import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';


import ResponsiveView from '../views/ResponsiveView'

class ButtonSubmitMain extends ResponsiveView {

  onButtonClick(){
    if(!this.props.onButtonClick) {
      console.log("onButtonClick not defined for ButtonMainAction with label: ", this.props.label);
      return;
    }

    this.props.onButtonClick();
  }

  render() {
    return (
      <button id={this.props.id} data-clipboard-text={this.props.dataClipboardText} style={this.styles().container}>
        {this.props.label}
      </button>
    );
  }

  styles(){

    return({
      container:{
        background:this.props.background ? this.props.background : '#4A90E0',
        color:'#fff',
        fontSize:'13px',
        fontWeight:'700',
        borderRadius:'3px',
        lineHeight:'36px',
        //maxWidth:'320px',
        boxShadow:'0 1px 2px rgba(0,0,0,.2)',
        cursor:'pointer',
        textAlign:'center',
        WebkitAppearance:'none',
        borderStyle:'hidden',
        outline:'none',
        width:'100%',
        ':hover':{
          opacity:.8,
        }
      }
    });
  }
}

ButtonSubmitMain.propTypes = {
  id:PropTypes.string.isRequired,
  label:PropTypes.string.isRequired,
  dataClipboardText:PropTypes.string,
  onButtonClick:PropTypes.func,
  background:PropTypes.string,
};

export default Radium(ButtonSubmitMain);

