import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import icnBackButton from '../img/icn_back.svg';
import icnPrevButton from '../img/icn_ui_arrow_left.svg';
import icnNextButton from '../img/icn_ui_arrow_right.svg';
import icnMapButton from '../img/icn_map.svg';

class InfoCardHeader extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onBackButton(){
    if(!this.props.onBackButton) {console.warn('onBackButton not defined in InfoCardHeader'); return;}
    this.props.onBackButton();
  }

  onMapButton(){
    if(!this.props.onMapButton) {console.warn('onMapButton not defined in InfoCardHeader'); return;}
    this.props.onMapButton();
  }

  onPrevButton(){
    if(!this.props.onPrevButton) {console.warn('onPrevButton not defined in InfoCardHeader'); return;}
    this.props.onPrevButton();
  }

  onNextButton(){
    if(!this.props.onNextButton) {console.warn('onNextButton not defined in InfoCardHeader'); return;}
    this.props.onNextButton();
  }

  render() {
    return (
      <div style={this.styles().container}>
        <div style={this.styles().backButton} key="backButton">
          <img src={icnBackButton} style={{display:'block'}} alt="Icon:BackButton" onClick={this.onBackButton.bind(this)}/>
        </div>

        <div style={this.styles().headerNavContainer}>
          <div style={this.styles().prevStepButton} onClick={this.onPrevButton.bind(this)} key="prevStepButton">
            <img src={icnPrevButton} style={{display:'block'}} alt="Icon:PrevButton" onClick={null}/>
          </div>
          <div style={this.styles().titleContainer}>
            <div style={this.styles().headerTitle}>{this.props.title}</div>
            <div style={this.styles().headerCounter}>{this.props.id}/{this.props.total}</div>
          </div>
          <div style={this.styles().nextStepButton} onClick={this.onNextButton.bind(this)} key="nextStepButton">
            <img src={icnNextButton} style={{display:'block'}} alt="Icon:NextButton" onClick={null}/>
          </div>
        </div>

        <div style={this.styles().mapButton} key="mapButton">
          <img src={icnMapButton} style={{display:'block'}} alt="Icon:MapButton" onClick={this.onMapButton.bind(this)}/>
        </div>

      </div>
    );
  }

  styles(){

    return {

      container:{
        //height:'60px',
        borderBottom:'1px solid #E6E6E6',
        textAlign:'center',
        padding:'10px 0 11px 0',
      },
      headerNavContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
      },
      titleContainer:{
        padding:'0 30px',
      },
      headerTitle:{
        fontSize:'16px',
        fontWeight:'900',
        color:'#4A4A4A',
      },
      headerCounter:{
        fontSize:'13px',
        color:'#999999',
      },
      backButton:{
        position:'absolute',
        left:'7px',
        top:'10px',
        cursor:'pointer',
        padding:'8px',
        ':hover':{
          background:'#E6E6E6',
          borderRadius:'50%',
        },
      },
      mapButton:{
        position:'absolute',
        right:'7px',
        top:'10px',
        cursor:'pointer',
        padding:'8px',
        ':hover':{
          background:'#E6E6E6',
          borderRadius:'50%',
        },
      },
      prevStepButton:{
        cursor:'pointer',
        ':hover':{
          background:'#E6E6E6',
          borderRadius:'50%',
        },
      },
      nextStepButton:{
        cursor:'pointer',
        ':hover':{
          background:'#E6E6E6',
          borderRadius:'50%',
        },
      }

    }

  }
}

InfoCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onBackButton: PropTypes.func.isRequired,
  onMapButton: PropTypes.func.isRequired,
  onPrevButton: PropTypes.func.isRequired,
  onNextButton: PropTypes.func.isRequired,
}

export default Radium(InfoCardHeader);