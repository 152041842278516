import React from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

import {getMapDirections} from '../utils/apiService';
import {getMapBoundingRect} from '../utils/helpers';

import ResponsiveView from '../views/ResponsiveView';
import MapPreview from './MapPreview';

import '../css/MapPopup.css';


class MapJourney extends ResponsiveView {

  constructor(props) {
    super(props);

    this.state = {
      displayedRoute:null,
      currentWaypoint:null,
      lastPopup:null,
    };

    this.mapComponent = null;
  }



  onMapLoaded(map, geoJsonData){
    console.log("onMapLoaded: ", map, geoJsonData);


    if(!this.map){
      this.map = map;
    }


    this.geoJsonData = geoJsonData;

    let coords = [];

    this.geoJsonData.features.forEach(function(el){
      coords.push(el.geometry.coordinates);
    });

    this.addLineToMap(coords);
    /*
    getMapDirections(coords, 'pk.eyJ1IjoiaHZrYXJhamFuIiwiYSI6IkNRMnUxTU0ifQ.qcOt5boGJZhgiECS9qvizw').done(function(data){

      //coords = data.routes[0].geometry.coordinates;




    }.bind(this));
    */
  }

  addLineToMap(coords){

    console.log("addLineToMap");
    console.log("this.state.geoJsonData: ", this.geoJsonData);
    console.log("this.mapComponent: ", this.mapComponent);

    if(this.map.getSource("routePoints")) this.map.removeSource("routePoints");
    if(this.map.getLayer("route")) this.map.removeLayer("route");

    if(this.map){

      this.map.addSource('routePoints', {
        type: 'geojson',
        data: {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "LineString",
            "coordinates": coords
          }
        }
      });


      this.map.addLayer({
        "id": "route",
        "type": "line",
        "minzoom": 3,
        "source": "routePoints",
        "layout": {
          "line-join":"round",
          "line-cap": "round"
        },
        "paint": {
          "line-color": "#141414",//"#3BB2D0",//"#9ACCDA",//"#D9C56F",
          "line-width": 3,
          "line-opacity": .6,
          "line-dasharray": [1,2],
          //"line-gap-width": 8,
        }
      });

      //this.map.moveLayer('route', 'points');
    }

    this.setState({
      displayedRoute:coords,
    }, function(){
      //this.startTravelRoute();
      this.bringJourneyIntoView();
    });
  }

  bringJourneyIntoView(){

    let bbox = getMapBoundingRect(this.state.displayedRoute);

    console.log("bbox: ", bbox);

    this.map.fitBounds(bbox, {padding: {top: 40, bottom:40, left: 40, right: 40}});

  }

  startTravelRoute(){
    console.log("startTravelRoute along: ", this.state.displayedRoute);

    this.setState({
      isTravelling:true,
      currentWaypoint:0,
    }, function(){
      this.mapComponent.animateMapTo(this.state.displayedRoute[0], null, 1);
    })

  }

  travelToPrevWaypoint(){

    if(this.state.lastPopup) this.state.lastPopup.remove();

    let prevWaypoint = this.state.currentWaypoint-1;

    if(prevWaypoint < 0) prevWaypoint = 0;

    this.setState({
      currentWaypoint:prevWaypoint,
    }, function(){

      if(this.state.isTravelling && this.state.currentWaypoint < this.state.displayedRoute.length){
        this.mapComponent.animateMapTo(this.state.displayedRoute[this.state.currentWaypoint], null, .9, 1);
      } else {
        this.setState({
          isTravelling:false,
        })
      }

    })

  }


  travelToNextWaypoint(){

    if(this.state.lastPopup) this.state.lastPopup.remove();

    let nextWaypoint = this.state.currentWaypoint+1;

    if(nextWaypoint > this.state.displayedRoute-1) nextWaypoint = this.state.displayedRoute-1;

    this.setState({
      currentWaypoint:nextWaypoint,
    }, function(){

      if(this.state.isTravelling && this.state.currentWaypoint < this.state.displayedRoute.length){
        this.mapComponent.animateMapTo(this.state.displayedRoute[this.state.currentWaypoint], null, .9, 1);
      } else {
        this.setState({
          isTravelling:false,
        })
      }

    })

  }

  displayPopup(coords){

    var popup = new mapboxgl.Popup({ offset: [0, -40] })
      .setHTML(this.getPopupHTML(this.geoJsonData.features[this.state.currentWaypoint].properties))
      .setLngLat(coords)
      .addTo(this.map);


    let prevButton = document.getElementById('prevWaypointButton');

    if(prevButton){

      prevButton.addEventListener('click', function(){

        console.log("clicked the prevWaypointButton");
        this.setState({
          isTravelling: true,
        }, function(){
          this.travelToPrevWaypoint();
        });

      }.bind(this));

    }

    let nextButton = document.getElementById('nextWaypointButton');

    if(nextButton){

      nextButton.addEventListener('click', function(){

        console.log("clicked the nextWaypointButton");
        this.setState({
          isTravelling: true,
        }, function(){
          this.travelToNextWaypoint();
        });


      }.bind(this));

    }


    this.setState({
      lastPopup:popup,
    })
  }

  getPopupHTML(place){

    let name = place._name ? place._name : 'Stadt: '+place.city;

    let htmlString = "";

    htmlString += '<div id="popupBg" class="journeyBg">';
      htmlString += '<div class="waypointTitle">' + name + '</div>';
      htmlString += '<div>' + place.city + '</div>';
      htmlString += '<div class="waypointDescription">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</div>';
      htmlString += '<div class="waypointNavigation">';

      if(this.state.currentWaypoint > 0) {
        //htmlString += '<div id="prevWaypointButton" class="prevButton">' + (this.state.currentWaypoint) + '</div>';
        htmlString += '<div id="prevWaypointButton" class="prevButton"> < </div>';
      } else {
        htmlString += '<div class="buttonPlaceholderLeft"> < </div>';
      }
        htmlString += '<div class="waypointIndex">' + (this.state.currentWaypoint+1) + '/' + this.geoJsonData.features.length + '</div>';

      if(this.state.currentWaypoint < this.geoJsonData.features.length-1) {
        //htmlString += '<div id="nextWaypointButton" class="nextButton">' + (this.state.currentWaypoint+2) + '</div>';
        htmlString += '<div id="nextWaypointButton" class="nextButton"> > </div>';
      } else {
        htmlString += '<div class="buttonPlaceholderRight"> < </div>';
      }

      htmlString += '</div>';
    htmlString += '</div>';

    return htmlString;
  }

  getWaypointIndexInJourney(waypointData){

    let id = 0;

    for(let i=0; i<this.geoJsonData.features.length; i++){

      if(this.geoJsonData.features[i].properties.id === waypointData.properties.id){
        id = i;
      }

    }

    return id;

  }

  onMapMoveStart(){
    if(this.state.lastPopup) this.state.lastPopup.remove();
  }

  onMapMoveEnd(){

    if(this.state.isTravelling){

      this.displayPopup(this.state.displayedRoute[this.state.currentWaypoint]);

      this.setState({
        isTravelling:false,
      });
      /*
      setTimeout(function(){
        this.travelToNextWaypoint();
      }.bind(this), 5000);*/
    }

  }

  onMarkerSelected(data){
    console.log("onMarkerSelected ", data);

    console.log("waypointIndex: ", this.getWaypointIndexInJourney(data));

    this.setState({
      isTravelling:true,
      currentWaypoint:this.getWaypointIndexInJourney(data),
    }, function(){
      this.mapComponent.animateMapTo(this.state.displayedRoute[this.getWaypointIndexInJourney(data)], null, 1);
    });

    //this.startTravelRoute();
    //this.props.onMarkerSelected(data);
  }


  render(){

    return (

      <div style={this.styles().container}>

        <MapPreview
          ref={(mapObj) => this.mapComponent = mapObj}
          onMapLoaded={this.onMapLoaded.bind(this)}
          onMapMoveStart={this.onMapMoveStart.bind(this)}
          onMapMoveEnd={this.onMapMoveEnd.bind(this)}
          coordinates={this.props.mapCenter}
          interactive={true}
          isFullScreen={true}
          onMarkerSelected={this.onMarkerSelected.bind(this)}
          isLocked={false}
          keepOrder={true}
          poiDataUrl={this.props.poiDataUrl}
          isJourneyMap={true}
          clustersActive={false}
          markerAsset="map_marker_highlight"
        />
      </div>
    );

  }

  styles(){

    return {
      container:{
        position:'relative',
        height:'100vh',
        width:'100%',
        boxSizing:'border-box',
      },

    }

  }
}

MapJourney.propTypes = {
  mapCenter: PropTypes.array.isRequired,
  onMarkerSelected: PropTypes.func.isRequired,
  poiDataUrl: PropTypes.string.isRequired,
  onMapLoaded: PropTypes.func,
};

export default MapJourney;