export const credits  = [
  {
    id:1,
    creditItems: [
      {
        "label": "Aufnahme/Recording:",
        "description": "KWV 361, 15.08.1976, Großes Festspielhaus Salzburg;  Recorded by Austria Radio"
      },
      {
        "label": "Bonus-Track:",
        "description": "4.12.1970, Lukaskirche Dresden; Recorded by Wolfgang Wahrig (Violinist Staatskapelle Dresden)"
      },
      {
        "label": "Digital Remastering:",
        "description": "Wolf-Dieter Karwatky, Hannover ℗ 2017 Karajan® Music, © Karajan® Music, 2017\n\nSonderveröffentlichung anlässlich 50. Geburtstag der Osterfestspiele Salzburg"
      },
      {
        "label": "Text:",
        "description": "Tobias Niederschlag (Staatskapelle Dresden); Übersetzung/Translation: Derek Henderson"
      },
      {
        "label": "Photos:",
        "description": "S. Lauterwasser, Karajan® Archive, DSCH Publishers, Internationale Schostakowitsch Tage Gohrisch"
      }
    ],
    logoUrls: [
      "/img/logo_karajan_gold.png",
      "/img/logo_osterfestspiele_gold.png",
      "/img/logo_staatskapelle.png"
    ]
  }
];

