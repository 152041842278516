import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import $ from 'jquery';

import {getInitials} from '../utils/helpers';
import {decodeId} from '../utils/helpers';

import ResponsiveView from '../views/ResponsiveView';

class ListItemArtist extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      imageError:false,
    }
  }

  componentDidMount(){
    this.checkIfTruncate();
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.props = nextProps;
    this.checkIfTruncate();
  }

  onViewResize(){
    this.checkIfTruncate();
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for InfoCardListItem");
      return;
    }

    this.props.onItemSelected(this.props.id);
  }

  onImageLoadError(){
    this.setState({
      imageError:true,
    })
  }

  checkIfTruncate() {

    if(!this.props.name) return;

    let checkString = this.props.name;
    let startPart = "";
    let endPart = "";
    let returnString = checkString;

    let itemWidth = $('#artistItem'+decodeId(this.props.id)).width();
    //console.log("itemWidth: ",itemWidth);
    //console.log("Truncate width: ", this.nameContainer.offsetWidth);
    let maxChars = Math.floor(itemWidth/10);
    let firstTrim = Math.floor((maxChars/2)*.9);

    if (checkString.length > maxChars) {

      startPart = checkString.slice(0, firstTrim).trim() + "...";
      endPart = checkString.slice(checkString.length - firstTrim, checkString.length);

      returnString = startPart + endPart;
    }

    this.setState({
      truncatedName:returnString,
    })

  }

  render() {

    return (
      <div style={this.styles().container} onClick={this.onItemSelected.bind(this)} id={"artistItem"+decodeId(this.props.id)}>

        <div style={this.styles().imageContainer} className={"artistItem"}>
          {this.props.imageUrl && !this.state.imageError
            ? <img style={this.styles().image} src={this.props.imageUrl} alt="" data-key={'0'} id={this.props.id} onError={this.onImageLoadError.bind(this)}/>
            : <span>{getInitials(this.props.name)}</span>
          }

        </div>

        <div style={this.styles().listTextContainer}>
          <div style={this.styles().name}>
            {this.state.truncatedName}
          </div>

          <div style={this.styles().role}>
            {this.props.role}
          </div>
        </div>

      </div>
    );
  }

  styles(){

    return {

      container:{
        background:'#fff',
        padding:'8px 20px',
        borderTop:this.props.standalone ? '1px solid #F0F0F0' : null,
        borderBottom:'1px solid #F0F0F0',
        //height:'60px',
        fontSize:'16px',
        color:'#4A4A4A',
        textAlign:'left',
        cursor:'pointer',
        position:'relative',
        overflow:'hidden',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      imageContainer:{
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        overflow: 'hidden',
        background: '#4A4A4A',
        float: 'left',
        //fontStyle: 'italic',
        lineHeight: '44px',
        textAlign: 'center',
        fontSize: '17px',
        color:'#fff',
        position:'relative',
      },
      image:{
        //width:'100%',
        height:'100%',
        marginLeft:'50%',
        transform:'translateX(-50%)',
      },
      listTextContainer: {
        paddingTop: '4px',
        paddingLeft: '24px',
        float: 'left',
        lineHeight: '18px',
      },
      name: {
        fontWeight:'400',
        fontSize:'16px',
        color:'#191919',
        whiteSpace:'nowrap',
        overflow:'hidden',
      },
      role:{
        color:'#999999',
        fontSize:'13px',
      },



    }

  }
}

ListItemArtist.propTypes = {
  id:PropTypes.number.isRequired,
  name:PropTypes.string.isRequired,
  role:PropTypes.string.isRequired,
  onItemSelected:PropTypes.func.isRequired,
  imageUrl:PropTypes.string,
}

export default Radium(ListItemArtist);
