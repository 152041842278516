import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

class ViewToggle extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onViewSelected(id){
    this.props.onViewSelected(id);
  }

  render() {

    return (
      <div style={this.styles().container}>
        <div style={Object.assign(this.styles().leftItem, this.props.activeView === 0 ? this.styles().activeItem : {})} onClick={this.onViewSelected.bind(this,0)}>
          {this.props.labelLeft}
        </div>
        <div style={Object.assign(this.styles().rightItem, this.props.activeView === 1 ? this.styles().activeItem : {})} onClick={this.onViewSelected.bind(this,1)}>
          {this.props.labelRight}
        </div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        width:'100%',
        height:'48px',
        lineHeight:'46px',
        fontSize:window.innerWidth <= 480 ? '14px' : '16px',
        fontWeight:'700',
        color:'#3F3F3F',
        overflow:'hidden'
      },
      leftItem:{
        width:'50%',
        borderTopLeftRadius:'24px',
        borderBottomLeftRadius:'24px',
        borderLeft:'1px solid #3F3F3F',
        borderTop:'1px solid #3F3F3F',
        borderBottom:'1px solid #3F3F3F',
        float:'left',
        cursor:'pointer',
      },
      rightItem:{
        width:'50%',
        borderTopRightRadius:'24px',
        borderBottomRightRadius:'24px',
        borderRight:'1px solid #3F3F3F',
        borderTop:'1px solid #3F3F3F',
        borderBottom:'1px solid #3F3F3F',
        float:'left',
        cursor:'pointer',
      },
      activeItem:{
        borderLeft:'1px solid #1C6ECC',
        borderRight:'1px solid #1C6ECC',
        borderTop:'1px solid #1C6ECC',
        borderBottom:'1px solid #1C6ECC',
        background:'#1C6ECC',
        color:'#fff',
        cursor:'default',
      }
    }

  }
}

ViewToggle.propTypes = {
  activeView: PropTypes.number.isRequired,
  onViewSelected: PropTypes.func.isRequired,
  labelLeft:PropTypes.string.isRequired,
  labelRight:PropTypes.string.isRequired,
}

export default Radium(ViewToggle);