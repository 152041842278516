import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

//import {linkify} from '../utils/helpers';

//import {getDashboardData, getDashboardDataWithCollections} from '../api/graphqlClient';
import { getDashboardData } from '../api/kapiClient';



import ResponsiveView from './ResponsiveView';

import DashboardHero from '../components/DashboardHero';
//import CollectionPreviewItem from '../components/CollectionPreviewItem';
//import RecommendationsGrid from '../components/RecommendationsGrid';
import BigButtonRound from '../components/BigButtonRound';

import imgDashboardHero from '../img/karajan_dashboard_web_background.jpg';
import imgDashboardHeroBlur from '../img/karajan_dashboard_web_background_blur.jpg';
import imgDashboardHeroMobile from '../img/karajan_dashboard_web_mobile_background.jpg';
import imgDashboardHeroMobileBlur from '../img/karajan_dashboard_web_mobile_background_blur.jpg';

import icnCollections from '../img/icn_collections_big.svg';
import icnDiscover from '../img/icn_discover_big.svg';
//import icnStories from '../img/icn_stories_big.svg';
import icnFavourites from '../img/icn_favourites_big.svg';
import icnNewsletter from '../img/icn_newsletter.svg';

import { getFeatureFlag } from '../utils/featureFlags';

import { track } from '../utils/trackingService';

import { withTranslation } from 'react-i18next';

class DashboardView extends ResponsiveView {

  constructor(props) {
    super(props);

    this.state = {
      blur: 0,
      viewData: null,
    };

    this.onDataReady = this.onDataReady.bind(this);
    this.loadViewData = this.loadViewData.bind(this);
    this.onViewScroll = this.onViewScroll.bind(this);
  }

  componentDidMount() {

    track('load-page', { pageId: 'Dashboard' });

    window.addEventListener('scroll', this.onViewScroll);

    this.loadViewData();


    // add newsletter script to the end
    //<script language="javascript" src="//karajanmusictech.us5.list-manage.com/generate-js/?u=4926e8903639b282d5a54e490&fid=16041&show=10" type="text/javascript"></script>
    const script = document.createElement("script");
    script.src = "https://karajanmusictech.us5.list-manage.com/generate-js/?u=4926e8903639b282d5a54e490&fid=16041&show=10";
    script.async = true;
    script.type = "text/javascript";

    document.getElementById("newsletters").appendChild(script);


  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener('scroll', this.onViewScroll);
  }

  loadViewData() {

    let apiCall = getDashboardData();


    apiCall.then(function (result) {

      console.log("getDashboardData: ", result);

      if (result && result.data) {
        console.log("getDashboardData returned: ", result.data);
        this.onDataReady(result.data);
      } else if (result && result.errors) {
        console.error('There was an error for query getDashboardData:', result.errors[0].message);
        this.setState({ isLoading: false, errorMessage: 'Es ist ein Fehler aufgetreten.' });
      } else {
        console.log('getStoriesList did not return any results', this.props.match.params.id, result)
        this.setState({ isLoading: false, errorMessage: 'Die Daten konnten nicht geladen werden.' });
      }

    }.bind(this), function (error) {
      console.log("There was a problem querying getDashboardData: ", error);
    });
  }

  onDataReady(data) {
    console.log("-> onDataReady ", data, this.props);

    this.setState({
      viewData: data,
    });

    if (this.props.onDataReady) this.props.onDataReady(data);
  }

  onViewResize() {
    //console.log("DashboardView -> onViewResize() ", this);

    this.setState({
      resize: true,
    })
  }

  onViewScroll() {

    let scroll = window.scrollY;
    let offset = 160;

    if (scroll > offset) {
      scroll = offset;

    }

    let percent = (Math.round(scroll * 100 / offset) / 100);

    this.setState({
      blur: percent,
    });

  }

  onShareItem(data) {
    this.props.onShareRequest(data);
  }

  navigateToSection(path) {
    console.log("navigateToSection: ", this.props);

    this.props.history.push(path);
  }

  onStartSearch(queryString) {
    this.props.history.push('/search?q=' + queryString);
  }

  onRecommendationSelected(index) {
    console.log("onRecommendationSelected: ", index);
    if (!this.state.viewData.recommendations) return;
    this.navigateToSection(this.state.viewData.recommendations[index].link);
  }

  render() {

    const { t } = this.props;

    return (

      <div style={this.styles().view}>
        <div style={this.styles().container}>

          <div style={this.styles().heroImageContainer}>
            {window.innerWidth > 768
              ?
              <div>
                <img style={Object.assign({}, this.styles().heroImage, { opacity: 1 - this.state.blur })} src={imgDashboardHero} alt="" />
                <img style={Object.assign({}, this.styles().heroImage, { opacity: this.state.blur })} src={imgDashboardHeroBlur} alt="" />
              </div>
              :
              <div>
                <img style={Object.assign({}, this.styles().heroImageMobile, { opacity: 1 - this.state.blur })} src={imgDashboardHeroMobile} alt="" />
                <img style={Object.assign({}, this.styles().heroImageMobile, { opacity: this.state.blur })} src={imgDashboardHeroMobileBlur} alt="" />
              </div>
            }

          </div>

          <DashboardHero
            onStartSearch={this.onStartSearch.bind(this)}
          />

          <div style={this.styles().sectionTeasersContainer}>

            {getFeatureFlag('COLLECTIONS')
              ? <div style={this.styles().sectionTeaser.container} key="sectionTeaser1" onClick={this.navigateToSection.bind(this, '/collections')}>
                <img src={icnCollections} alt="icnCollections" />
                <div style={this.styles().sectionTeaser.title}>{t('dashboard.collections.header')}</div>
                <div style={this.styles().sectionTeaser.description}>{t('dashboard.collections.teaser')}</div>
              </div>
              : null
            }

            <div style={this.styles().sectionTeaser.container} key="sectionTeaser2" onClick={this.navigateToSection.bind(this, '/discover')}>
              <img src={icnDiscover} alt="icnDiscover" />
              <div style={this.styles().sectionTeaser.title}>{t('dashboard.discover.header')}</div>
              <div style={this.styles().sectionTeaser.description}>{t('dashboard.discover.teaser')}</div>
            </div>




            <div style={this.styles().sectionTeaser.container} key="sectionTeaser4" onClick={this.navigateToSection.bind(this, '/favourites')}>
              <img src={icnFavourites} alt="icnFavourites" />
              <div style={this.styles().sectionTeaser.title}>{t('dashboard.favourites.header')}</div>
              <div style={this.styles().sectionTeaser.description}>{t('dashboard.favourites.teaser')}</div>
            </div>
          </div>


          <div style={this.styles().contentContainer}>
            <div style={this.styles().newsletterNotification}>

              <div style={this.styles().newsletterBadge}>
                <img src={icnNewsletter} alt="" />
              </div>

              <h2>{t('dashboard.newsletter.header')}</h2>
              {t('dashboard.newsletter.header')}

              <div style={this.styles().newsletterButtonContainer}>
                <BigButtonRound label={t('dashboard.newsletter.button')} onButtonClick={() => { window.open('https://karajan.org/#community', '_blank') }} background={'#DAC46D'} labelColor={'#fff'} />
              </div>
            </div>
          </div>

          <div style={this.styles().contentContainer}>
            <div id="newsletters">
            </div>
          </div>


          {/*getFeatureFlag('COLLECTIONS') && this.state.viewData && this.state.viewData.collections && this.state.viewData.collections[0]
            ? <div style={this.styles().contentContainer}>
                <div style={this.styles().sectionLabelContainer}>
                  <span style={this.styles().sectionLabel}>Collection der Woche</span>
                  <span style={this.styles().sectionLink} onClick={this.navigateToSection.bind(this, '/collections')}>Alle anzeigen</span>
                </div>
                <CollectionPreviewItem
                  path={"/collections/"+this.state.viewData.collections[0].id}
                  key="0"
                  title={this.state.viewData.collections[0].title}
                  subtitle={this.state.viewData.collections[0].subtitle}
                  caption={this.state.viewData.collections[0].caption}
                  imageUrl={this.state.viewData.collections[0].imageUrl}
                  standalone={true}
                />

              </div>
            : null
          */}

          {/*getFeatureFlag('RECOMMENDATIONS') && this.state.viewData && this.state.viewData.recommendations && this.state.viewData.recommendations[0]
            ? <div style={this.styles().contentContainer}>
                <div style={this.styles().sectionLabelContainer}>
                  <span style={this.styles().sectionLabel}>Empfohlen für dich</span>
                </div>

                <RecommendationsGrid
                  recommendations={this.state.viewData.recommendations}
                  onItemSelected={this.onRecommendationSelected.bind(this)}
                />

              </div>
            : null
        */}

          {/*this.state.viewData && this.state.viewData.stories && this.state.viewData.stories[0] ||
          this.state.viewData && this.state.viewData.stories.edges && this.state.viewData.stories.edges[0]

            ? <div style={this.styles().contentContainer}>
                <div style={this.styles().sectionLabelContainer}>
                  <span style={this.styles().sectionLabel}>Aktuelle Story</span>
                  <span style={this.styles().sectionLink} onClick={this.navigateToSection.bind(this, '/stories')}>Alle anzeigen</span>
                </div>

                <StoryItem
                  id={1}
                  title={this.state.viewData.stories.edges ? this.state.viewData.stories.edges[0].node.title : this.state.viewData.stories[0].title}
                  text={this.state.viewData.stories.edges ? this.state.viewData.stories.edges[0].node.teaser : this.state.viewData.stories[0].teaser}
                  releaseDate={this.state.viewData.stories.edges ? this.state.viewData.stories.edges[0].node.releaseDate : this.state.viewData.stories[0].releaseDate}
                  onLinkClick={()=>{}}
                  imageUrl={''}
                  tags={this.state.viewData.stories.edges ? this.state.viewData.stories.edges[0].node.tags : this.state.viewData.stories[0].tags}
                  alignment={'left'}
                  forceFullWidth={true}
                  onShareItem={this.onShareItem.bind(this)}
                  standalone={true}
                  onLinkButtonClick={this.navigateToSection.bind(this)}
                  />
              </div>
            : null
          */}


        </div>


      </div>
    );
  }

  styles() {

    return {
      view: {
        background: '#131313',
        height: '100vh',
        position: 'absolute',
        width: '100%',
      },
      container: {
        background: '#131313',
        color: '#fff',
        overflow: 'hidden',
        //height:'100vh',
        //overflowY:'scroll',
        paddingLeft: window.innerWidth > 768 ? '60px' : '0px',
        paddingBottom: '100px',

      },
      heroImageContainer: {
        width: '100%',
        position: 'fixed',
        top: '0',

      },
      heroImage: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        opacity: '1',
      },
      heroImageMobile: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        opacity: '1',
      },
      contentContainer: {
        maxWidth: '860px',
        margin: '96px auto 0 auto',
        position: 'relative',
        //padding:window.innerWidth >= 1024 ? '0 0' : '0 20px',
      },
      sectionLabelContainer: {
        color: '#fff',
        fontSize: '18px',
        letterSpacing: '.5px',
        fontWeight: '900',
        position: 'relative',
        textAlign: window.innerWidth >= 768 ? 'left' : 'center',
        marginBottom: '20px',
        overflow: 'hidden',
      },
      sectionLabel: {
        float: window.innerWidth >= 768 ? 'left' : null,
      },
      sectionLink: {
        color: '#1C6ECC',
        fontSize: '14px',
        float: window.innerWidth >= 768 ? 'right' : null,
        display: window.innerWidth >= 768 ? null : 'block',
        cursor: 'pointer',
        paddingTop: '5px',
      },
      sectionTeasersContainer: {
        overflow: 'hidden',
        /*display:window.innerWidth >= 1024 ? 'flex' : 'block',*/
        flexWrap: 'wrap',
        position: 'relative',
        display: 'inline-flex'
      },
      sectionTeaser: {
        container: {
          background: 'rgba(255,255,255,.15)',
          padding: '40px 40px 60px 40px',
          boxSizing: 'border-box',
          margin: window.innerWidth >= 1024 ? '0.2em' : '4px',
          width: 'calc(50% - .6em)',
          cursor: 'pointer',
          ":hover": {
            background: 'rgba(218,196,109,.5)',
          },
        },
        title: {
          fontSize: '28px',
          fontWeight: '900',
          marginTop: '20px',
        },
        description: {
          color: '#ccc',
          fontSize: '18px',
          margin: '20px auto 0 auto',
          maxWidth: '300px',
        },
      },
      newsletterNotification: {
        background: '#fff',
        color: '#3f3f3f',
        padding: '48px',
        borderRadius: '3px',
        fontWeight: '400',
        position: 'relative',
        //textAlign:'left',
      },
      newsletterButtonContainer: {
        marginTop: '24px',
        paddingTop: '16px',
        textAlign: 'center',
        borderTop: '1px solid #f0f0f0',
      },
      newsletterBadge: {
        width: '96px',
        height: '96px',
        padding: '24px',
        background: '#DAC46D',
        borderRadius: '50%',
        display: 'inline-block',
        position: 'absolute',
        top: '-48px',
        left: 'calc(50% - 48px)'
      }
    }

  }
}

DashboardView.propTypes = {
  onShareRequest: PropTypes.func.isRequired,
};

export default withTranslation()(Radium(DashboardView));