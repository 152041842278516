import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../views/ResponsiveView';

import BigButtonRound from './BigButtonRound';

import badgePremiumBig from '../img/badge_premium_big.svg';

class PremiumTeaser extends ResponsiveView {


  onButtonClick(){
    this.props.onActivatePremium();
  }

  render() {
    return (
      <div style={this.styles().container}>
        <img src={badgePremiumBig} alt="" style={this.styles().badge}/>
        <div style={this.styles().headline}>Teste jetzt Karajan Premium</div>
        und erhalte vollen Zugang  zu  dieser Collection und vielen anderen spannenden Inhalten.

        <div style={this.styles().buttonContainer}>
          <BigButtonRound
            label={'PREMIUM TESTEN'}
            background={'#fff'}
            labelColor={'#DAC46D'}
            onButtonClick={this.onButtonClick.bind(this)}
          />
        </div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        background:'#DAC46D',
        padding:'40px 20px',
        textAlign:'center',
      },
      badge:{
        display:'block',
        margin:'-66px auto 20px auto',
      },
      headline:{
        fontSize:'20px',
        fontWeight:'900',
        lineHeight:'36px',
      },
      buttonContainer:{
        marginTop:'20px',
      }

    }

  }
}

PremiumTeaser.propTypes = {
  onActivatePremium:PropTypes.func.isRequired,
};

export default PremiumTeaser;