export const composers = [
  { id: 1,
    imageUrl:'https://www.thefamouspeople.com/profiles/images/claude-debussy-4.jpg',
    firstName: 'Claude',
    lastName: 'Debussy',
    birthDate:{
      day:21,
      month:8,
      year:1862
    },
    deathDate:{
      day:24,
      month:3,
      year:1918
    },
    description:'Herbert von Karajan nahm am 22.07.1953 zum ersten Mal ein Werk von Debussy auf. Er spielte in 91 Konzerten 6 Werke des Komponisten.',
    role:'Komponist',
  },
  { id: 2,
    imageUrl:'https://www.mphil.de/fileadmin/redaktion/Personen_diverse/komponisten/Tschaikowsky_Pjotr_Iljitsch.jpg',
    firstName: 'Peter Iljitsch',
    lastName: 'Tschaikowsky',
    birthDate:{
      day:21,
      month:8,
      year:1862
    },
    deathDate:{
      day:24,
      month:3,
      year:1918
    },
    description:'Herbert von Karajan nahm am 22.07.1953 zum ersten Mal ein Werk von Tschaikowsky auf. Er spielte in 91 Konzerten 6 Werke des Komponisten.',
    role:'Komponist',
  },
  { id: 3,
    imageUrl:'https://upload.wikimedia.org/wikipedia/commons/b/bc/Mozart-by-Croce-1780-81.jpg',
    firstName: 'Wolfgang Amadeus',
    lastName: 'Mozart',
    birthDate:{
      day:26,
      month:1,
      year:1756
    },
    deathDate:{
      day:4,
      month:12,
      year:1791
    },
    description:'Herbert von Karajan nahm am 09.12.1938 zum ersten Mal ein Werk von Mozart auf. Er spielte in 705 Konzerten 44 Werke des Komponisten.',
    role:'Komponist',
  }
];