import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';


import ListItemArtist from './ListItemArtist';

import icnArrowDown from '../img/icn_ui_arrow_small_down.svg';
import icnArrowUp from '../img/icn_ui_arrow_small_up.svg';
import { capitalizeTitle } from '../utils/helpers';

class ArtistListDropdown extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      isOpen:false,
    }
  }

  onViewResize(){
    return true;
  }

  toggleDropDown(){
    this.setState({
      isOpen:!this.state.isOpen,
    })
  }

  onItemSelected(id){
    console.log("Selected artist with id: ", id);
    if(!this.props.onItemSelected) {console.warn('this.props.onItemSelected not defined for ArtistListDropdown'); return;}
    this.props.onItemSelected(id);
  }

  renderList(){

    let items = [];

    for(let i=0; i<this.props.artists.length;i++){

      let artist = this.props.artists[i];

      console.log("DEBUG ARTIST");
      console.log(artist);

      let artistName = artist.firstName !== "null" && artist.firstName !== null ? artist.firstName + ' ' + artist.lastName : artist.lastName;

      items.push(
        <ListItemArtist
          imageUrl={artist.imageUrl}
          name={artistName}
          role={capitalizeTitle(artist.role)}
          key={i}
          id={artist.id}
          onItemSelected={this.onItemSelected.bind(this)}
        />
      );
    }

    return items;


  }

  render() {
    return (
      <div style={this.styles().container}>

        <div style={this.styles().header} onClick={this.toggleDropDown.bind(this)}>
          {this.props.label}

          {this.state.isOpen
            ? <img src={icnArrowUp} alt="" style={this.styles().arrow}/>
            : <img src={icnArrowDown} alt="" style={this.styles().arrow}/>
          }
        </div>

        {this.state.isOpen
          ? <div style={this.styles().content}>
              {this.renderList()}
            </div>
          : null
        }

      </div>
    );
  }

  styles(){

    return {

      container:{

      },
      header:{
        padding:'0px 20px',
        borderTop:'1px solid #F0F0F0',
        borderBottom:'1px solid #F0F0F0',
        //height:'60px',
        fontSize:'16px',
        color:'#4A4A4A',
        lineHeight:'60px',
        textAlign:'left',
        cursor:'pointer',
        position:'relative',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      arrow:{
        display:'block',
        position:'absolute',
        right:'20px',
        top:'18px',
      },
      content:{

      }


    }

  }
}

ArtistListDropdown.propTypes = {
  label:PropTypes.string.isRequired,
  artists:PropTypes.array.isRequired,
  onItemSelected:PropTypes.func,
};


export default Radium(ArtistListDropdown);