import React from 'react';
import ResponsiveView from './ResponsiveView';

import {getAllFavourites, clearFavourite} from '../utils/localStorageService';
import {track} from '../utils/trackingService';

import LoadingIndicator from '../components/LoadingIndicator';
import FavouriteItem from '../components/FavouriteItem';

import icnFavourites from '../img/icn_favourites_big.svg';

import { withTranslation } from 'react-i18next';


class FavouritesView extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      viewData:null,
    };

    this.onDataReady = this.onDataReady.bind(this);
  }

  componentDidMount(){

    this.onDataReady(getAllFavourites());

  }

  onDataReady(data){
    console.log("-> onDataReady ", data);

    this.setState({
      viewData:data,
    });

    track('load-page', {pageId:'Favourites', favourites:data.length});
  }

  onFavouritesItemSelected(link){

    if(link && link.length > 0){
      this.props.history.push(link);
    } else {
      console.warn("Can't navigate. No link defined.");
    }
  }

  onRemoveItem(link){
    console.log("onRemoveItem in Favourites view: ", link);
    clearFavourite(link);
    this.onDataReady(getAllFavourites());
  }

  renderFavouriteItems(){

    let items = [];

    for(let i=0; i<this.state.viewData.length;i++){

      items.push(
        <FavouriteItem
          type={this.state.viewData[i].type}
          title={this.state.viewData[i].data.title}
          subtitle={this.state.viewData[i].data.subtitle}
          description={this.state.viewData[i].data.description}
          imageUrl={this.state.viewData[i].data.imageUrl}
          link={this.state.viewData[i].data.link}
          onItemSelected={this.onFavouritesItemSelected.bind(this)}
          onRemoveItem={this.onRemoveItem.bind(this)}
          key={i}
        />
      )

    }

    return items;

  }

  render() {

    const {t} = this.props;

    return (
      <div style={this.styles().view}>
        <div style={this.styles().backgroundGradient}></div>

        <div style={this.styles().container}>

          {!this.state.viewData
            ?
            <div style={this.styles().container}>
              <LoadingIndicator />
            </div>

            :
            <div style={this.styles().contentContainer}>

              <div style={this.styles().pageIcon}>
                <img src={icnFavourites} alt="Icon: Stories"/>
              </div>
              <div style={this.styles().pageTitle}>
              {t('favourites.header')}
              </div>

              {this.state.viewData.length === 0
                ?
                <div style={this.styles().emptyViewDescription}>
                  {t('favourites.teaser')}
                </div>
                :
                <div style={this.styles().favouriteItemsContainer}>
                  {this.renderFavouriteItems()}
                </div>
              }


            </div>
          }

        </div>

      </div>
    );
  }

  styles(){

    return {
      view:{
        background:'#3A3A3A',
        minHeight:'100vh',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
      },
      backgroundGradient:{
        position:'fixed',
        top:'0',
        left:'0',
        width:'100%',
        height:'100vh',
        background:'url('+process.env.PUBLIC_URL + '/img/bg_dark_gradient.png)',
        backgroundSize:'cover',
        zIndex:0,
      },
      container:{
        position:'relative',
        //overflow:'hidden',
        paddingBottom:'100px',
        color:'#fff',
      },
      spinnerContainer:{
        padding:'50px 20px',
        textAlign:'center',
      },
      contentContainer: {
        maxWidth:'860px',
        margin:'0 auto',
      },
      pageTitle:{
        fontSize:'28px',
        fontWeight: '900',
        marginBottom:'40px',
        color:'#fff',
      },
      pageIcon: {
        paddingTop:'40px',
      },
      emptyViewDescription:{
        fontSize:'18px',
        fontWeight:'700',
      },
      favouriteItemsContainer:{
        /*
        display:'flex',
        flexWrap:'wrap',
        flexFlow: 'column wrap',
        justifyContent: 'flex-start',
        alignItems:'flex-start',
        height:'600px',
        maxWidth:'860px',
        */
        columnCount:window.innerWidth > 768 ? '4' : (window.innerWidth > 480 ? '3' : '2'),
        columnGap:'0',
        padding:'6px',
      }
    }

  }
}

export default withTranslation()(FavouritesView);