import React from 'react';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';
import RecordingTypeLabel from './RecordingTypeLabel';

class ListItemToggle extends ResponsiveView {

  UNSAFE_componentWillReceiveProps(nextProps){
    this.props = nextProps;
  }

  onViewResize(){
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for ListItemToggle");
      return;
    }

    this.props.onItemSelected(this.props.id);
  }

  render() {

    return (
      <div style={this.styles().container} onClick={this.onItemSelected.bind(this)}>
        {this.props.active ? this.props.labelActive : this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        borderTop:'1px solid #E6E6E6',
        textAlign:'center',
        color:'#1C6ECC',
        fontSize:'14px',
        fontWeight:'700',
        lineHeight:'60px',
        cursor:'pointer',
        ':hover':{
          background:'#f9f9f9',
        },
      }
    }

  }
}

export default Radium(ListItemToggle);