export const stories = [
  {
    id:1,
    title:'Stalin-Porträt im »Wunderharfen«-Klang',
    subtitle:'Herbert von Karajan, die Staatskapelle Dresden und Schostakowitschs Zehnte',
    teaser:'Ein Jahr nach Schostakowitschs Tod, auf der Höhe des Kalten Krieges im Sommer 1976, lädt Herbert von Karajan die Sächsische Staatskapelle Dresden nach Salzburg ein und öffnet damit für die rund 100 Musiker des Orchesters einen Spalt des Eisernen Vorhangs. Gemeinsam geben Sie am 15. August 1976 ein Konzert zu Ehren des verstorbenen Komponisten und setzen ihm damit ein bleibendes Denkmal. Erleben Sie erstmals veröffentlichten Aufnahme des Konzerts den monumentalen Klang einer einzigartigen Verbundenheit von Dirigent, Orchester und Komposition.',
    fullText:'»Die Symphonie war zu Ende, und ein Mann in der sechsten Reihe springt auf und schreit ›Bravo!‹. Das war Karajan, damit war der Schostakowitsch gerettet, alle Leute wussten, das muss gut sein«, erinnerte sich 2016 Barbara Sanderling, die Witwe des Dirigenten Kurt Sanderling, an dessen Aufführung von Schostakowitschs Achter Symphonie mit der Staatskapelle Dresden bei den Salzburger Festspielen 1965. Der damalige Festspielleiter Herbert von Karajan hatte die Staatskapelle – auf dem Höhepunkt des Kalten Krieges – zu einer mehrwöchigen Residenz nach Salzburg eingeladen und stand bei dieser Gelegenheit auch erstmals selbst am Pult des Orchesters.\n\nNoch zwei Mal, 1972 und 1976, war die Staatskapelle in der Folge mit Residenzen bei den Festspielen zu Gast – so häufig, wie es die DDR-Behörden erlaubten. Bei ihrem Gastspiel im Sommer 1976 dirigierte Karajan dann selbst im Großen Festspielhaus ein Werk von Schostakowitsch: die Zehnte Symphonie. Peter Cossé schwärmte damals in den Salzburger Nachrichten: »Ich habe Karajan in letzter Zeit nicht mehr so treibend, so um die Erfüllung des dynamischen ›Solls‹ besorgt gesehen, und sein Impetus wirkte sich auf den Spiel- und Ausdruckswillen der Dresdner wie eine befeuernde Arznei aus.« Die vorliegende CD enthält den Rundfunkmitschnitt dieser letzten Zusammenarbeit Karajans mit der Staatskapelle."',
    imageUrl:'',
    releaseDate:{
      day:17,
      month:3,
      year:2017
    },
  },
  {
    id:2,
    title:'Orchestre de Paris',
    subtitle:'',
    teaser:'',
    fullText:"45 years ago today Karajan conducted the Orchestre de Paris in a memorial concert dedicated to their founder, French conductor Charles Munch, a man ‘much admired’ by Karajan. The repertoire was Brahms' ‘Ein deutsches Requiem’ and this was the first of two such concerts, the other taking place on the following day. Karajan took on the role of interim music advisor following Munch’s death in November 1968, conducting 20 concerts during his 2 year tenure, until the appointment of Georg Solti in 1972.",
    imageUrl:'',
    releaseDate:{
      day:29,
      month:1,
      year:2017
    },
    tags:[
      {
        link:"/discover/composers/37",
        label:"BRAHMS"
      },
      {
        link:"/discover/orchestras/9",
        label:"ORCHESTRE DE PARIS"
      }
    ]
  },
  {
    id:3,
    title:'Wedding Anniversary',
    subtitle:'',
    teaser:'',
    fullText:'Today marks the wedding anniversary of Herbert and Eliette. The pair married in Megeve at a civil ceremony in 1958 and it was a somewhat precipitate affair. Karajan’s personal assistant Andre von Mattoni recalls a phone call he received from Karajan on the eve of the wedding. ‘Come at once to Megeve, I’m getting married tomorrow!’ he said. Mattoni leapt in his car and was in Geneva the next morning. He then received another call from Karajan asking if he would get him a ring and a wedding bouquet as Karajan had forgotten to get either! Karajan and Eliette went onto have a long and happy marriage. Since Karajan’s death in 1989 Eliette has made it her life’s work to maintain and continue her husband’s musical legacy through the Eliette and Herbert von Karajan Institute.',
    imageUrl:'http://www.crescendo.de/wp-content/uploads/2008/01/Karajan4_c_Fotos-Henschel-Verlag-privat.jpg',
    releaseDate:{
      day:27,
      month:1,
      year:2017
    },
    tags:[
      {
        link:'/discover/locations/109',
        label:'GENEVA'
      },
      {
        link:'/discover/artitst/109',
        label:'Andre von Mattoni'
      }
    ]
  },
  {
    id:4,
    title:'Orchestre de Paris',
    subtitle:'',
    teaser:'',
    fullText:"45 years ago today Karajan conducted the Orchestre de Paris in a memorial concert dedicated to their founder, French conductor Charles Munch, a man ‘much admired’ by Karajan. The repertoire was Brahms' ‘Ein deutsches Requiem’ and this was the first of two such concerts, the other taking place on the following day. Karajan took on the role of interim music advisor following Munch’s death in November 1968, conducting 20 concerts during his 2 year tenure, until the appointment of Georg Solti in 1972.",
    imageUrl:'',
    releaseDate:{
      day:3,
      month:1,
      year:2017
    },
    tags:[
      {
        link:"/discover/composers/37",
        label:"BRAHMS"
      },
      {
        link:"/discover/orchestras/9",
        label:"ORCHESTRE DE PARIS"
      }
    ]
  },

];