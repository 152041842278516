import React, { Component } from 'react';

class MainNavigationSideBarItem extends Component {


  onSelected(){
    this.props.onViewSelected(this.props.index, this.props.path);
  }

  render() {

    let containerStyle = this.props.isActive ? this.styles().activeContainer : this.styles().container;

    return (
      <div style={containerStyle} onClick={this.onSelected.bind(this)}>
        <img style={this.styles().icon} src={this.props.icon} alt={this.props.label}/>
      </div>
    );
  }

  styles(){

    return {

      container: {
        width:'60px',
        height:'60px',
        color:'#fff',
        lineHeight:'60px',
        borderTop:this.props.position === 'bottom' ? '1px solid #4A4A4A' : '',
        borderBottom:this.props.position === 'bottom' ? '' : '1px solid #4A4A4A',
        cursor:'pointer',
        fontSize:'12px',
      },
      activeContainer: {
        width:'60px',
        height:'60px',
        color:'#fff',
        lineHeight:'60px',
        borderTop:this.props.position === 'bottom' ? '1px solid #4A4A4A' : '',
        borderBottom:this.props.position === 'bottom' ? '' : '1px solid #4A4A4A',
        background:'#D9C56F',
        cursor:'pointer',
        fontSize:'12px',
      },
      icon:{
        marginTop:'14px',
      }

    }
  }
}

export default MainNavigationSideBarItem;