import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from '../views/ResponsiveView';

import Radium from 'radium';


class ButtonRoundedWithIcon extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onButtonClick(){
    if(this.props.onButtonClick) this.props.onButtonClick(this.props.id);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>
        <img src={this.props.icon} alt="" style={this.styles().icon}/> {this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        textAlign:'center',
        background:this.props.background ? this.props.background : null,
        border:this.props.borderColor ? ('1px solid '+ this.props.borderColor) : null,
        color:this.props.labelColor,
        fontSize:'18px',
        fontWeight:'900',
        height:'48px',
        borderRadius:'24px',
        padding:'0 20px',
        lineHeight:'48px',
        display:'inline-block',
        cursor:'pointer',
        minWidth:'100px',
        ':hover':{
          border:('1px solid '+this.props.highlightColor),
        }
      },
      icon:{
        marginRight:'5px',
      },
    }

  }
}

ButtonRoundedWithIcon.propTypes = {
  onButtonClick:PropTypes.func.isRequired,
  id:PropTypes.number,
  label:PropTypes.string,
  background:PropTypes.string,
  borderColor:PropTypes.string,
  labelColor:PropTypes.string,
  icon:PropTypes.string,
  highlightColor:PropTypes.string.isRequired,
};

export default Radium(ButtonRoundedWithIcon);