export const recommendations = [
  {
    id:1,
    title:'Franz List',
    subtitle:'AUFNAHME',
    imageUrl:'https://media1.britannica.com/eb-media/36/133136-004-F438C927.jpg',
    link:'/discover/composers/1',
  },
  {
    id:2,
    title:'Philharmonie Berlin',
    subtitle:'ORT',
    imageUrl:'http://www.eventim.de/obj/media/DE-eventim/galery/venue/p/philharmonie-berlin-tickets-07-2013-02.jpg',
    link:'/discover/locations/1',
  },
  {
    id:3,
    title:'Orchesterkonzert',
    subtitle:'KONZERT',
    imageUrl:'https://tubestatic.orf.at/static/images/site/tube/20170622/wiener-sternstunden-herbert-von-karajan-1987.5613079.jpg',
    link:'/discover/concerts/1',
  },
  {
    id:4,
    title:'Paris',
    subtitle:'ORT',
    imageUrl:'https://countryheritagetours.com/wp-content/uploads/2017/02/france-paris-eiffel-tower.jpg',
    link:'/discover/locations/2',
  },
];