import React from 'react';
import PropTypes from 'prop-types';
//import Radium from 'radium';
import mapboxgl from 'mapbox-gl';

import {readLocalJSON, convertJSONtoGeoJSON} from '../utils/helpers';
// import {getMapDirections} from '../utils/apiService';

import ResponsiveView from '../views/ResponsiveView';

import LockedLabel from './LockedLabel';

import '../css/MapPopup.css';

class MapPreview extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      flying:false,
      geoJsonData:null,
      lastPopup:null,
      displayedRoute:null,
      isTravelling:false,
      currentWaypoint:null,
      isMapReady:false,
      isPoiFilterActive:false,
    };

    this.animateMapTo = this.animateMapTo.bind(this);


    this.onMapMoveStart = this.onMapMoveStart.bind(this);
    this.onMapMoveEnd = this.onMapMoveEnd.bind(this);

    this.onClusterMouseEnter = this.onClusterMouseEnter.bind(this);
    this.onClusterMouseLeave = this.onClusterMouseLeave.bind(this);
    this.onClusterClick = this.onClusterClick.bind(this);

    this.onPOIMouseEnter = this.onPOIMouseEnter.bind(this);
    this.onPOIMouseLeave = this.onPOIMouseLeave.bind(this);
    this.onPOIClick = this.onPOIClick.bind(this);

    this.viewContainer = null;
  }

  componentDidMount(){
    console.log("Props for MapPreview: ", this.props);

    if(this.props.poiDataUrl){
      this.readPlaceData();
    } else if(this.props.poiData){
      this.onPlaceDataReady(this.props.poiData, false);
    }


  }

  UNSAFE_componentWillReceiveProps(nextProps){

    console.log("componentWillReceiveProps in MapPreview: ", nextProps);

    if(this.props.isLocked !== nextProps.isLocked){
      this.props = nextProps;

      this.initMap();
    }

    if(nextProps.poiData !== null && this.props.poiData !== nextProps.poiData){
      this.props = nextProps;
      this.onPlaceDataReady(this.props.poiData, false);
    } else if(this.props.poiDataUrl !== nextProps.poiDataUrl){

      console.log("We need to reload the journey");
      this.props = nextProps;
      this.readPlaceData();

    } else if(!this.props.isLocked){

      if(this.props.isFullScreen !== nextProps.isFullScreen){
        this.onViewResize();
      }

      if(this.props.coordinates !== nextProps.coordinates){

        this.props = nextProps;
        if(this.props.coordinates[0] && this.props.coordinates[1]) this.animateMapTo(this.props.coordinates);
      }

      if(this.props.mapBoundingRect !== nextProps.mapBoundingRect || this.props.poiHighlights !== nextProps.poiHighlights){

        this.props = nextProps;

        this.clearPOIHighlights();

        if(this.props.poiHighlights) {
          if(this.state.isMapReady){
            this.addPOIHighlights();
          }
        }
        if(this.props.mapBoundingRect) {
          this.positionMapOnBoundingRect(this.props.mapBoundingRect);
        } else {
          this.setPOIVisibility(true);
        }
      }


    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onViewResize);

    if(this.map) {
     this.unregisterEventListeners();
    }

    this.map = null;
  }

  onViewResize() {
    console.log("on view resize mapPreview");
    if(this.map) this.map.resize();
  }

  readPlaceData(){

    console.log("readPlaceData from: ", this.props.poiDataUrl);

    readLocalJSON(this.props.poiDataUrl, function(result){
      console.log("readLocalJSON returned: ", result);

      let keepOrder = this.props.keepOrder ? this.props.keepOrder : false;
      this.onPlaceDataReady(result, keepOrder);

    }.bind(this));

  }

  onPlaceDataReady(places, keepOrder){

    console.log("onPlaceDataReady: ", places);

    convertJSONtoGeoJSON(places, keepOrder, function(geoResult){
      console.log("GeoResult: ", geoResult);

      this.setState({
        geoJsonData:geoResult,
      }, function(){

        if(!this.props.isLocked) {
          if(!this.map) {
            this.initMap();
          } else {

            this.clearSources();
            this.clearPOIHighlights();

            if(this.props.onMapLoaded) this.props.onMapLoaded(this.map, this.state.geoJsonData);


            this.loadPOIData();


          }
        }
      });

    }.bind(this))

  }

  initMap(){

    console.log("INIT MAP");

    mapboxgl.accessToken = 'pk.eyJ1IjoiaHZrYXJhamFuIiwiYSI6IkNRMnUxTU0ifQ.qcOt5boGJZhgiECS9qvizw';

    this.map = new mapboxgl.Map({
      container: 'mapContainer',
      //style: 'mapbox://styles/hvkarajan/citfwijz1000f2ip3ckehy1p8',
      //style: 'mapbox://styles/hvkarajan/cj6xbejqa14zn2ro6q135uttv', // Karajan_v1-web
      //style: 'mapbox://styles/hvkarajan/cj6xdc7dx16sb2ro685qmfxa2', // Karajan_v1-web-copy
      style:'mapbox://styles/hvkarajan/cj6xfdzg418u42speqqkld7vx', // Karajan_v1-web-clean
      center: this.props.coordinates[0] && this.props.coordinates[1] ? this.props.coordinates : this.props.defaultCoordinates,
      zoom: this.props.zoomLevel ? this.props.zoomLevel : 13,
      attributionControl: false,
      interactive: this.props.interactive,
      maxTileCacheSize:1,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', function () {

      if(this.props.onMapLoaded) this.props.onMapLoaded(this.map, this.state.geoJsonData);

      this.setState({
        isMapReady:true,
      },function(){
        this.loadPOIData();
      });

    }.bind(this));

  }

  loadPOIData(){

    console.log("add Source for geoJsonData: ", this.state.geoJsonData);

    if(this.map) {

      this.map.addSource('locations', {
        type: 'geojson',
        data: this.state.geoJsonData,
        cluster: this.props.clustersActive,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });


      this.map.addLayer({
        "id": "points",
        "type": "symbol",
        "source": "locations",
        filter: ["!has", "point_count"],
        "layout": {
          "icon-image": this.props.markerAsset ? this.props.markerAsset : "map_marker_default",
          "icon-allow-overlap": true,
          "icon-offset": [0,-18],
          "text-field": {base: 1, stops: [[0, ""], [10, "{title}"]]},
          "text-font": ["Lato Bold", "Arial Unicode MS Regular"],
          "text-letter-spacing": 0.08,
          "text-offset": [0, 3],
          "text-size": 11,
          "text-transform": "uppercase",
          "visibility": "visible",
        },
        "paint": {
          "text-color": "hsl(0,0,40%)",
          "text-translate": [0, 0]
        }
      });


      this.map.addLayer({
        id: "clusters",
        type: "circle",
        source: "locations",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": {
            property: "point_count",
            type: "interval",
            stops: [
              [0, "rgba(26,26,26,.85)"],
              [30, "rgba(26,26,26,.85)"],
              [60, "rgba(26,26,26,.85)"],
            ]
          },
          "circle-radius": {
            property: "point_count",
            type: "interval",
            stops: [
              [0, 20],
              [30, 30],
              [60, 40]
            ]
          }
        }
      });

      this.map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "locations",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 15,
        },
        paint: {
          "text-color": {
            property: "point_count",
            type: "interval",
            stops: [
              [0, "#D9C56F"],
              [30, "#D9C56F"],
              [60, "#D9C56F"],
            ]
          }
        }
      });

      this.unregisterEventListeners();
      this.registerEventListeners();

      //this.clearSources();

      if(this.props.poiHighlights) this.addPOIHighlights();
      if(this.props.mapBoundingRect) this.positionMapOnBoundingRect(this.props.mapBoundingRect);

    }

  }

  clearSources(){

    if(!this.map) return;

    if(this.map.getSource('locations')) this.map.removeSource('locations');

    if(this.map.getSource('highlightLocations')) this.map.removeSource('highlightLocations');

    if(this.map.getLayer('clusters')) this.map.removeLayer('clusters');
    if(this.map.getLayer('cluster-count')) this.map.removeLayer('cluster-count');
    if(this.map.getLayer('points')) this.map.removeLayer('points');
  }

  addPOIHighlights(){

    if(!this.map) return;

    let geoJSON = {
      type: "FeatureCollection",
      features:[],

    };

    if(this.props.mapBoundingRect){
      this.setPOIVisibility(false);
    } else {
      this.setPOIVisibility(true);
    }


    this.props.poiHighlights.forEach(function(el){
      geoJSON.features.push({
        "type": "Feature",
        "properties": {},
        "geometry": {
          "type": "Point",
          "coordinates": [el[0], el[1]],
        }
      });
    });

    this.map.addSource('highlightLocations', {
      type: 'geojson',
      data: geoJSON,
      cluster: this.props.clustersActive,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    this.map.addLayer({
      id: "poiHighlights",
      type: "circle",
      source: "highlightLocations",
      paint: {
        "circle-color": "rgba(100,100,100,1)",
        "circle-radius": 8,
      },
      minzoom:11,
    });

    this.map.addLayer({
      "id": "poiHighlightsMarker",
      "type": "symbol",
      "source": "highlightLocations",
      "layout": {
        "icon-image": "map_marker_highlight",
        "icon-allow-overlap": true,
        "icon-offset": [0,-16],
        "visibility": "visible",
        "icon-size":1.3,
      },
      "paint": {
        "text-color": "hsl(0,0,40%)",
        "text-translate": [0, 0]
      },
      //minzoom:11,
    });

    //this.map.moveLayer("poiHighlights", "cluster-count");
    //this.map.moveLayer("poiHighlightsMarker", "cluster-count");

  }

  setPOIVisibility(visible){

    if(visible){
      if(this.map.getLayer('clusters')) this.map.setLayoutProperty('clusters', 'visibility', 'visible');
      if(this.map.getLayer('cluster-count')) this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
      if(this.map.getLayer('points')) this.map.setPaintProperty('points', 'icon-opacity', 1);
    } else {
      if(this.map.getLayer('clusters')) this.map.setLayoutProperty('clusters', 'visibility', 'none');
      if(this.map.getLayer('cluster-count')) this.map.setLayoutProperty('cluster-count', 'visibility', 'none');
      if(this.map.getLayer('points')) this.map.setPaintProperty('points', 'icon-opacity', 0);
    }

    this.setState({
      isPoiFilterActive:!visible,
    })

  }


  clearPOIHighlights(){

    if(!this.map) return;
    if(this.map.getLayer('poiHighlights')) this.map.removeLayer('poiHighlights');
    if(this.map.getLayer('poiHighlightsMarker')) this.map.removeLayer('poiHighlightsMarker');
    if(this.map.getSource('highlightLocations')) this.map.removeSource('highlightLocations');

  }

  registerEventListeners(){

    this.map.on('click', 'clusters', this.onClusterClick);

    // Change the cursor to a pointer when the mouse is over an item on the clusters layer.
    this.map.on('mouseenter', 'clusters', this.onClusterMouseEnter);
    this.map.on('mouseleave', 'clusters', this.onClusterMouseLeave);


    this.map.on('click', 'points', this.onPOIClick);
    //this.map.on('click', 'poiHighlightsMarker', this.onPOIClick);

    // Change the cursor to a pointer when the mouse is over the an item on places layer.
    this.map.on('mouseenter', 'points', this.onPOIMouseEnter);
    this.map.on('mouseleave', 'points', this.onPOIMouseLeave);

    this.map.on('movestart', this.onMapMoveStart);
    this.map.on('moveend', this.onMapMoveEnd);

  }

  unregisterEventListeners(){
    this.map.off('movestart', this.onMapMoveStart);
    this.map.off('moveend', this.onMapMoveEnd);

    this.map.off('click', 'clusters', this.onClusterClick);
    this.map.off('mouseenter', 'clusters', this.onClusterMouseEnter);
    this.map.off('mouseleave', 'clusters', this.onClusterMouseLeave);

    this.map.off('click', 'points', this.onPOIClick);
    //this.map.off('click', 'poiHighlightsMarker', this.onPOIClick);
    this.map.off('mouseenter', 'points', this.onPOIMouseEnter);
    this.map.off('mouseleave', 'points', this.onPOIMouseLeave);
  }

  onPOIMouseEnter(e){
    if(this.map) this.map.getCanvas().style.cursor = 'pointer';
  }

  onPOIMouseLeave(e){
    if(this.map) this.map.getCanvas().style.cursor = '';
  }

  onClusterMouseEnter(e){
    if(this.map) this.map.getCanvas().style.cursor = 'pointer';
  }

  onClusterMouseLeave(e){
    if(this.map) this.map.getCanvas().style.cursor = '';
  }

  onClusterClick(e){

    var feature = e.features[0];
    console.log("this.map: ", this.map.getZoom());
    console.log("onClusterClick feature: ", e);

    this.animateMapTo([e.lngLat.lng, e.lngLat.lat], this.map.getZoom()+2.5);

  }

  onPOIClick(e){

    var feature = e.features[0];

    console.log("clicked on poi with data: ", feature);

    let subPlaces = feature.properties.childLocations ? JSON.parse(feature.properties.childLocations) : null;

    if(!this.props.isJourneyMap){

      if(subPlaces && subPlaces.length > 0) subPlaces.unshift({hall:feature.properties.name, id:feature.properties.id});

      var popup = new mapboxgl.Popup({ offset: [0, -40] })
        .setHTML(this.getPopupHTML(feature.properties, subPlaces))
        .setLngLat(feature.geometry.coordinates)
        .addTo(this.map);

      if(subPlaces){

        subPlaces.forEach(function(el, i){

          document.getElementById('popupItem'+i)
            .addEventListener('click', function(){
              el.id = el.id;
              console.log("clicked the button", el);
              this.onMarkerClick(el);
            }.bind(this));

        }.bind(this));

      }

    }

    if(!subPlaces || (subPlaces && subPlaces.length === 0)) {

      setTimeout(function(){
        this.onMarkerClick(feature);
      }.bind(this), 700);

    }



    this.setState({
      lastPopup:popup,
    })

  }

  getPopupHTML(place, subPlaces){

    let name = place.name ? place.name : 'Stadt: '+place.city;

    let htmlString = "";

    htmlString += '<div id="popupBg" style="padding:10px 20px">';
    htmlString += '<div style="font-size:15px; font-weight:700;">' + name + '</div>';

    if(subPlaces && subPlaces.length > 0){

      htmlString += '<div class="placeItemContainer">';

      subPlaces.forEach(function(el, i){

        let subName =  el.hall ? el.hall : el.name;

        htmlString += '<div class="subPlaceItem" id="popupItem'+i+'">' + subName + '</div>';
      });

      htmlString += '</div>';

    } else if(place.hall){
      htmlString += '<div style="margin-top:10px;">' + place.hall + '</div>';
    }

    htmlString += '</div>';

    return htmlString;
  }

  onMapMoveStart(){

    //console.log("MOVE START flying:", this.state.flying);
    if(this.state.lastPopup) {
      this.state.lastPopup.remove();
      this.setState({lastPopup:null});
    }

    if(this.props.onMapMoveStart) this.props.onMapMoveStart();

  }

  onMapMoveEnd(){

    //console.log("MOVE END");


    if(this.props.onMapMoveEnd) this.props.onMapMoveEnd();
  }

  onMarkerClick(properties){
    if(properties.subPlaces) {
      console.log("onMarkerClick: ", JSON.parse(properties.subPlaces));
    } else {
      console.log("onMarkerClick: ", properties);
    }
    if(this.props.onMarkerSelected) this.props.onMarkerSelected(properties);
  }

  animateMapTo(coordinates, zoomTo, speed, curve){

    console.log("animateMapTo coordinates: ", coordinates);

    if(!this.map) return;

    this.setState({
      flying:true,
    });


    this.map.flyTo({
      center: [
        coordinates[0],
        coordinates[1]
      ],
      //duration:2000,
      zoom: zoomTo ? zoomTo : 15,
      speed: speed ? speed : 1.5,
      curve: curve ? curve : 1.2,
    });

  }

  positionMapOnBoundingRect(rect){
    console.log("positionMapOnBoundingRect: ", rect);

    if(!this.map || !rect) return;

    // if all concerts/recordings where in the same location
    if(rect[0][0] === rect[1][0]){
      console.warn("ALL THE SAME");
      this.animateMapTo(rect[0]);
    } else {
      this.map.fitBounds(rect, {padding: {top: 100, bottom:60, left: 60, right: 60}});
    }


  }

  onPoiVisibilityToggle(){

    if(this.state.isPoiFilterActive){
      this.setPOIVisibility(true);
    } else {
      this.setPOIVisibility(false);
    }


  }


  render() {

    return (

      <div style={this.styles().container} ref={(c) => {this.viewContainer = c}}>
        {this.props.isLocked
          ? <div style={this.styles().labelContainer}>
              <LockedLabel label="LANDKARTE"/>
            </div>
          : null
        }

        {this.props.mapBoundingRect
          ? <div style={this.styles().filterLabelContainer} id="mapLabel" onClick={this.onPoiVisibilityToggle.bind(this)}>
              <input
                type="checkbox"
                checked={this.state.isPoiFilterActive}
                onChange={()=>{}}
                style={this.styles().filterCheckbox}
              />
              <span style={this.styles().filterLabel}>
                {"Nur "+this.props.poiFilterType+" des "+this.props.infoCardType+" anzeigen"}
              </span>
            </div>
          : null
        }


        <div id="mapContainer" style={this.styles().mapContainer}></div>

      </div>


    );
  }


  styles(){

    let isSmallScreen = (window.innerWidth <= 480 || (this.viewContainer && this.viewContainer.clientWidth <= 490))

    return {

      container:{
        background:this.props.isLocked ? null : '#f9f9f9',
        height:'100%',
        width:'100%',
        boxShadow:this.props.isLocked ? null : '0 1px 3px rgba(0,0,0,.2)',
        border:this.props.isLocked ? '1px solid #DAC46D' : null,
        position:'relative',
      },
      mapContainer:{
        width:'100%',
        height:'100%',
        minHeight:'340px',
        borderRadius:'3px',
        overflow:'hidden',
        position:'relative',
        display:'flex',
      },
      filterLabelContainer:{
        zIndex:'5',
        position:'absolute',
        top:'10px',
        marginLeft:'50%',
        transform:'translateX(-50%)',
        background:'#fff',
        lineHeight:!isSmallScreen ? '30px' : '15px',
        borderRadius:!isSmallScreen ? '15px' : '3px',
        boxShadow:'0 0 5px rgba(0,0,0,.3)',
        maxWidth:'300px',
        cursor:'pointer',
        padding:!isSmallScreen ? '0 10px' : '6px 10px',
      },
      filterLabel:{
        verticalAlign:'top',
        fontSize:'13px',
        display: !isSmallScreen ? null : 'block',
      },
      filterCheckbox:{
        display:'inline-block',
        width:'inherit',
        verticalAlign:'text-bottom',
        marginRight:'6px',
      }

    }

  }
}


MapPreview.defaultProps = {
  isLocked:true,
  clustersActive:true,
};

MapPreview.propTypes = {
  onMapLoaded:PropTypes.func,
  onMapMoveStart:PropTypes.func,
  onMapMoveEnd:PropTypes.func,
  onMarkerSelected:PropTypes.func,
  isLocked:PropTypes.bool,
  zoomLevel:PropTypes.number,
  poiDataUrl:PropTypes.string,
  poiData:PropTypes.array,
  keepOrder:PropTypes.bool,
  isJourneyMap:PropTypes.bool,
  clustersActive:PropTypes.bool,
  mapBoundingRect:PropTypes.array,
  poiHighlights:PropTypes.array,
  markerAsset:PropTypes.string,
  coordinates:PropTypes.array,
  defaultCoordinates:PropTypes.array,
  poiFilterType:PropTypes.string,
  infoCardType:PropTypes.string,
};


export default MapPreview;