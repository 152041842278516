import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import ResponsiveView from '../views/ResponsiveView';

import PlaylistItem from './PlaylistItem';
import BigButtonRoundToggle from './buttons/BigButtonRoundToggle';
import ListItemToggle from './ListItemToggle';

import dummySound from '../sounds/dummy_sound.m4a';
import { withTranslation } from 'react-i18next';
import { capitalizeTitle } from '../utils/helpers';

class PlaylistPlayer extends ResponsiveView {

  constructor(props){
    super(props);

    console.log("playlist in playlistplayer: ", this.props.playlist);

    this.onPlaylistButtonClick = this.onPlaylistButtonClick.bind(this);
    this.startPlaylist = this.startPlaylist.bind(this);
    this.stopPlaylist = this.stopPlaylist.bind(this);
    this.onTrackEnd = this.onTrackEnd.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onOutput = this.onOutput.bind(this);

    this.state = {
      isPlaying:this.props.isPlaying ? this.props.isPlaying : false,
      defaultTrackUrl:dummySound, // dummySound is played before actual playlist is started => workaround to make playback work on iOS and Android
      trackId:0,
      queuedTrackId:null,
      output:"NO OUTPUT",
      playlistExpanded:false,
      currentProgress:0,
      displayErrorMessage:false,
    };

    this.errorTimeouts = [];

  }

  UNSAFE_componentWillReceiveProps(nextProps){

    console.log("***");
    console.log("nextProps.isPlaying: ", nextProps.isPlaying, " / this.state.isPlaying:", this.state.isPlaying);
    if(nextProps.isPlaying != this.state.isPlaying){
      console.log("The playback state has been updated from InfoCard to: ", nextProps.isPlaying);
      this.props = nextProps;

      if(this.state.isPlaying){
        this.stopPlaylist();
      } else if(this.props.isPlaying){
        //let startTrack = this.state.queuedTrackId ? this.state.queuedTrackId : 1;
        this.startPlaylist();
      }
    }
  }

  componentWillUnmount(){

    if(this.errorTimeouts && this.errorTimeouts.length > 0){
      this.errorTimeouts.forEach(function(el){
        clearTimeout(el);
      });
      this.errorTimeouts = [];
    }

    if(this.props.onPlaybackStop) this.props.onPlaybackStop();

  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }


  // Controls

  onPlaylistButtonClick(){
    if(this.state.isPlaying){
      this.stopPlaylist();
    } else {
      this.startPlaylist(1);
    }
  }

  onItemSelected(id){
    console.log("onItemSelected in Playlist: ", id);

    /*
    if(this.state.trackId === id){
      if(this.props.onPlaybackStop) this.props.onPlaybackStop();
    } else {
      this.setState({
        queuedTrackId:id,
      },function(){
        if(this.props.onPlaybackStart) this.props.onPlaybackStart();
      });

    }*/

    if(this.state.trackId === id){
      this.stopPlaylist();
    } else {
      this.startPlaylist(id);
    }

  }


  // Playlist

  startPlaylist(id){
    console.log("startPlaylist with id: ", id);

    if(this.state.trackId === 0){
      this.setState({
        isPlaying: true,
        queuedTrackId:id,
        currentProgress:0,
      }, function(){
        if(this.props.onPlaybackStart) this.props.onPlaybackStart();
      });
    } else {
      this.startTrack(id);
    }
  }

  stopPlaylist(){
    this.setState({
      isPlaying: false,
      trackId:0,
    }, function(){
      if(this.props.onPlaybackStop) this.props.onPlaybackStop();
    });

  }

  onProgress(playbackState){
    //console.log("playbackState: ", playbackState);
    this.onOutput(playbackState.played);

    this.setState({
      currentProgress: (this.state.trackId === 0) ? 0 : playbackState.played,
    })
  }

  onOutput(outputTxt){

    this.setState({
      output:outputTxt,
    })
  }

  onPlaybackError(trackUrl){
    console.warn("There was an error, trying to playback the audioUrl: ", trackUrl);
    this.setState({
      displayErrorMessage:true,
      isPlaying:false,
      trackId:0,
    }, function(){
      if(this.props.onPlaybackStop) this.props.onPlaybackStop();

      this.errorTimeouts.push(setTimeout(function(){

        console.log("*** CLEAR ERROR MESSAGE ***");
        this.setState({
          displayErrorMessage:false,
        })

      }.bind(this), 3000));

    });

  }

  startTrack(id){

    console.log("startTrack with id: ", id);

    // to make audio playback work on iOS and Android isPlaying has to be set to false first and then back to true
    // to trigger the next track
    this.setState({
      isPlaying: false,
      queuedTrackId:null,
      trackId:id,
      currentProgress:0,
    },function(){
      this.setState({
        isPlaying:true,
      }, function(){
        if(this.props.onPlaybackStart) this.props.onPlaybackStart();
      });
    });
  }

  onTrackEnd(){
    console.log("onTrackEnd ", this.state.trackId, "this.state.queuedTrackId: ", this.state.queuedTrackId);

    if(this.state.queuedTrackId){

      this.startTrack(this.state.queuedTrackId);

    } else if(this.state.trackId <= this.props.playlist.length-1){

      let nextTrack = this.state.trackId+1;

      this.startTrack(nextTrack);

    } else {
      console.log("end of list");
      this.setState({
        isPlaying: false,
        trackId:0,
      }, function(){
        if(this.props.onPlaybackStop) this.props.onPlaybackStop();
      });
    }
  }



  onTogglePlaylist(){
    this.setState({
      playlistExpanded:!this.state.playlistExpanded,
    })
  }

  // Rendering

  renderPlaylistItems(){

    const {t} = this.props;

    let items = [];
    let listLength = this.props.playlist.length;
    let listToggle;

    if(listLength > this.props.collapseAfter) {
      listToggle = <ListItemToggle
        key={'listItemToggle'}
        label={t('playlist.showAll')}
        labelActive={t('playlist.showLess')}
        onItemSelected={this.onTogglePlaylist.bind(this)}
        active={this.state.playlistExpanded}
      />
    }

    if(!this.state.playlistExpanded && listLength > this.props.collapseAfter) {
      listLength = this.props.collapseAfter;
    }

    for(let i=0; i<listLength;i++){

      let pItem = this.props.playlist[i];

      items.push(
        <PlaylistItem
          title={pItem.title ? capitalizeTitle(pItem.title) : pItem.title}
          duration={pItem.referenceTrack ? pItem.referenceTrack.duration : 0}
          currentProgress={this.state.currentProgress}
          key={i}
          id={i+1}
          isActive={(i+1===this.state.trackId && this.state.isPlaying)}
          onItemSelected={this.onItemSelected.bind(this)}
          insetContent={this.props.headless}
          lastItem={i===this.props.playlist.length-1 && listLength <= this.props.collapseAfter}
          showDuration={false}
        />
      )
    }


    if(listToggle) items.push(listToggle);

    return items;

  }



  render() {

    /*
    if(this.state.trackId != 0) console.log("AudioUrl to play in render: ", this.props.playlist.items[this.state.trackId-1].audioUrl);
    console.log("trackId to play in render: ", this.state.trackId);
    */
    return (
      <div style={this.styles().container} id="PlaylistContainer">


        {!this.props.headless
          ? <div style={this.styles().playlistHeader}>
              <div style={this.styles().playlistTitle}>
                {this.props.title ? capitalizeTitle(this.props.title) : 'Title'}
              </div>
              <div style={this.styles().playlistSubtitle}>
                {this.props.subtitle ? capitalizeTitle(this.props.subtitle) : 'Subtitle'}
              </div>

              <div style={this.styles().buttonContainer}>
                <BigButtonRoundToggle
                  label={this.props.isPreview ? "VORSCHAU ABSPIELEN" : "ABSPIELEN"}
                  activeLabel="STOP"
                  labelColor="#fff"
                  background="#1C6ECC"
                  onButtonClick={this.onPlaylistButtonClick}
                  isActive={this.state.isPlaying}
                  minWidth='200px'
                />
                {/*<span>{this.state.output}</span>*/}
              </div>
            </div>
          : null
        }


        {this.state.displayErrorMessage
          ? <div style={this.styles().errorMessage}>
              Track kann nicht abgespielt werden
            </div>
          : null
        }

        <div style={this.styles().listContainer}>

          {this.renderPlaylistItems()}

        </div>


        <ReactPlayer
            width='0px'
            height='0px'
            ref={player => { this.player = player }}
            //url={this.state.trackId > -1 ? this.props.playlist.items[this.state.trackId].audioUrl : ''}
            url={this.state.trackId == 0 ? this.state.defaultTrackUrl : (this.props.playlist[this.state.trackId-1] ? this.props.playlist[this.state.trackId-1].referenceTrack.audioUrl : '')}
            playing={this.state.isPlaying}
            onReady={() => console.log('onReady')}
            onEnded={() => this.onTrackEnd()}
            onError={() => this.onPlaybackError()} //
            onProgress={this.onProgress}
            fileConfig={{forceAudio:true}}
          />


      </div>
    );
  }


  styles(){

    return {

      container:{
        //marginTop:this.props.headless ? null : '30px',
        //paddingBottom:this.props.headless ? null : '30px',
        position:'relative',
        width:'100%',
        overflow:'hidden',
      },
      playlistHeader: {
        position:'relative',
        marginBottom:'25px',
        paddingTop:'5px',
      },
      playlistTitle: {
        fontSize:'16px',
        fontWeight:'700',
      },
      playlistSubtitle: {
        fontSize:'14px',
        marginTop:'5px'
      },
      buttonContainer:{
        position:window.innerWidth >= 768 ? 'absolute' : 'relative',
        top:'0px',
        right:'0px',
        marginTop:window.innerWidth >= 768 ? null : '20px',
      },
      errorMessage:{
        borderTop:'1px solid #e6e6e6',
        padding:'12px 0',
        textAlign:'center',
        color:'#f00',
      },
      listContainer: {

      }

    }

  }
}

PlaylistPlayer.propTypes = {
  title:PropTypes.string,
  subtitle:PropTypes.string,
  headless:PropTypes.bool,
  playlist:PropTypes.array,
  onPlaybackStart:PropTypes.func,
  onPlaybackStop:PropTypes.func,
  isPreview:PropTypes.bool
};

export default withTranslation()(PlaylistPlayer);