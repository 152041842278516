import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import icnClose from '../img/icn_close_light.svg';

import '../cssAnimations/SearchHeaderAnims.css';

class SearchHeader extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      isActive:false,
      searchQuery:'',
    }
  }

  onViewResize(){
    return true;
  }


  onInputChange(e){

    this.setState({
      searchQuery:e.target.value,
    });

    if(this.props.onInputChange) this.props.onInputChange(e.target.value);
  }

  onActivateSearch(e){

    this.setState({
      isActive:true,
      searchQuery:'',
    }, function(){

      setTimeout(function(){
        this.searchInput.focus();
      },600);

    });

    //this.props.location.search = 'input=active';

  }

  onCloseSearch(){
    this.setState({isActive:false});
    if(this.props.onInputChange) this.props.onInputChange('');
    if(this.props.onCloseSearch) this.props.onCloseSearch();
  }

  render() {

    return (

      <div id="searchContainer" className={this.state.isActive ? 'active' : ''} style={this.styles().container}>
        <div id="icnSearch" className={this.state.isActive ? 'active' : ''} style={this.styles().searchIcon} onClick={this.onActivateSearch.bind(this)}>
          <svg width="24px" height="24px" viewBox="0 0 24 24" style={{display:'block'}}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(3.000000, 2.000000)">
                <circle id="searchCircle" className={this.state.isActive ? 'active' : ''} stroke="#4A4A4A" strokeWidth="2" cx="8" cy="8" r="7"/>
                <rect id="searchRectangle" className={this.state.isActive ? 'active' : ''} fill="#4A4A4A" transform="translate(15.000000, 16.000000) rotate(45.000000) translate(-15.000000, -16.000000) " x="11" y="15" width="8" height="2"/>
              </g>
            </g>
          </svg>
        </div>


        <input id='searchInput'
               type="text"
               className={this.state.isActive ? 'active' : ''}
               style={this.styles().searchInput}
               placeholder={this.props.placeholder}
               ref={input => this.searchInput = input}
               onChange={this.onInputChange.bind(this)}
               value={this.state.searchQuery}
        />

        <div id="icnClose" className={this.state.isActive ? 'active' : ''} style={this.styles().closeIcon} onClick={this.onCloseSearch.bind(this)}>
          <img src={icnClose} alt="" style={{display:'block'}}/>
        </div>

      </div>
    );
  }

  // Animation styles are defined in SearchHeaderAnim.css

  styles(){

    return {

      container:{
        position:'absolute',
        top:'0',
        right:'0',
        height:'60px',
        zIndex:this.state.isActive ? '2' : null,
      },
      searchIcon:{
        cursor:'pointer',
        padding:'18px 16px',
        zIndex:'1',
      },
      closeIcon:{
        position:'absolute',
        top:'0',
        right:'0',
        cursor:'pointer',
        padding:'18px 16px',
      },
      searchInput: {
        border:'none',
        height:'60px',
        fontSize:'16px',
        padding:'0',
        outline:'none',
        background:'transparent',
        color:'#fff',
      },

    }

  }
}

SearchHeader.propTypes = {
  onInputChange:PropTypes.func.isRequired,
  onCloseSearch:PropTypes.func,
  placeholder:PropTypes.string,
};

export default Radium(SearchHeader);