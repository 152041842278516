import React from 'react';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import icnClose from '../img/icn_lightbox_close.svg';
import icnNext from '../img/icn_lightbox_next.svg';
import icnPrev from '../img/icn_lightbox_prev.svg';

class LightBox extends ResponsiveView {

  constructor(props){
    super(props);

    let pos = 0;

    if (this.props.startPos) pos = this.props.startPos;

    this.state = {
      mediaPos:pos,
    }

  }

  onViewResize(){
    return true;
  }

  nextItem(){
    let currentPos = this.state.mediaPos;

    if(currentPos < this.props.media.length-1){
      currentPos++;

    } else {
      currentPos = 0;
    }

    this.setState({
      mediaPos:currentPos,
    })

  }

  prevItem(){
    let currentPos = this.state.mediaPos;

    if(currentPos > 0){
      currentPos--;

    } else {
      currentPos = this.props.media.length-1;
    }

    this.setState({
      mediaPos:currentPos,
    })
  }

  closeView(){
    this.props.closeView();
  }

  render() {

    let data = this.props.media[this.state.mediaPos];
    let imageUrl = data.imageUrl;

    return (
      <div style={this.styles().container} id="lightBox">
        {/*<div style={this.styles().contentContainer}>*/}

        <img style={this.styles().image} src={imageUrl} alt="" />

        {/*</div>*/}

        <div style={this.styles().nextButton} onClick={this.nextItem.bind(this)} key="nextButton">
          <img src={icnNext} alt="nextButtonIcon" style={this.styles().buttonIcon}/>
        </div>
        <div style={this.styles().prevButton} onClick={this.prevItem.bind(this)} key="prevButton">
          <img src={icnPrev} alt="prevButtonIcon" style={this.styles().buttonIcon}/>
        </div>

        <div style={this.styles().closeButton} onClick={this.closeView.bind(this)} key="closeButton">
          <img src={icnClose} alt="closeButtonIcon"/>
        </div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height:'100vh',
        background:'rgba(0,0,0,.8)',
        zIndex:'100',
      },
      contentContainer:{
        maxWidth:'85%',
        maxHeight:'85%',
        margin:'0 auto',
        background:'#fff',
        top:'50%',
        position:'relative',
        transform:'translateY(-50%)',
      },
      image:{
        display:'block',
        margin:'0 auto',
        background:'#fff',
        top:'50%',
        position:'relative',
        transform:'translateY(-50%)',
        maxWidth:'85%',
        maxHeight:'85%',
      },
      nextButton:{
        background:'#fff',
        width:'60px',
        height:'60px',
        borderRadius:'50%',
        position:'absolute',
        top:'50%',
        right:'20px',
        transform:'translateY(-50%)',
        cursor:'pointer',
        ':hover':{
          opacity:'.9'
        }
      },
      prevButton:{
        background:'#fff',
        width:'60px',
        height:'60px',
        borderRadius:'50%',
        position:'absolute',
        top:'50%',
        left:'20px',
        transform:'translateY(-50%)',
        cursor:'pointer',
        ':hover':{
          opacity:'.9'
        }
      },
      closeButton:{
        //background:'#fff',
        width:'40px',
        height:'40px',
        borderRadius:'50%',
        position:'absolute',
        top:'20px',
        right:'20px',
        cursor:'pointer',
        ':hover':{
          opacity:'.8'
        }
      },
      buttonIcon:{
        marginTop:'10px',
      }
    }

  }
}

export default Radium(LightBox);