export const typeDefs = `
  
  type Query {
    artists: [Person]
    artist(id: Int!): Person
    
    composers: [Person]
    composer(id: Int!): Person
    
    concerts: [Concert]        
    concert(id: Int): Concert
    
    events: [Event]
    event(id: Int!): Event
    
    locations: [Location]
    location(id: Int!): Location
    
    orchestras: [Orchestra]
    orchestra(id: Int!): Orchestra
    
    recordings: [Recording]
    recording(id: Int!): Recording
    
    musicworks: [MusicWork]
    musicwork(id: Int!): MusicWork
        
    collections(maxCount: Int): [Collection]
    collection(id: Int!): Collection
    
    stories(maxCount: Int): [Story]
    story(id: Int!): Story
    
    recommendations(maxCount: Int): [Recommendation]
  }
  
  type Person {
    id: Int!
    imageUrl: String
    firstName: String
    lastName: String
    role: String
    birthDate: Date
    deathDate: Date
    description: String
    recordings: [Recording]
    concerts: [Concert]
    works: [MusicWork]
  }
  
  type Concert {
    id: Int!
    title: String
    imageUrl: String
    composers: [Person]
    orchestra: Orchestra
    artists: [Person]
    date: Date
    location: Location
    works: [MusicWork]
    program: [MusicWork]
  }
  
  type Event {
    id: Int !,
    imageUrl: String,
    title: String,
    description: String
    date: Date,
    tags: [Tag]
    location: Location
  }
  
  type Location {
    id: Int!
    imageUrl: String
    latitude:Float
    longitude:Float
    name: String
    city: String
    country: String
    description: String
    events: [Event]
    concerts: [Concert]
    recordings: [Recording]
  }
  
  type Orchestra {
    id: Int!
    imageUrl: String
    name: String
    description: String
    recordings: [Recording]
    concerts: [Concert]
  }
  
  type Recording {
    id: Int! 
    imageUrl: String
    title: String
    composer: Person
    orchestra: Orchestra
    artists: [Person]
    type: String
    date: Date
    location: Location
    products: [Product]
    program: [MusicWork]
  }
  
  type MusicWork {
    id: Int!
    composer: Person
    title: String
    type: String
    concerts: [Concert]
    recordings: [Recording]
    parts: [MusicWorkPart]!
  }
  
  type MusicWorkPart {
    title: String,
    duration: Int,
    audioUrl: String,
    audioPreviewUrl: String
  }
  
  type Date {
    day: Int
    month: Int
    year: Int
  }
  
  type Tag {
    id: Int!
    link: String,
    label: String
  }
  
  type Product {
		id: Int!
		imageUrl: String
		shopUrl: String
	}
	
	type Collection {
	  id: Int!
	  title: String
	  subtitle: String
	  caption: String
	  imageUrl: String
	  program: [MusicWork]
	  location: Location
	  tags: [Tag]
	  story: Story
	  media: [MediaAsset]
	  credits : Credits	  
	}
	
	type MediaAsset {
	  id: Int!
	  type: String
	  thumbUrl: String
	  imageUrl: String
	}
  
  type Credits {
    id: Int!
    creditItems: [CreditItems]
    logoUrls: [String] 
  }
  
  type CreditItems {
    id: Int!
    label: String
    description: String
  }
  
  type Story {
	  id: Int!
	  title: String
	  subtitle: String
	  teaser: String
	  fullText: String
	  imageUrl: String 
	  releaseDate: Date
	  tags: [Tag]
	}
	
	type Recommendation {
	  id: Int!
	  title: String
	  subtitle: String
	  imageUrl: String 
	  link: String
	}
  
  `;