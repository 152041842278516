import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../views/ResponsiveView';

class DummyStoryItem extends ResponsiveView {


  onViewResize(){
    return true;
  }

  render() {

    return (
      <div style={this.styles().container}>

        <div style={this.styles().storyCard}>

        </div>

        <div style={this.styles().connectorContainer}>
          <div style={this.styles().connector}></div>
        </div>

        <div style={this.styles().verticalLineContainer}>
          <div style={this.styles().verticalLine}></div>
        </div>

        <div style={this.styles().connectorContainer}>
          <div style={this.styles().connectorEmpty}></div>
        </div>

        <div style={this.styles().secondColumnContainer}>
          <div style={this.styles().tagsContainer}>

          </div>

        </div>


      </div>
    );
  }

  styles(){

    let floatDirection = this.props.alignment;//'right';

    return {

      container:{
        textAlign:'left',
        position:'relative',
        display:window.innerWidth < 1024 ? 'block' : 'flex',
        flexDirection:floatDirection === 'right' ? 'row-reverse' : null,
      },
      storyCard:{
        marginTop:'15px',
        marginBottom:'15px',
        borderRadius:window.innerWidth <= 660 ? '0px' : '2px',
        background:'#3f3f3f',
        width:'100%',
        maxWidth:'660px',
        height:'320px',
        overflow:'hidden',
        marginLeft:window.innerWidth < 1024 ? 'auto' : null,
        marginRight:window.innerWidth < 1024 ? 'auto' : null,
      },
      connectorContainer:{
        display:window.innerWidth < 1024 ? 'none' : null,
        paddingTop:'65px',
        //display:'inline-block',
        //float:floatDirection,
        verticalAlign:'top',
      },
      connector:{
        display:window.innerWidth < 1024 ? 'none' : null,
        width:'16px',
        height:'2px',
        background:'#3f3f3f',
      },
      connectorEmpty:{
        display:window.innerWidth < 1024 ? 'none' : null,
        width:'16px',
        height:'2px',
      },
      verticalLineContainer:{
        display:window.innerWidth < 1024 ? 'none' : null,
        background:'#3f3f3f',
      },
      verticalLine:{
        display:window.innerWidth < 1024 ? 'none' : null,
        width:'2px',
        height:'100%',
        background:'#3f3f3f',
      },
      secondColumnContainer:{
        display:window.innerWidth < 1024 ? 'none' : null,
        width:'100%',
        verticalAlign:'top',
        paddingTop:'54px',
        textAlign:floatDirection,
      },
      tagsContainer:{
        marginTop:'8px',
        display:'flex',
        flexWrap:'wrap',
        flexDirection:floatDirection === 'right' ? 'row-reverse' : null,
      },

    }

  }
}

DummyStoryItem.propTypes = {
  color:PropTypes.string,
}


export default DummyStoryItem;