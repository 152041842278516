import React from 'react';

import ResponsiveView from './ResponsiveView';

import MapJourney from '../components/MapJourney';


class JourneysView extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      mapCenter:[16.3772449,48.200953], // Wien
      //mapCenter:[130.40448,33.583546] // Japan
      selectedJourney:'/mapdata/journey.json',

    };
  }



  onMarkerSelected(data){
    console.log("onMarkerSelected in JourneysView: ", data.id);

    if(data.id){
      //this.props.history.push('/discover/locations/'+data.id);
    } else {
      console.warn("Id for location is not set.");
    }

  }

  onSelectJourney(id){
    console.log("onSelectJourney: ", id);

    switch(id){
      case "Wien":
        this.setState({selectedJourney:'/mapdata/journey.json', mapCenter:[16.3772449,48.200953]});
        break;
      case "Japan":
        this.setState({selectedJourney:'/mapdata/journey_japan.json', mapCenter:[130.40448,33.583546]});
        break;
      default:
        break;
    }
  }

  render() {

    return (
      <div style={this.styles().view} id="journeysViewContainer">

        <div style={this.styles().journeySelectionContainer}>
          <div style={this.styles().journeyItem} onClick={this.onSelectJourney.bind(this, 'Wien')}>
            Wien
          </div>
          <div style={this.styles().journeyItem} onClick={this.onSelectJourney.bind(this, 'Japan')}>
            Japan
          </div>
        </div>

        <div style={this.styles().mapView}>
          <MapJourney
            mapCenter={this.state.mapCenter}
            onMarkerSelected={this.onMarkerSelected.bind(this)}
            //poiDataUrl="/mapdata/journey_japan.json"
            poiDataUrl={this.state.selectedJourney}
          />
        </div>

      </div>
    );
  }

  styles(){

    return {
      view:{
        backgroundColor:'#FAFAFA',
        backgroundSize:'cover',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
        position:'relative',
        height:'100vh',
        width:'100%',
        boxSizing:'border-box',
        //overflow:window.innerWidth >= 480 ? 'hidden' : null,
      },
      mapView:{
        marginLeft:this.state.isInfoContainerVisible ? '480px' : '0px',
        //width:'100%',
        height:'100%',
        /*
        position:'fixed',
        top:'0',
        width:'100%',
        height:'100vh'*/
      },
      journeySelectionContainer:{
        background:'rgba(255,255,255,.9)',
        boxShadow:'0 1px 5px rgba(0,0,0,.2)',
        position:'absolute',
        bottom:'10px',
        left:window.innerWidth >= 768 ? '70px' : '10px',
        right:'10px',
        borderRadius:'2px',
        zIndex:'1',
        padding:'10px',
      },
      journeyItem:{
        textAlign:'center',
        border:'1px solid #141414',
        padding:'0 24px',
        display:'inline-block',
        borderRadius:'20px',
        lineHeight:'40px',
        fontWeight:'700',
        margin:'0 5px',
        cursor:'pointer',
      }
    }
  }
}

export default JourneysView;