import React from 'react';
import ResponsiveView from '../views/ResponsiveView';
import MainSearchBox from './MainSearchBox';
import logoKarajan from '../img/logo_karajan_light.svg';
import { withTranslation } from 'react-i18next';


class DashboardHero extends ResponsiveView {

  onViewResize() {
    this.setState({
      resize: true,
    })
  }

  onStartSearch(queryString) {
    this.props.onStartSearch(queryString)
  }

  render() {
    const { t } = this.props;

    return (
      <div style={this.styles().container}>

        {window.innerWidth >= 768 ? null : <img src={logoKarajan} alt="" />}

        <div style={this.styles().headline}>{t('dashboard.header')}</div>
        <p style={this.styles().subline}>
          {t('dashboard.teaser')}
        </p>

        <div style={this.styles().searchboxContainer}>
          <MainSearchBox
            onStartSearch={this.onStartSearch.bind(this)}
            displaySuggestions={false}
          />
        </div>
      </div>
    );
  }

  styles() {

    return {

      container: {
        padding: window.innerWidth >= 768 ? '120px 20px' : '60px 20px',
        //paddingBottom:'120px',
        position: 'relative',
        margin: '0 auto',
        maxWidth: '860px',
        textAlign: 'center',
      },
      headline: {
        fontSize: window.innerWidth >= 768 ? '80px' : '40px',
        fontWeight: '900',
        marginBottom: '40px',
      },
      subline: {
        fontSize: window.innerWidth >= 768 ? '24px' : '18px',
        lineHeight: window.innerWidth >= 768 ? '29px' : '22px',
        maxWidth: '540px',
        margin: '0 auto',
      },
      searchboxContainer: {
        margin: '40px auto',
        maxWidth: '480px',
        position: 'relative',
      }

    }

  }
}

export default withTranslation()(DashboardHero);

