export const concerts = [
  {
    id:1,
    imageUrl:'http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg',
    title:'Orchesterkonzert',
    composerIds:[1, 2],
    artistIds:[1],
    orchestraId:1,
    workIds:[1,2,3],
    date:
    {
      day:26,
      month:3,
      year:1989
    },
    locationId:1
  },
  {
    id:2,
    imageUrl:'http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg',
    title:'Orchesterkonzert',
    composerIds:[1, 2],
    artistIds:[1],
    orchestraId:1,
    workIds:[2,3,4],
    date:
    {
      day:20,
      month:3,
      year:1989
    },
    locationId:3
  },
  {
    id:3,
    imageUrl:'http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg',
    title:'Abo-Konzert',
    artistIds:[1],
    composerIds:[1],
    orchestraId:1,
    workIds:[3,4],
    date:
    {
      day:31,
      month:12,
      year:1988
    },
    locationId:4
  }
];
