import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import { getArtistsList } from '../../api/kapiClient';
import { getArtistDetails } from '../../api/kapiClient';


import { getParamValue, getFormattedLifeDates, formatConcertItemSubtitle, formatRecordingItemSubtitle, joinSingleComposerString, getMapBoundingRect, sortByDate, getFormattedDate, decodeId } from '../../utils/helpers';
import { track } from '../../utils/trackingService';

import InfoCard from './InfoCard';

import ListItemTwoLineIcon from '../../components/ListItemTwoLineIcon';
import ViewToggle from '../../components/ViewToggle';

import { withTranslation } from 'react-i18next';
import { capitalizeTitle } from '../../utils/helpers';

class InfoCardArtist extends ResponsiveView {

  constructor(props) {
    super(props, true);

    let sub = parseInt(getParamValue(this.props.location.search, 'sub'));

    this.state = {
      viewData: null,
      subViewId: sub ? sub : 0,
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props.match.params.id != nextProps.match.params.id) {
      this.props = nextProps;
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.props.setMapBoundingRect(null, null);
  }

  onDataReady(result) {
    console.log("-> onDataReady in InfoCardArtist", result, this.props);

    let data = Object.assign({}, result);

    let concertCoordinates = [];
    let concertsBoundingRect = null;

    let recordingCoordinates = [];
    let recordingsBoundingRect = null;


    if (data && data.concerts) {
      //data.concerts = sortByDate(data.concerts.slice(), 'ASC');
      data.concerts.forEach(function (concert) {
        if (concert.location) concertCoordinates.push([concert.location.longitude, concert.location.latitude]);
      })
    }

    if (concertCoordinates.length >= 2) concertsBoundingRect = getMapBoundingRect(concertCoordinates);


    if (data && data.recordings) {
      //data.recordings = sortByDate(data.recordings.slice(), 'ASC');
      data.recordings.forEach(function (recording) {
        if (recording.location) recordingCoordinates.push([recording.location.longitude, recording.location.latitude]);
      })
    }

    if (recordingCoordinates.length >= 2) recordingsBoundingRect = getMapBoundingRect(recordingCoordinates);

    this.setState({
      viewData: data,
      concertCoordinates: concertCoordinates,
      concertsBoundingRect: concertsBoundingRect,
      recordingCoordinates: recordingCoordinates,
      recordingsBoundingRect: recordingsBoundingRect,
      subViewId: !data.recordings || data.recordings.length < 1 ? 1 : 0,
    }, function () {

      track('load-page', { pageId: 'InfoCard', category: 'Künstler', id: decodeId(this.props.match.params.id), name: joinSingleComposerString(this.state.viewData) });
      this.setMapBoundingRect();
    });

  }

  generateDescriptionText() {

    let artistName = joinSingleComposerString(this.state.viewData);

    let noOfConcerts = this.state.viewData.concerts ? this.state.viewData.concerts.length : 0;
    let noOfRecordings = this.state.viewData.recordings ? this.state.viewData.recordings.length : 0;

    let firstConcert = this.state.viewData.firstConcertWithKarajan ? this.state.viewData.firstConcertWithKarajan.date : null;
    let lastConcert = this.state.viewData.lastConcertWithKarajan ? this.state.viewData.lastConcertWithKarajan.date : null;

    let firstColaboration = firstConcert;
    let lastColaboration = lastConcert;

    let firstRecording = this.state.viewData.firstRecordingWithKarajan ? this.state.viewData.firstRecordingWithKarajan.date : null;
    let lastRecording = this.state.viewData.lastRecordingWithKarajan ? this.state.viewData.lastRecordingWithKarajan.date : null;

    if (firstRecording) {
      if (!firstColaboration || firstRecording.year < firstColaboration.year) firstColaboration = firstRecording;
    }
    if (lastRecording) {
      if (!lastColaboration || lastRecording.year > lastColaboration.year) lastColaboration = lastRecording;
    }

    let description = "";

    if (firstColaboration) {
      description += artistName;
      description += " arbeitete zum ersten Mal ";
      description += firstColaboration.day ? "am " : "im ";
      description += getFormattedDate(firstColaboration) + " mit Herbert von Karajan zusammen. ";
    }

    let years = 0;

    if (firstColaboration && lastColaboration) {
      years = Math.floor(lastColaboration.year - firstColaboration.year);

      if (years > 0) {
        description += "Ihre Zusammenarbeit ging über " + years + " Jahr" + (years > 1 ? "e. " : ". ");
      }
    }


    if (firstColaboration && noOfConcerts > 0) {

      if (years === 0) {
        description += artistName + "wirkte bei " + (noOfConcerts === 1 ? "einem Konzert" : noOfConcerts + " Konzerten") + " mit";
      } else {
        description += "In dieser Zeit wirkte " + artistName + " bei " + (noOfConcerts === 1 ? "einem Konzert" : noOfConcerts + " Konzerten") + " mit";
      }

      if (noOfRecordings === 0) description += ". "
    }

    if (firstColaboration && noOfRecordings > 0) {

      if (noOfConcerts === 0) {
        if (years === 0) {
          description += artistName + "wirkte bei " + (noOfRecordings === 1 ? "einer Aufnahme" : noOfRecordings + " Aufnahmen") + " mit.";
        } else {
          description += "In dieser Zeit wirkte " + artistName + " bei " + (noOfRecordings === 1 ? "einer Aufnahme" : noOfRecordings + " Aufnahmen") + " mit.";
        }

      } else {
        description += " und sie produzierten gemeinsam " + (noOfRecordings === 1 ? "eine Aufnahme" : noOfRecordings + " Aufnahmen") + ". ";
      }
    }

    return description;
  }

  onViewResize() {
    this.setState({
      resize: true,
    })
  }

  onNavigateTo(path) {
    console.log("onNavigateTo: ", path);
    this.props.history.push(path);
  }


  onBackButton() {
    this.props.history.goBack();
  }

  onMapButton() {
    this.props.onMapButton();
  }

  onShareInfoCard() {
    let sharingData = {
      url: window.location.origin + '/#' + this.props.location.pathname,
      title: joinSingleComposerString(this.state.viewData),
      text: this.state.viewData.description,
    };

    this.props.onShareInfoCard(sharingData)
  }

  onRecordingItemSelected(id) {
    this.props.history.push('/discover/recordings/' + id);
  }

  onConcertItemSelected(id) {
    this.props.history.push('/discover/concerts/' + id);
  }

  onSubViewSelected(id) {
    this.setState({
      subViewId: id,
    }, function () {

      this.setMapBoundingRect();

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: '?sub=' + this.state.subViewId,
      });


    })
  }

  setMapBoundingRect() {

    if (this.state.subViewId === 0 && this.props.setMapBoundingRect && this.state.recordingsBoundingRect) {
      this.props.setMapBoundingRect(this.state.recordingsBoundingRect, this.state.recordingCoordinates, 'Aufnahmen', 'Künstlers');
    } else if (this.state.subViewId === 1 && this.props.setMapBoundingRect && this.state.concertsBoundingRect) {
      this.props.setMapBoundingRect(this.state.concertsBoundingRect, this.state.concertCoordinates, 'Konzerte', 'Künstlers');
    } else {
      this.props.setMapBoundingRect(null);
    }

  }

  renderRecordingListItems() {

    if (!this.state.viewData.recordings) return;

    let items = [];

    for (let i = 0; i < this.state.viewData.recordings.length; i++) {

      let pItem = this.state.viewData.recordings[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={formatRecordingItemSubtitle(pItem.composer, pItem.date)}
          onItemSelected={this.onRecordingItemSelected.bind(this)}
          lastItem={i === this.state.viewData.recordings.length - 1}
          labelType={pItem.type}
        />
      )

    }

    return items;
  }

  renderConcertsListItems() {

    if (!this.state.viewData.concerts) return;

    let items = [];

    for (let i = 0; i < this.state.viewData.concerts.length; i++) {

      let pItem = this.state.viewData.concerts[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={formatConcertItemSubtitle(pItem.composers, pItem.orchestra, pItem.date)}
          onItemSelected={this.onConcertItemSelected.bind(this)}
          lastItem={i === this.state.viewData.recordings.length - 1}
        />
      )

    }

    return items;

  }

  render() {

    const { t } = this.props;

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getArtistsList}
          resultKeyList={'persons'}
          queryFunc={getArtistDetails}
          loadMockData={false}
          resultKey={'person'}
          title={t('infocard.artist')}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          id={this.props.match.params.id}
          total={337}
          type="IC_ARTIST"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
              {
                title: joinSingleComposerString(this.state.viewData),
                subtitle: getFormattedLifeDates(this.state.viewData.birthDate, this.state.viewData.deathDate, true),
                imageUrl: this.state.viewData.imageUrl,
                link: this.props.location.pathname,
              } : null
          }
        >

          {this.state.viewData
            ?
            <div>

              <div style={this.styles().infoContainer}>

                <div style={this.styles().role}>
                  {this.state.viewData.role}
                </div>
                <div style={this.styles().name}>
                  {joinSingleComposerString(this.state.viewData)}
                </div>


                {this.state.viewData.role !== 'Ballett' && this.state.viewData.role !== 'Orchester' && this.state.viewData.role !== 'Chor'
                  ? <div style={this.styles().subTitle}>
                    {getFormattedLifeDates(this.state.viewData.birthDate, this.state.viewData.deathDate, true)}
                  </div>
                  : null
                }



                <div style={this.styles().description}>
                  <div style={this.styles().generatedDescription}>{this.state.viewData.karajanCredits}</div>
                  {this.state.viewData.description}
                </div>


              </div>

              <div style={this.styles().toggleContainer}>
                {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
                  && this.state.viewData.concerts && this.state.viewData.concerts.length > 0

                  ? <ViewToggle
                    activeView={this.state.subViewId}
                    labelLeft={t('infocard.work.recordings') + " • " + this.state.viewData.recordings.length}
                    labelRight={t('infocard.work.concerts') + " • " + this.state.viewData.concerts.length}
                    onViewSelected={this.onSubViewSelected.bind(this)}
                  />
                  : <div>
                    {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
                      ? <div style={this.styles().toggleReplaceHeader}>{t('infocard.work.recordings') + " • " + this.state.viewData.recordings.length}</div>
                      : null
                    }
                    {this.state.viewData.concerts && this.state.viewData.concerts.length > 0
                      ? <div style={this.styles().toggleReplaceHeader}>{t('infocard.work.concerts') + " • " + this.state.viewData.concerts.length}</div>
                      : null
                    }
                  </div>
                }

              </div>

              <div style={this.styles().toggleListContainer}>
                {this.state.subViewId === 0
                  ? this.renderRecordingListItems()
                  : this.renderConcertsListItems()
                }
              </div>

            </div>

            : null
          }



        </InfoCard>
      </div>
    );
  }

  styles() {

    return {
      view: {

      },
      infoContainer: {
        position: 'relative',
        width: '100%',
        borderTop: '1px solid #E6E6E6',
        padding: '20px 20px 40px 20px',
        textAlign: 'left',
        fontSize: '18px',
        color: '#191919',
      },
      role: {
        textTransform: 'uppercase',
        fontSize: '11px',
        fontWeight: '700',
        letterSpacing: '.5px',
        color: '#d9c56f',
        marginBottom: '8px',
      },
      name: {
        fontWeight: '700',
        fontSize: '20px',
        color: '#191919',
        overflow: 'hidden',
      },
      subTitle: {
        //color:'#3F3F3F',
        fontSize: '16px',
      },
      description: {
        fontSize: '16px',
        lineHeight: '21px',
        color: '#3F3F3F',
        marginTop: '15px'
      },
      generatedDescription: {
        marginBottom: '16px',
      },
      eventListContainer: {
        textAlign: 'left',
        padding: '0 10px 30px 10px',
      },
      listHeader: {
        color: '#191919',
        fontWeight: '700',
        fontSize: '14px',
        padding: '20px 10px 10px 10px',
      },
      toggleContainer: {
        padding: '0 20px 20px 20px',
      },
      toggleListContainer: {
        padding: '0 10px 40px 10px',
      },
      toggleReplaceHeader: {
        fontSize: '16px',
        fontWeight: '700',
        color: '#3F3F3F',
        textAlign: 'left',
      }

    }

  }
}

InfoCardArtist.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  setMapBoundingRect: PropTypes.func,
  setPoiHighlights: PropTypes.func,
  onShareInfoCard: PropTypes.func,
};

export default withTranslation()(InfoCardArtist);