import React from 'react';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

class ClusterListItem extends ResponsiveView {


  onViewResize(){
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for CategoryListItem");
      return;
    }

    this.props.onItemSelected(this.props.label);
  }

  render() {

    let background = this.props.isActive ? {background:'#f9f9f9'} : null;

    return (
      <div style={Object.assign({}, this.styles().container, background)} onClick={this.onItemSelected.bind(this)}>


        <div style={this.styles().label}>
          {this.props.label}
        </div>

        <div style={this.styles().amount}>
          {this.props.amount}
        </div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        background:'#fff',
        width:'100%',
        lineHeight:'60px',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        padding:'0 20px',
        cursor:'pointer',
        textAlign:'left',
        overflow:'hidden',
        fontSize:'18px',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      label: {
        fontWeight:'900',
        color:'#3F3F3F',
        whiteSpace:'nowrap',
        overflow:'hidden',
        float:'left',
      },
      amount:{
        color:'#4A4A4A',
        float:'right'
      },

    }

  }
}

export default Radium(ClusterListItem);