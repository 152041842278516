import React from 'react';
import PropTypes from 'prop-types';

import { fullTextQuery } from '../api/kapiClient';

import ResponsiveView from '../views/ResponsiveView';

import SuggestionItem from './SuggestionItem';

/*
const SUGGESTION_DATA = [
  {
    string:'Peter Iljitsch Tschaikowsky',
    type:'KOMPONIST',
  },
  {
    string:'Berliner Philharmoniker',
    type:'ORCHESTER',
  },
  {
    string:'Anne-Sophie Mutter',
    type:'KÜNSTLER',
  },
  {
    string:'Claude Debussy',
    type:'KOMPONIST',
  },
  {
    string:'Smetana-Saal',
    type:'ORT',
  },

];
*/

class SuggestionsList extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      viewData:null,
      selectedSuggestion:'',
    };

    this.hideList = this.hideList.bind(this);

    window.addEventListener('click', this.hideList)
  }

  componentWillUnmount(){
    window.removeEventListener('click', this.hideList)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props.query !== nextProps.query) {

      this.props = nextProps;

      if (nextProps.query.length > 1) {

        if (this.props.query.length > 1) {
          this.loadSuggestions();
        } else {
          this.hideList();
        }

      } else {
        this.props = nextProps;
        this.hideList();
      }
    }
  }

  loadSuggestions(){

    fullTextQuery(this.props.query).then(function (data) {
      console.log("received data in SearchView: ", data);
      if(data.length > 0) {
        this.onDataReady(data);
      } else {
        this.hideList();
      }
    }.bind(this));

  }

  onDataReady(data){
    this.setState({
      viewData:data,
    })
  }

  hideList(){
    //console.log("hideList");
    this.setState({
      viewData:null,
    })
  }

  onItemSelected(suggestion){
    console.log("onItemSelected in SuggestionsList ", suggestion);

    this.setState({
      selectedSuggestion:suggestion
    },function(){
      this.hideList();
      this.props.onSuggestionSelected(suggestion);
    });
  }

  renderSuggestionItems(){

    if(!this.state.viewData) return;

    let items = [];

    for(let i=0; i<this.state.viewData.length;i++){
      items.push(
        <SuggestionItem
          id={i}
          key={i}
          string={this.state.viewData[i].string}
          type={this.state.viewData[i].type}
          onItemSelected={this.onItemSelected.bind(this)}
        />
      )
    }

    return items;

  }

  render() {
    return (
      <div style={this.styles().container}>
        {this.renderSuggestionItems()}
      </div>
    );
  }

  styles(){

    return {

      container:{
        textAlign:'left',
        position:'absolute',
        background:'#fff',
        width:'100%',
        maxWidth:'480px',
        marginTop:'3px',
        zIndex:'10',
        boxShadow:'0 1px 15px rgba(0,0,0,.8)',
      },

    }

  }
}

SuggestionsList.propTypes = {
  endpoint:PropTypes.string,
  query:PropTypes.string,
};

export default SuggestionsList;