import React from 'react';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import { setCookiesConsent, getCookiesConsent } from '../utils/localStorageService';
import { withTranslation } from 'react-i18next';


class CookiesNotification extends ResponsiveView {


  UNSAFE_componentWillMount() {
    this.state = {
      displayNotification: getCookiesConsent() === null,
    }
  }

  onConfirmCookies() {

    this.setState({
      displayNotification: false,
    });

    setCookiesConsent();

    console.log(`TRACKER enabled!!`);

    var _paq = window._paq = window._paq || [];
    // user has given consent to process their data
    _paq.push(['setConsentGiven']);

    // OR user has given consent to store and use cookies
    _paq.push(['setCookieConsentGiven']);


  }

  render() {
    const { t } = this.props;

    return (
      <div class="cookieConsent">
        {this.state.displayNotification
          ? <div style={this.styles().cookieContainer}>
            <div style={this.styles().cookieContent}>
              <div style={this.styles().headline}>{t('cookieConsent.headline')}</div>
              {t('cookieConsent.text')}<br />
              <div style={this.styles().confirmButtonContainer}>
                <div style={this.styles().confirmButton} onClick={this.onConfirmCookies.bind(this)}>OK</div>
              </div>

            </div>
          </div>
          : null
        }
      </div>
    );
  }

  styles() {

    return {
      cookieContainer: {
        width: '100%',
        background: '#fff',
        position: 'fixed',
        zIndex: '4',
        borderTop: '1px solid #aaa',
        bottom: '0',
        padding: window.innerWidth > 768 ? '20px 15px 20px 75px' : '20px',
      },
      cookieContent: {
        maxWidth: '720px',
        fontSize: '14px',
        lineHeight: '20px',
        margin: '0 auto',
        textAlign: 'left',
      },
      headline: {
        fontSize: '16px',
        fontWeight: '700',
        marginBottom: '8px',
      },
      confirmButtonContainer: {
        marginTop: '8px',
      },
      confirmButton: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#1c6ecc',
        cursor: 'pointer',
        display: 'inline-block',
        ':hover': {
          opacity: '.7',
        }

      }
    }
  }
}

export default withTranslation()(Radium(CookiesNotification));


