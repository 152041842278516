import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from './ResponsiveView';
import {track} from '../utils/trackingService';

import LoadingIndicator from '../components/LoadingIndicator';

class BlogView extends ResponsiveView {

  constructor(props) {
    super(props);

    this.state = {
      // baseUrl:'https://krjnblog.centaurus.uberspace.de/',
      baseUrl:'https://blog.karajan.community/',
      // blogUrl:'https://krjnblog.centaurus.uberspace.de/',
      blogUrl:'https://blog.karajan.community/',
      isLoading:true,
    }
  }

  UNSAFE_componentWillMount(){
    this.setBlogUrl();
  }

  componentDidMount(){
    track('load-page', {pageId:'Blog'});
  }

  componentDidUpdate(){
    //this.setBlogUrl();
  }

  setBlogUrl(){
    console.log("setBlogUrl: ", this.props.match.params);
    if(this.props.match && this.props.match.params && this.props.match.params.articleId){
      this.setState({
        blogUrl:this.state.baseUrl+'/'+this.props.match.params.articleId,
      })
    }
  }

  onBlogLoaded(){
    this.setState({
      isLoading:false,
    })
  }

  render(){
    console.log("Render iframe with url: ", this.state.blogUrl);
    return (
      <div style={this.styles().view}>
        {this.state.isLoading
          ? <div style={this.styles().loadingIndicatorContainer}><LoadingIndicator/></div>
          : null
        }

        <iframe src={this.state.blogUrl} height='100vh' width='100vw' scrolling='yes' frameBorder='0' target="_parent" style={this.styles().frame} onLoad={this.onBlogLoaded.bind(this)}></iframe>
      </div>
    )
  }

  styles(){
    return {
      view:{
        background:'#3A3A3A',
        minHeight:'100vh',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
        color:'#fff',
      },
      loadingIndicatorContainer:{
        position:'fixed',
        width:window.innerWidth > 768 ? 'calc(100vw - 60px)' : '100vw',
      },
      frame:{
        height:'100vh',
        width:window.innerWidth > 768 ? 'calc(100vw - 60px)' : '100vw',
      }
    }
  }

}


BlogView.propTypes = {

};

export default BlogView;
