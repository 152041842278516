// API CACHE
let cacheExpiryInMillis = 60*60*1000;
let runtimeCache = {};

export const storeApiResultInCache = function(endpoint, data){

  console.log("STORE DATA IN CACHE: ", endpoint, data)
  let timestamp = new Date().getTime();

  runtimeCache[endpoint] =  data;
  runtimeCache[endpoint+ '_TS'] = timestamp;

};

export const isApiCacheExpired = function(endpoint){

  let timestamp = getApiResultFromCache(endpoint+'_TS');
  let now = new Date().getTime();

  let diff = now-timestamp;

  if(!isNaN(diff) && diff < cacheExpiryInMillis) return false;

  return true;
};

export const getApiResultFromCache = function(endpoint){

  let value = runtimeCache[endpoint];
  return value;

};

export const logRuntimeCache = function(){
  console.log('*** RUNTIME CACHE ***');
  console.log(runtimeCache);
  console.log('*********************');
};
