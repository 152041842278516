import React, {Component} from 'react';
import PropTypes from 'prop-types';

class RecordingTypeLabel extends Component {

  render() {

    return (
      <div style={this.styles().container}>
        {this.props.type}
      </div>
    );
  }

  styles(){

    let background;
    let color;

    if(this.props.lightTheme){
      background = this.props.type === "STUDIO" || this.props.type === "AUDIO" ? '#D9C56F' : '#999999';
      color = '#fff';
    } else {
      background = this.props.type === "STUDIO" || this.props.type === "AUDIO" ? '#D9C56F' : '#E6E6E6';
      color = '#131313';
    }

    return {

      container:{
        width:'42px',
        lineHeight:'14px',
        background:background,
        color:color,
        textAlign:'center',
        borderRadius:'1px',
        fontSize:'8px',
        fontWeight:'bold',
      }
    }

  }
}

RecordingTypeLabel.propTypes = {
  type:PropTypes.string.isRequired,
  lightTheme:PropTypes.bool,
}


export default RecordingTypeLabel;