import React from 'react';
import ResponsiveView from '../views/ResponsiveView';

import Radium from 'radium';

import icnAlphabeticIdle from '../img/icn_sort_alphabetic.svg';
import icnAlphabeticActive from '../img/icn_sort_alphabetic_active.svg';
import icnNumericIdle from '../img/icn_sort_numeric.svg';
import icnNumericActive from '../img/icn_sort_numeric_active.svg';

class ListSortToggle extends ResponsiveView {

  componentDidMount(){
    this.state = {
      isActive:false,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps){

    //console.log("ListSortToggle -> componentWillReceiveProps: ", nextProps);

    if(nextProps.isActive === this.props.isActive)

      this.setState({
        isActive:nextProps.isActive,
      });

  }

  onViewResize(){
    return true;
  }

  onButtonClick(){

    let newButtonState = !this.props.isActive;

    console.log("ListSortToggle -> newButtonState: ", newButtonState);

    if(this.props.onButtonClick) this.props.onButtonClick(newButtonState);
  }

  render() {

    let icnLeft = this.props.isActive ? icnAlphabeticIdle : icnAlphabeticActive;
    let icnRight = !this.props.isActive ? icnNumericIdle : icnNumericActive;

    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>

        <div style={this.styles().iconContainerLeft}>
          <img src={icnLeft} alt="" style={this.styles().icon}/>
        </div>
        <div style={this.styles().iconContainerRight}>
          <img src={icnRight} alt="" style={this.styles().icon}/>
        </div>

      </div>
    );
  }

  styles(){

    return {

      container:{
        textAlign:'center',
        borderRadius:'3px',
        display:'block',
        cursor:'pointer',
        overflow:'hidden',
        border:'1px solid #E6E6E6',
        ':hover':{
          opacity:.8,
        }
      },
      iconContainerLeft:{
        float:'left',
        padding:'4px 5px 6px 5px',
        borderRight:'1px solid #E6E6E6',
      },
      iconContainerRight:{
        float:'left',
        padding:'4px 5px 6px 5px',
      },
      icon:{
        display:'block',
      }

    }

  }
}

export default Radium(ListSortToggle);
