import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from '../views/ResponsiveView';

import Radium from 'radium';

import icnCloseLight from '../img/icn_close_light.svg';

class WorkTypeTag extends ResponsiveView {

  componentDidMount(){
    this.state = {
      isActive:false,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps){

    if(nextProps.isActive === this.props.isActive)

      this.setState({
        isActive:nextProps.isActive,
      });

  }

  onViewResize(){
    return true;
  }

  onButtonClick(e){

    e.stopPropagation();

    let newButtonState = !this.props.isActive;

    console.log("WorkTypeTag clicked");

    if(this.props.onButtonClick) this.props.onButtonClick(newButtonState);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>
        {this.props.isActive ? <img style={this.styles().icnCloseLight} src={icnCloseLight} alt=""/> : null}
        {this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        textAlign:'center',
        background:this.props.background ? this.props.background : null,
        border:this.props.borderColor ? '1px solid '+this.props.borderColor : null,
        color:this.props.borderColor ? this.props.borderColor : '#fff',
        fontSize:'11px',
        fontWeight:'900',
        lineHeight:'32px',
        borderRadius:'3px',
        padding:this.props.isActive ? '0 16px 0 28px' : '0 16px',
        display:'inline-block',
        cursor:'pointer',
        ':hover':{
          opacity:'.8'
        }
      },
      icnCloseLight:{
        position:'absolute',
        left:'4px',
        top:'4px'
      }

    }

  }
}

WorkTypeTag.propTypes = {
  label:PropTypes.string.isRequired,
  background:PropTypes.string,
  borderColor:PropTypes.string,
  isActive:PropTypes.bool
}

export default Radium(WorkTypeTag);