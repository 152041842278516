import React from 'react';

import { track } from '../utils/trackingService';

import ResponsiveView from './ResponsiveView';

class JourneysView extends ResponsiveView {

  componentDidMount() {
    track('load-page', { pageId: 'Imprint' });
  }

  render() {

    return (
      <div style={this.styles().view}>

        <div style={this.styles().container}>
          <div style={this.styles().pageTitle}>Imprint</div>

          <p>
            Herbert von Karajan Centrum Ges.m.b.H. and Herbert von Karajan Centrum Music Productions Ges.m.b.H.
          </p>



          <p style={this.styles().attributeText}>
            Imbergstrasse 6<br />
            A-5020 Salzburg
          </p>

          <p style={this.styles().attributeText}>
            Tel. +43 (0) 662/84 11 11<br />
            Fax +43 (0) 662/84 11 11 - 14<br />
            E-Mail <a href="mailto:info@karajan.org">info@karajan.org</a><br />
            Web <a href="http://www.karajan-institut.org" target="_blank" rel="noopener noreferrer">www.karajan-institut.org</a><br />
          </p>

          <p style={this.styles().attributeText}>
            Herbert von Karajan Centrum Ges.m.b.H.:<br />
            <br />
            FB-Nr. 146137h<br />
            DVR-Nr. 0877140<br />
          </p>

          <p style={this.styles().attributeText}>
            Herbert von Karajan Centrum Music Productions Ges.m.b.H.:<br />
            <br />
            UID-Nr. ATU 43916107<br />
            FB-Nr. 165988a<br />
            DVR-Nr. 0953873<br />
          </p>

          <p style={this.styles().attributeText}>
            CEO: Matthias Röder<br />
            CFO: Henry Ladewig
          </p>

          <div style={this.styles().headline2}>Responsible for the content:</div>

          <p>
            Eliette und Herbert von Karajan Institut
          </p>


          <div style={this.styles().headline2}>Registered trademark:</div>

          <p>
          The name HERBERT VON KARAJAN/KARAJAN® is a registered trademark and trademark of the Karajan estate.
          </p>

          


          <div style={this.styles().headline2}>Copyrights</div>
          <p>
             <a href="/copyright-holders.html" target="_blank" rel="noopener noreferrer">List of all copyright holders</a> for the images on this website.
          </p>
        </div>
      </div>
    );
  }

  styles() {

    return {
      view: {
        paddingLeft: window.innerWidth > 768 ? '60px' : '0px',
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        lineHeight: '21px',
      },
      container: {
        maxWidth: '860px',
        margin: '0 auto',
        textAlign: 'left',
        padding: '0 20px 60px 20px',
      },
      pageTitle: {
        fontSize: '28px',
        fontWeight: '900',
        marginTop: '40px',
        marginBottom: '40px',
        textAlign: 'center',
      },
      headline1: {
        fontSize: '20px',
        fontWeight: '700',
        marginTop: '30px',
        //marginBottom:'10px',
      },
      headline2: {
        fontWeight: '700',
        marginTop: '30px',
        //marginBottom:'10px',
      },
      attributeText: {
        fontSize: '14px',
        fontWeight: '500',
      }

    }
  }
}

export default JourneysView;