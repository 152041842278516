import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';
import SmallButtonRound from './SmallButtonRound';

class FavouriteItem extends ResponsiveView {

  constructor(props){
    super(props);
  }

  UNSAFE_componentWillMount(){
    this.state = {
      showContextMenu:false,
      isMobile: window.innerWidth <= 480,
    };

    this.checkIfMobile();

  }

  componentWillUnmount(){
    super.componentWillUnmount();
    window.removeEventListener('click', this.onClickOutside, false);
  }

  onViewResize(){
    this.checkIfMobile();
  }

  checkIfMobile(){

    let isMobile = window.innerWidth <= 480;

    if(isMobile){

      window.removeEventListener('click', this.onClickOutside, false);

      this.clickedOnItem = false;

      this.onClickOutside = this.onClickOutside.bind(this);
      window.addEventListener('click', this.onClickOutside, false);
    }

    this.setState({
      isMobile: isMobile,
    })

  }

  onToggleContextMenu(){
    this.setState({
      showContextMenu:!this.state.showContextMenu,
    })
  }

  onClickFavItem(e){
    this.clickedOnItem = true;
    this.onToggleContextMenu();
  }

  onClickOutside(e){

    if(this.state.showContextMenu && !this.clickedOnItem){
      this.onToggleContextMenu();
    }

    this.clickedOnItem = false;
  }

  onItemSelected(){
    if(!this.props.onItemSelected) { console.warn("'onItemSelected' is not defined in FavouriteItem"); return;};
    this.props.onItemSelected(this.props.link);
  }

  onRemoveItem(e){
    e.preventDefault();
    e.stopPropagation();

    if(!this.props.onRemoveItem) { console.warn("'onRemoveItem' is not defined in FavouriteItem"); return;};
    this.props.onRemoveItem(this.props.link);
  }

  render() {

    return (
      <div style={this.styles().wrapper} onClick={this.onItemSelected.bind(this)}>
        <div style={this.styles().container} key="container">
          <div style={this.styles().containerContent}>
            {this.props.imageUrl && this.props.imageUrl.length > 0
              ? <img src={this.props.imageUrl} alt="" style={this.styles().image}/>
              : null
            }
            <div style={this.styles().content}>
              <div style={this.styles().title}>{this.props.title}</div>

              {this.props.subtitle ? <div>{this.props.subtitle}</div> : null}

              {this.props.description
                ? <div style={this.styles().description}>
                    {this.props.description}
                  </div>
                : null
              }
            </div>
          </div>

          <div style={this.styles().contextMenuButtons}>
            <div style={this.styles().deleteBtn} onClick={this.onRemoveItem.bind(this)}>
              LÖSCHEN
            </div>
          </div>



        </div>


      </div>
    );
  }

  styles(){

    return {

      wrapper:{
        padding:'6px',
        breakInside: 'avoid',
        WebkitColumnBreakInside: 'avoid',
        MozColumnBreakInside: 'avoid',
        position:'relative',
      },
      container:{
        background:'#fff',
        color:'#191919',
        textAlign:'left',
        borderRadius:'2px',
        overflow:'hidden',
        cursor:'pointer',
        ':hover':{
          background:'#e9e9e9',
        }
      },
      containerContent:{
        //opacity:this.state.showContextMenu ? '.2' : null,
      },
      content:{
        padding:'10px',
        fontSize:'13px',
      },
      image:{
        maxWidth:'100%',
        maxHeight:'500px',
        display:'block',
      },
      title:{
        fontWeight:'700',
        fontSize:'16px',
      },
      description:{
        marginTop:'8px',
      },
      contextMenuButtons:{
        //extAlign:'center',
        overflow:'hidden',
        padding:'2px 10px 10px 10px',
      },
      deleteBtn:{
        cusror:'pointer',
        fontWeight:'bold',
        fontSize:'11px',
        letterSpacing:'.5px',
        color:'#bbb',
        border:'1px solid #ddd',
        display:'inline-block',
        padding:'2px 6px',
        borderRadius:'12px',
        ':hover':{
          border:'1px solid #D0011B',
          color:'#D0011B',
        }
      }

    }

  }
}

FavouriteItem.propTypes = {
  title:PropTypes.string.isRequired,
  subtitle:PropTypes.string,
  imageUrl:PropTypes.string,
  onItemSelected:PropTypes.func.isRequired,
  link:PropTypes.string,
};

export default Radium(FavouriteItem);