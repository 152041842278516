export const recordings = [
  {
    id:1,
    title:"Klavierkonzert Nr. 1 b-moll op. 23",
    imageUrl:"http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg",
    composerId:1,
    orchestraId:1,
    artistIds:[1],
    program:[
      {
        parts:[
          {
            "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
            "duration":1357,
            "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
            "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
          },
          {
            "title": "1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
            "duration": 256,
            "audioUrl": "https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
            "audioPreviewUrl": "https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
          }
        ]
      },
      {
        parts:[
          {
            "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
            "duration":703,
            "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
            "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
          },
          {
            "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
            "duration":787,
            "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
            "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
          },
        ]
      }
    ],
    productIds:[1,2,3,4],
    type:"RADIO",
    date:
    {
      day:26,
      month:3,
      year:1989
    },
    locationId:3
  },
  {
    id:2,
    title:"Klavierkonzert Nr. 1 b-moll op. 23",
    imageUrl:"http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg",
    composerId:1,
    orchestraId:1,
    artistIds:[1],
    playlistId:1,
    productIds:[1,2,3,4],
    type:"STUDIO",
    date:
    {
      day:31,
      month:12,
      year:1988
    },
    locationId:4
  },
  {
    id:3,
    title:"Klavierkonzert Nr. 1 b-moll op. 23",
    imageUrl:"http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg",
    composerId:1,
    orchestraId:1,
    artistIds:[1],
    playlistId:1,
    productIds:[1,2,3,4],
    type:"FILM",
    date:
      {
        day:31,
        month:12,
        year:1988
      },
    locationId:2
  },
  {
    id:4,
    title:"Violinkonzert D-Dur op. 35",
    imageUrl:"http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg",
    composerId:1,
    orchestraId:1,
    artistIds:[1],
    playlistId:1,
    productIds:[1,2,3,4],
    type:"RADIO",
    date:
      {
        day:15,
        month:8,
        year:1988
      },
    locationId:1
  },
  {
    id:5,
    title:"Symphonie Nr. 6 h-moll op. 74 “Pathétique",
    imageUrl:"http://static.universal-music.de/asset_new/278492/881/view/Berliner-Philharmoniker-mit-Herbert-von-Karajan-c-Siegfried-Lauterwasser---DG.jpg",
    composerId:1,
    orchestraId:1,
    artistIds:[1],
    playlistId:1,
    productIds:[1,2],
    type:"RADIO",
    date:
      {
        day:2,
        month:5,
        year:1988
      },
    locationId:3
  }];