import React, {Component} from 'react';
import PropTypes from 'prop-types';

import loader from '../img/loader_tail_spin.svg';


class LoadingIndicator extends Component {

  render() {
    return (
      <div style={this.styles().container}>
        <img src={loader} alt="" style={this.styles().loader}/>
        {this.props.label
          ? <div style={this.styles().label}>{this.props.label}</div>
          : null
        }

      </div>
    );
  }

  styles(){

    return({
      container:{
        padding:'20px',
        textAlign:'center',
      },
      loader:{
        display:'block',
        margin:'0 auto',
        height:this.props.height ? this.props.height : '32px',
      },
      label:{
        paddingTop:'16px',
        color:this.props.labelColor ? this.props.labelColor : null,
        //paddingBottom:'20px',
        lineHeight:'14px',
        fontSize:'14px',
      },
    });
  }
}

LoadingIndicator.propTypes = {
  height:PropTypes.number,
  label:PropTypes.string,
  labelColor:PropTypes.string,
};

export default LoadingIndicator;
