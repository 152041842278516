import React from 'react';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import WorkTypeTag from './WorkTypeTag';

class ListItemWork extends ResponsiveView {


  onViewResize(){
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for ListItemInfoCardSimple");
      return;
    }

    this.props.onItemSelected(this.props.id);
  }

  onTagSelected(){
    this.props.onTagSelected(this.props.type);
  }

  render() {

    let background = this.props.isActive ? {background:'#f9f9f9'} : null;

    return (
      <div style={Object.assign({}, this.styles().container, background)} onClick={this.onItemSelected.bind(this)}>


        <div style={this.styles().title}>
          {this.props.title}
        </div>

        <div style={this.styles().subtitle}>
          {'Konzerte: '+this.props.concerts+" • Aufnahmen: "+this.props.recordings}
        </div>

        <WorkTypeTag
          isActive={false}
          label={this.props.type.toUpperCase()}
          background="#1C6ECC"
          onButtonClick={this.onTagSelected.bind(this)}
        />

      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        padding:'10px',
        cursor:'pointer',
        textAlign:'left',
        overflow:'hidden',
        fontSize:'18px',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      title: {
        fontWeight:'700',
        fontSize:'16px',
        color:'#3F3F3F',
        whiteSpace:'nowrap',
        overflow:'hidden',
      },
      subtitle:{
        color:'#999999',
        fontSize:'13px',
        lineHeight:'24px',
      },

    }

  }
}

export default Radium(ListItemWork);