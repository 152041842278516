import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import { setFavourite, clearFavourite, isFavourite, addDiscoveredItem } from '../../utils/localStorageService';
import { decodeId } from '../../utils/helpers';
import { track } from '../../utils/trackingService';

import InfoCardHeader from '../../components/InfoCardHeader';
import LoadingIndicator from '../../components/LoadingIndicator';
import CircleButtonToggle from '../../components/buttons/CircleButtonToggle';
import ButtonToggleIconAndLabel from '../../components/buttons/ButtonToggleIconAndLabel';

import icnFavIdle from '../../img/icn_ui_favourites_inactive.svg';
import icnFavActive from '../../img/icn_ui_favourites_active.svg';

import icnSharing from '../../img/icn_share.svg';

import icnPlay from '../../img/icn_play.svg';
import icnPause from '../../img/icn_pause.svg';

import imgPlaceholder from '../../img/placeholder_noimage.png';

import { withTranslation } from 'react-i18next';

class InfoCard extends ResponsiveView {

    constructor(props) {
        super(props, true);

        console.log("this.props: ", this.props);

        this.state = {
            viewData: null,
            isFavourite: isFavourite(this.props.pathname),
            subViewId: 0,
            errorMessage: null,
            imageIsLoading: true,
            listData: null,
            isLoading: true,
        };

        this.onDataReady = this.onDataReady.bind(this);
    }

    componentDidMount() {

        console.log("-> componentDidMount InfoCard with id: ", this.props.id);
        //console.log("DECODE: ", atob('UGVyc29uOjIzNjk='));

        this.loadCategoryList = this.loadCategoryList.bind(this);
        this.loadViewData = this.loadViewData.bind(this);

        this.loadCategoryList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.id != nextProps.id) {
            this.props = nextProps;

            this.updateInfoCardIndex();
            this.loadViewData();
        }
    }

    loadCategoryList() {

        this.props.queryFuncList(false).then(function (result) {

            if (result && result.data && result.data[this.props.resultKeyList]) {

                // remove 'node' from data object
                let data = result.data[this.props.resultKeyList].edges.map(function (el) { return el.node });

                if (data) {

                    this.setState({
                        listData: data,
                    }, function () {
                        this.updateInfoCardIndex();
                        this.loadViewData();
                    })
                }

            }
        }.bind(this));

    }

    updateInfoCardIndex() {

        if (this.state.listData) {
            let index = this.getIndexForId(this.state.listData, this.props.id);

            console.log("Match id: ", this.props.id, 'found at index: ', index);

            this.setState({
                navIndex: index,
            })
        }

    }

    getIndexForId(data, id) {
        let index = -1;

        for (let i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                console.log("found index: " + i);
                return i;
            }
        }
        return index;
    }

    getIdForIndex(data, index) {
        console.log("getIdForIndex");
        console.log(data);
        console.log(index);
        let id = data[index].id;
        return id;
    }

    loadViewData() {

        const {t} = this.props;

        this.setState({
            isLoading: true,
        });

        this.props.queryFunc(decodeId(this.props.id), this.props.loadMockData).then(function (result) {
            console.log("Call for " + this.props.resultKey + " returned result: ", result);

            if (result && result.data && result.data[this.props.resultKey]) {
                console.log("Call for " + this.props.resultKey + " returned: ", result.data[this.props.resultKey]);
                this.onDataReady(result.data[this.props.resultKey]);
                this.setState({ errorMessage: null });
            } else if (result && result.errors) {
                console.error('There was an error for query ' + this.props.resultKey + ':', result.errors[0].message);
                this.setState({ isLoading: false, errorMessage: t('infocard.error') });
            } else {
                console.log(this.props.resultKey + ' did not return any results for id: ', this.props.id, result)
                this.setState({ isLoading: false, errorMessage: t('infocard.notFound') });
            }



        }.bind(this), function (error) {
            console.log("There was a problem querying " + this.props.resultKey + ": ", error);
        }.bind(this));
    }

    onDataReady(data) {
        console.log("-> onDataReady ", data, this.props);

        if (!data.imageUrl) {
            data = Object.assign({}, data, { imageUrl: (this.props.placeholderImage ? this.props.placeholderImage : imgPlaceholder) });
        }

        this.setState({
            viewData: data,
            isFavourite: isFavourite(this.props.pathname),
            isLoading: false,
            //imageIsLoading:data.imageUrl === null ? false : true,
        });

        if (data) addDiscoveredItem(this.props.resultKey, data.id);

        if (this.props.onDataReady) this.props.onDataReady(data);

    }

    onImageLoaded() {
        this.setState({
            imageIsLoading: false,
        })
    }

    onImageError() {

        let data = Object.assign({}, this.state.viewData, { imageUrl: imgPlaceholder });

        this.setState({
            viewData: data,
            imageIsLoading: false,
        })
    }

    onViewResize() {
        this.setState({
            resize: true,
        })
    }

    onBackButton() {
        this.props.onBackButton();
    }

    onMapButton() {
        this.props.onMapButton();
    }

    onPrevButton() {

        let index = this.state.navIndex;
        let total = this.state.listData ? this.state.listData.length - 1 : 0;

        if (index > 0) {
            index--;
        } else {
            index = total;
        }

        let id = this.getIdForIndex(this.state.listData, index);

        let pathWithoutId = this.props.pathname.substr(0, this.props.pathname.lastIndexOf('/') + 1);

        let path = pathWithoutId + id;
        this.props.onNavigateTo(path);

        this.setState({
            navIndex: index,
        })
    }

    onNextButton() {

        let index = this.state.navIndex;
        let total = this.state.listData ? this.state.listData.length - 1 : 0;

        if (index < total) {
            index++;
        } else {
            index = 0;
        }

        let id = this.getIdForIndex(this.state.listData, index);

        let pathWithoutId = this.props.pathname.substr(0, this.props.pathname.lastIndexOf('/') + 1);

        let path = pathWithoutId + id;
        this.props.onNavigateTo(path);

        this.setState({
            navIndex: index,
        })
    }

    onFavouriteToggle() {

        this.setState({
            isFavourite: !this.state.isFavourite,
        }, function () {

            console.log("this.props.favouriteStorageData: ", this.props.favouriteStorageData);
            if (this.state.isFavourite) {
                track('favourite-add', { type: this.props.type });
                setFavourite(this.props.pathname, { type: this.props.type, data: this.props.favouriteStorageData });
            } else {
                clearFavourite(this.props.pathname);
            }
        })
    }

    onShareInfoCard() {
        if (this.props.onShareInfoCard) this.props.onShareInfoCard();
    }

    isPlaybackButtonRequired() {

        let parts = null;

        if (this.state.viewData.parts && this.state.viewData.parts.length > 0) {
            parts = this.state.viewData.parts;
        } else if (this.state.viewData.musicWork && this.state.viewData.musicWork.parts.length > 0) {
            parts = this.state.viewData.musicWork.parts;
        }

        if (parts && this.props.onTogglePlayback) {

            let hasAudioTracks = parts.filter(function (el) {
                return el.referenceTrack.audioUrl && el.referenceTrack.audioUrl.length > 0 && el.referenceTrack.duration && el.referenceTrack.duration > 0;
            });

            return (hasAudioTracks && hasAudioTracks.length > 0);
        }

        return false;
    }

    render() {

        const {t} = this.props;

        return (
            <div style={this.styles().view}>

                {!this.state.viewData && !this.state.errorMessage
                    ?
                    <LoadingIndicator
                        label={t('infocard.resultsLoading')}
                    />
                    : null
                }

                {this.state.errorMessage
                    ? <div style={this.styles().errorMessageContainer}>{this.state.errorMessage}</div>
                    : null
                }

                {this.state.viewData && !this.state.errorMessage
                    ?
                    <div style={this.styles().container}>

                        <InfoCardHeader
                            title={this.props.title}
                            id={this.state.navIndex + 1}
                            total={this.state.listData ? this.state.listData.length : 0}
                            onBackButton={this.onBackButton.bind(this)}
                            onMapButton={this.onMapButton.bind(this)}
                            onPrevButton={this.onPrevButton.bind(this)}
                            onNextButton={this.onNextButton.bind(this)}
                        />


                        {this.state.isLoading
                            ?
                            <LoadingIndicator
                                label={t('infocard.resultsLoading')}
                            />
                            :

                            <div style={this.styles().contentContainer}>

                                <div style={this.styles().imageContainer}>

                                    <img style={this.styles().mainImage} src={(this.state.viewData.imageUrl ? this.state.viewData.imageUrl : this.props.placeholderImage) + "?targetSize=400"} alt="" onLoad={this.onImageLoaded.bind(this)} onError={this.onImageError.bind(this)} />

                                    {this.state.imageIsLoading
                                        ? <div style={this.styles().loadingIndicatorContainer}>
                                            <LoadingIndicator />
                                        </div>
                                        : null
                                    }

                                    <div style={this.styles().favButton}>
                                        <CircleButtonToggle
                                            isActive={this.state.isFavourite}
                                            idleBackground={'#fff'}
                                            activeBackground={'#fff'}
                                            icon={icnFavIdle}
                                            iconActive={icnFavActive}
                                            onButtonClick={this.onFavouriteToggle.bind(this)}
                                            width={'48px'}
                                            height={'48px'}
                                        />
                                    </div>

                                    <div style={this.styles().shareButton}>
                                        <CircleButtonToggle
                                            isActive={false}
                                            idleBackground={'#fff'}
                                            activeBackground={'#fff'}
                                            icon={icnSharing}
                                            iconActive={icnSharing}
                                            onButtonClick={this.onShareInfoCard.bind(this)}
                                            width={'48px'}
                                            height={'48px'}
                                        />
                                    </div>

                                    {this.isPlaybackButtonRequired()
                                        ? <div style={this.styles().playbackAllBtn}>
                                            <ButtonToggleIconAndLabel
                                                label={'ABSPIELEN'}
                                                labelActive={'STOPPEN'}
                                                icon={icnPlay}
                                                iconActive={icnPause}
                                                isActive={this.props.isPlaybackActive}
                                                onButtonClick={this.props.onTogglePlayback ? () => { this.props.onTogglePlayback() } : null}
                                            />
                                        </div>
                                        : null
                                    }

                                </div>

                                <div>
                                    {this.props.children}
                                </div>

                            </div>
                        }


                    </div>
                    : null
                }
            </div>
        );
    }

    styles() {

        return {
            container: {
                boxSizing: 'border-box',
                background: '#fff',
                color: '#131313',
                overflow: 'hidden',
                width: '100%',
            },
            contentContainer: {
                width: '100%',
                marginTop: '-1px',
            },
            errorMessageContainer: {
                marginTop: '30px',
            },
            imageContainer: {
                position: 'relative',
                maxHeight: '600px',
                overflow: 'hidden',
            },
            mainImage: {
                width: '100%',
                display: 'block',
                minHeight: this.state.imageIsLoading ? '258px' : null,
            },
            loadingIndicatorContainer: {
                position: 'absolute',
                top: '80px',
                width: '100%',
            },
            favButton: {
                position: 'absolute',
                right: '20px',
                bottom: '20px',
            },
            shareButton: {
                position: 'absolute',
                right: '20px',
                bottom: '80px',
            },
            playbackAllBtn: {
                position: 'absolute',
                left: '20px',
                bottom: '20px',
            },
            infoContainer: {
                position: 'relative',
                width: '100%',
                borderTop: '1px solid #E6E6E6',
                padding: '20px 20px 30px 20px',
                textAlign: 'left',
                fontSize: '18px',
                color: '#191919',
            },
        }

    }
}

InfoCard.propTypes = {
    queryFuncList: PropTypes.func.isRequired,
    resultKeyList: PropTypes.string.isRequired,
    queryFunc: PropTypes.func.isRequired,
    resultKey: PropTypes.string.isRequired,
    title: PropTypes.string,
    loadMockData: PropTypes.bool,
    onMapButton: PropTypes.func.isRequired,
    onDataReady: PropTypes.func,
    pathname: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    onNavigateTo: PropTypes.func,
    onBackButton: PropTypes.func,
    favouriteStorageData: PropTypes.object,
    isPlaybackActive: PropTypes.bool,
    onTogglePlayback: PropTypes.func,
    placeholderImage: PropTypes.string,
    onShareInfoCard: PropTypes.func,
};

export default withTranslation()(InfoCard);