import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import ResponsiveView from '../views/ResponsiveView';
import SmallButtonRound from './SmallButtonRound';

class CollectionPreviewItem extends ResponsiveView {

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onCollectionSelected(id){
    this.props.history.push(this.props.path);
  }

  render() {

    return (
      <div style={this.styles().container}>

        <div style={this.styles().collectionImage}>
          <img src={this.props.imageUrl} alt="" style={this.styles().imageAsset}/>
        </div>


        <div style={this.styles().collectionInfoContainer} >

          {/*<div style={this.styles().collectionLabel}>COLLECTION</div>*/}

          <div style={this.styles().collectionTitle} className="serifFont">{this.props.title}</div>
          <div style={this.styles().subtitle}>{this.props.subtitle}</div>
          <div style={this.styles().caption}>{this.props.caption}</div>
          <SmallButtonRound
            label="ENTDECKEN"
            labelColor="#fff"
            background="#1C6ECC"
            onButtonClick={this.onCollectionSelected.bind(this)}
            id={99}
          />
        </div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        maxWidth:'860px',
        textAlign:'center',
        boxSizing:'border-box',
        //overflow:'hidden',
        marginTop:this.props.standalone && window.innerWidth >= 768 ? null : '100px',
        marginBottom:'20px',
        borderRadius:window.innerWidth >= 768 ? '3px' : null,

        borderBottom:'1px solid #E6E6E6',
        borderTop:window.innerWidth >= 768 ? '1px solid #E6E6E6' : null,
        borderLeft:window.innerWidth >= 768 ? '1px solid #E6E6E6' : null,
        borderRight:window.innerWidth >= 768 ? '1px solid #E6E6E6' : null,

        background:'#fff',
        width:'100%',
        padding:'100px 20px 40px 20px',
      },
      collectionImage:{
        background:'#DAC46D',
        borderRadius:'50%',
        width:'160px',
        height:'160px',
        position:'absolute',
        top:'-80px',
        left:'50%',
        transform:'translateX(-50%)',
        boxShadow:'0 1px 2px rgba(0,0,0,.3)',
        overflow:'hidden',
      },
      imageAsset:{
        width:'100%',
      },
      collectionInfoContainer:{
        margin:window.innerWidth >= 768 ? '0px auto' : '0px auto 10px auto',
        maxWidth:'420px',
        textAlign:'center',
        color:'#191919',
        //padding:'20px 20px 40px 20px',
        position:'relative',
      },
      collectionLabel: {
        fontSize:'12px',
        color:'#DAC46D',
        marginBottom:'20px',
      },
      collectionTitle: {
        fontSize:'24px',
        lineHeight:'31px',
      },
      subtitle: {
        fontSize:'16px',
        marginTop:'10px',
      },
      caption: {
        fontSize:'12px',
        marginBottom:'30px',
      },


    }

  }
}

CollectionPreviewItem.propTypes = {
  imageUrl:PropTypes.string.isRequired,
  title:PropTypes.string.isRequired,
  subtitle:PropTypes.string.isRequired,
  caption:PropTypes.string,
  path:PropTypes.string.isRequired,
  standalone:PropTypes.bool,
}

export default withRouter(CollectionPreviewItem);