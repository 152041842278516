import React from 'react';
import ResponsiveView from './ResponsiveView';

import { fullTextQuery } from '../api/kapiClient';

import { getParamValue } from '../utils/helpers';
import { track } from '../utils/trackingService';

import MainSearchBox from '../components/MainSearchBox';
import SearchResultCluster from '../components/SearchResultCluster';

import DummyItemCluster from '../components/DummyItemCluster';

//import loader from '../img/loader_tail_spin.svg';
import icnEmptySearchView from '../img/icn_search_big.png';
import icnNoSearchResults from '../img/icn_search_failed_big.svg';

import { withTranslation } from 'react-i18next';

import icnDiscover from '../img/icn_discover_big.svg';


class SearchView extends ResponsiveView {

    constructor(props) {
        super(props, true);

        let searchQuery = getParamValue(this.props.location.search, 'q');

        this.state = {
            viewData: null,
            searchActive: false,
            searchQuery: searchQuery,
        };

        this.onStartSearch = this.onStartSearch.bind(this);
        this.onLoadSearchResults = this.onLoadSearchResults.bind(this);
        this.onDataReady = this.onDataReady.bind(this);

    }

    componentDidMount() {

        track('load-page', { pageId: 'Search' });

        console.log("*** SEARCH VIEW DID MOUNT ***");
        console.log("history: ", this.props);
        if (this.state.searchQuery) {
            this.onLoadSearchResults(this.state.searchQuery)
        }

        console.log("window.scrollTop: ", window.scrollY);
        window.scrollTo(0, 0);

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.location.search !== nextProps.location.search) {
            this.props = nextProps;

            let searchQuery = getParamValue(this.props.location.search, 'q');
            console.log("history: ", this.props);
            console.log("window.location.search: ", window.location.search);
            console.log("searchQuery: ", searchQuery, this.state.searchQuery);
            if (searchQuery !== this.state.searchQuery) {

                if (searchQuery === undefined) {
                    this.setState({
                        viewData: null,
                        searchActive: false,
                        searchQuery: null,
                    });
                } else {
                    this.onLoadSearchResults(searchQuery)
                }

            }
        }
    }

    onViewResize() {
        return true;
    }

    onStartSearch(query) {
        this.props.history.replace('/search?q=' + query);
    }

    onLoadSearchResults(query) {
        console.log("Start search with query: ", query)


        track('search-request', { query: query });

        this.setState({
            viewData: null,
            searchActive: true,
            searchFailed: false,
            searchQuery: query,
        });

        fullTextQuery(query).then(function (data) {
            console.log("received data in SearchView: ", data);
            this.onDataReady(data);
        }.bind(this));

    }

    navigateToSection(path) {
        console.log("navigateToSection: ", this.props);

        this.props.history.push(path);
    }

    onDataReady(data) {
        console.log("-> onDataReady ", data);

        this.setState({
            viewData: data,
            searchActive: false,
            searchFailed: !this.hasAnySearchResults(data),
        }, function () {
            if (this.state.searchFailed) track('search-fail', { query: this.state.searchQuery });
        })
    }

    hasAnySearchResults(data) {

        const keysArray = Object.keys(data);

        for (const key of keysArray) {
            if (data[key] !== null) {
                return true;
            }
        }

        return false;
    }

    onItemSelected(type, id) {

        console.log("Navigate to search item: ", type.toLowerCase(), id);

        if (!type || type.length < 1 || !id) {
            console.warn("Can't navigate to search result as type or id is invalid.");
            return;
        }

        let path;

        path = "/discover/" + type.toLowerCase() + "/" + id;

        this.props.history.push(path);

    }

    render() {

        const { t } = this.props;

        return (
            <div style={this.styles().view}>
                <div style={this.styles().backgroundGradient}></div>
                <div style={this.styles().container}>

                    <div style={this.styles().searchboxContainer}>
                        <MainSearchBox
                            onStartSearch={this.onStartSearch}
                            defaultQuery={this.state.searchQuery}
                            searchActive={this.state.searchActive}
                            displaySuggestions={false}
                        />
                    </div>


                    {!this.state.searchActive && !this.state.viewData && !this.state.searchFailed
                        ? <div style={this.styles().noResultsContainer}>
                            <img src={icnEmptySearchView} alt="" />
                            <div style={this.styles().noResultsText}>{t('search.teaser')}</div>
                        </div>
                        : null
                    }


                    {this.state.searchActive
                        ? <div style={this.styles().resultsContainer}>
                            <DummyItemCluster />
                            <DummyItemCluster />
                            <DummyItemCluster />
                        </div>
                        : null
                    }


                    {this.state.viewData

                        ? <div style={this.styles().resultsContainer}>


                            {this.state.viewData.composers && this.state.viewData.composers.length > 0
                                ? <SearchResultCluster
                                    type="COMPOSERS"
                                    title={t('searchresult.composers')}
                                    data={this.state.viewData.composers}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.concerts && this.state.viewData.concerts.length > 0
                                ? <SearchResultCluster
                                    type="CONCERTS"
                                    title={t('searchresult.concerts')}
                                    data={this.state.viewData.concerts}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
                                ? <SearchResultCluster
                                    type="RECORDINGS"
                                    title={t('searchresult.recordings')}
                                    data={this.state.viewData.recordings}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.works && this.state.viewData.works.length > 0
                                ? <SearchResultCluster
                                    type="WORKS"
                                    title={t('searchresult.works')}
                                    data={this.state.viewData.works}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.orchestras && this.state.viewData.orchestras.length > 0
                                ? <SearchResultCluster
                                    type="ORCHESTRAS"
                                    title={t('searchresult.orchestras')}
                                    data={this.state.viewData.orchestras}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.artists && this.state.viewData.artists.length > 0
                                ? <SearchResultCluster
                                    type="ARTISTS"
                                    title={t('searchresult.artists')}
                                    data={this.state.viewData.artists}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.locations && this.state.viewData.locations.length > 0
                                ? <SearchResultCluster
                                    type="LOCATIONS"
                                    title={t('searchresult.places')}
                                    data={this.state.viewData.locations}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                            {this.state.viewData.events && this.state.viewData.events.length > 0
                                ? <SearchResultCluster
                                    type="EVENTS"
                                    title={t('searchresult.events')}
                                    data={this.state.viewData.events}
                                    visibleElements={4}
                                    onItemSelected={this.onItemSelected.bind(this)}
                                />
                                : null
                            }

                        </div>
                        : null
                    }

                    {this.state.searchFailed
                        ? <div style={this.styles().noResultsContainer}>
                            <img src={icnNoSearchResults} alt="" />
                            <div style={this.styles().noResultsText}>{t('search.error')}</div>
                            <div>

                                <div style={this.styles().sectionTeaser.container} key="sectionTeaser2" onClick={this.navigateToSection.bind(this, '/discover')}>
                                    <img src={icnDiscover} alt="icnDiscover" />
                                    <div style={this.styles().sectionTeaser.title}>{t('dashboard.discover.header')}</div>
                                    <div style={this.styles().sectionTeaser.description}>{t('dashboard.discover.teaser')}</div>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                </div>

            </div>
        );
    }

    styles() {

        return {
            view: {
                background: '#3A3A3A',
                //height:'100%',
                paddingLeft: window.innerWidth > 768 ? '60px' : '0px',
            },
            backgroundGradient: {
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100vh',
                background: 'url(' + process.env.PUBLIC_URL + '/img/bg_dark_gradient.png)',
                backgroundSize: 'cover',
                zIndex: 0,
            },
            container: {
                position: 'relative',
                //overflow:'hidden',
                //paddingLeft:'60px',
                padding: '0px 20px 100px 20px',
                color: '#fff',
                maxWidth: '860px',
                margin: '0 auto',
            },
            searchboxContainer: {
                maxWidth: '480px',
                margin: '0px auto',
                paddingTop: '20px',
                position: 'relative',
            },
            spinnerContainer: {
                padding: '50px 20px',
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: '700',
            },
            loaderIcon: {
                width: '48px',
                marginBottom: '20px',
            },
            pageTitle: {
                fontSize: '28px',
                fontWeight: '900',
                marginBottom: '40px',
                color: '#fff',
            },
            pageIcon: {
                marginTop: '40px',
            },
            emptyViewDescription: {
                fontSize: '18px',
                fontWeight: '700',
            },
            noResultsContainer: {
                textAlign: 'center',
                marginTop: '80px',
            },
            noResultsText: {
                fontSize: '18px',
                fontWeight: '700',
                marginTop: '20px',
            },
            sectionTeasersContainer: {
                overflow: 'hidden',
                /*display:window.innerWidth >= 1024 ? 'flex' : 'block',*/
                flexWrap: 'wrap',
                position: 'relative',
                display: 'inline-flex'
            },
            sectionTeaser: {
                container: {
                    background: 'rgba(255,255,255,.15)',
                    padding: '40px 40px 60px 40px',
                    boxSizing: 'border-box',
                    margin: window.innerWidth >= 1024 ? '0.2em' : '4px',
                    width: 'calc(100% - .6em)',
                    cursor: 'pointer',
                    ":hover": {
                        background: 'rgba(218,196,109,.5)',
                    },
                },
                title: {
                    fontSize: '28px',
                    fontWeight: '900',
                    marginTop: '20px',
                },
                description: {
                    color: '#ccc',
                    fontSize: '18px',
                    margin: '20px auto 0 auto',
                    maxWidth: '300px',
                },
            }
        }

    }
}

export default withTranslation()(SearchView);