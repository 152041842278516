import {isStorageAvailable} from './helpers';

// FAVOURITES

let favPrefix = 'com.karajan.favs.';

// APP settings
let configPrefix = 'com.karajan.config.';

export const setLanguage = function (language) {
  console.log("setLanguage for language: ", language);
  if(isStorageAvailable('localStorage')){
    localStorage.setItem(configPrefix+'language', JSON.stringify(language));
  }
};
export const getLanguage = function () {
  console.log("getLanguage for language: ");
  if(isStorageAvailable('localStorage')){
    return JSON.parse(localStorage.getItem(configPrefix+'language'));
  }
  return undefined;
};


export const setFavourite = function (key, data) {
  console.log("setFavourite for key: ", key, "and data: ", data);

  if(isStorageAvailable('localStorage')){
    localStorage.setItem(favPrefix+key, JSON.stringify(data));
  }

};

export const clearFavourite = function (key) {
  console.log("clearFavourite for key: ", favPrefix+key);
  if(isStorageAvailable('localStorage')) {
    localStorage.removeItem(favPrefix + key);
  }
};

export const getFavouriteData = function (key) {
  if(isStorageAvailable('localStorage')) {
    return JSON.parse(localStorage.getItem(favPrefix + key));
  }
};

export const isFavourite = function (key) {
  if(isStorageAvailable('localStorage')) {
    return (localStorage[favPrefix + key] !== null && localStorage[favPrefix + key] !== undefined);
  }
};

export const clearFavourites = function () {
  console.log("clearFavourites: ", localStorage);
  if(isStorageAvailable('localStorage')) {
    localStorage.clear();
  }
};

export const getAllFavourites = function(){

  let data = [];

  if(isStorageAvailable('localStorage')) {
    for (var key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if(key.indexOf(favPrefix) > -1){
          data.push(JSON.parse(localStorage[key]))
        }

      }
    }
  }

  return data;

};


// DISCOVERED VALUES

let discoveredPrefix = 'com.karajan.discovered.';

export const addDiscoveredItem = function(category, id){
  if(isStorageAvailable('localStorage')){

    let items = JSON.parse(localStorage.getItem(discoveredPrefix+category));

    if(!items) items = {};

    if(!items[id]) {
      items[id] = true;
      //console.warn("Item added to discovered list ", items);
    } else {
      //console.warn("Item was already discovered");
    }

    localStorage.setItem(discoveredPrefix+category, JSON.stringify(items));
  }
};

export const getDiscoveredValue = function(category){
  if(isStorageAvailable('localStorage')){

    let all = JSON.parse(localStorage.getItem(discoveredPrefix+category));
    let value = 0;

    if(all){
      for (var key in all) {
        if (all.hasOwnProperty(key)) {
          value++;
        }
      }
    }

    return value;
  }
};


export const setCookiesConsent = function(){
  if(isStorageAvailable('localStorage')){
    localStorage.setItem('consentToCookies', '1');
  }
};

export const getCookiesConsent = function () {
  if(isStorageAvailable('localStorage')) {
    return JSON.parse(localStorage.getItem('consentToCookies'));
  }
};