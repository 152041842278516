import React from 'react';
import PropTypes from 'prop-types';

import {convertDateStringToObject, getFormattedDate, getFormattedLifeDates, joinComposersString, encodeId} from '../utils/helpers';

import ResponsiveView from '../views/ResponsiveView';
import ListItemInfoCard from '../components/ListItemInfoCard';
import ListItemInfoCardSimple from '../components/ListItemInfoCardSimple';

import Radium from 'radium';


class SearchResultsList extends ResponsiveView {


  constructor(props){
    super(props);

    this.state = {
      rerender:false,
      gridCluster:(this.props.type == "COMPOSERS" || this.props.type == "ORCHESTRAS" || this.props.type == "ARTISTS" || this.props.type == "LOCATIONS" || this.props.type == "EVENTS"),
      expandedSections:{},
    }
  }

  componentDidMount(){
    this.setState({
      isExpanded:false,
      rerender:true,
    })
  }

  onViewResize(){
    return true;
  }

  onItemSelected(type, id){
    this.props.onItemSelected(type, id);
  }

  getTitleForType(type){

    let title = "";

    switch(type){
      case "concerts":
        title = "Konzerte";
        break;
      case "recordings":
        title = "Aufnahmen";
        break;
      case "composers":
        title = "Komponisten";
        break;
      case "works":
        title = "Werke";
        break;
      case "orchestras":
        title = "Orchester";
        break;
      case "artists":
        title = "Künstler";
        break;
      case "locations":
        title = "Orte";
        break;
      case "events":
        title = "Ereignisse";
        break;
      default:
        break;
    }

    return title;
  }

  expandCluster(type){

    let expandedSectionsTmp = Object.assign({},this.state.expandedSections);

    if(!expandedSectionsTmp[type]){
      expandedSectionsTmp[type] = true;
    } else {
      expandedSectionsTmp[type] = false;
    }

    this.setState({
      expandedSections:expandedSectionsTmp,
    })
  }

  getExpandToggleLabel(cluster, type){

    let label = "";
    let diff = String(cluster.length-this.props.visibleElements);

    if(this.state.expandedSections[type]){
      label += "- "+diff+" weniger anzeigen"
    } else {
      label += "+ "+diff+" mehr anzeigen"
    }

    return label;
  }

  renderResultClusters(){

    let items = [];

    for (var type in this.props.results) {
      if( this.props.results.hasOwnProperty(type) ) {

        let cluster = this.props.results[type];

        items.push(
          <div key={type}>
            <div style={this.styles().clusterTitle}> {this.getTitleForType(type)}</div>
            {this.renderResultItems(type, cluster)}

            {cluster.length > this.props.visibleElements

              ? <div style={this.styles().expandToggle} onClick={this.expandCluster.bind(this, type)} key={'expandToggle'+type}>{this.getExpandToggleLabel(cluster, type)}</div>
              : null

            }
          </div>
        );
      }
    }


    return items;
  }

  renderResultItems(type, cluster){

    let items = [];
    let num = cluster.length;

    if(this.props.visibleElements < cluster.length && !this.state.expandedSections[type]){
      num = this.props.visibleElements;
    }

    for(let i=0; i<num; i++){

      let data = cluster[i];
      let item;

      switch(type){

        case "concerts":
          item = (<ListItemInfoCard
            key={i}
            id={encodeId('Concert',data.id)}
            title={data.title}
            subtitle={data.composers}
            caption={data.orchestra}
            dateString={getFormattedDate(convertDateStringToObject(data.date))}
            onItemSelected={this.onItemSelected.bind(this, 'concerts')}
            lastItem={i === num-1}
          />);
          break;
        case "recordings":
          item = (<ListItemInfoCard
            key={i}
            id={encodeId('Recording',data.id)}
            title={data.title}
            subtitle={data.composers}
            caption={data.orchestra}
            dateString={getFormattedDate(data.date)}
            onItemSelected={this.onItemSelected.bind(this, 'recordings')}
            lastItem={i === num-1}
          />);
          break;
        case "composers":
          item = (<ListItemInfoCardSimple
            key={i}
            id={encodeId('Person',data.id)}
            title={data.first_name+" "+data.last_name}
            subtitle={getFormattedLifeDates(data.birth, data.death, true)}
            imageUrl={data.image_url}
            onItemSelected={this.onItemSelected.bind(this, 'composers')}
            lastItem={i === num-1}
          />);
          break;
        case "works":
          item = (<ListItemInfoCardSimple
            key={i}
            id={encodeId('Work',data.id)}
            title={data.title}
            subtitle={data.composer}
            onItemSelected={this.onItemSelected.bind(this, 'works')}
            lastItem={i === num-1}
          />);
          break;
        case "locations":
          item = (<ListItemInfoCardSimple
            key={i}
            id={encodeId('Location',data.id)}
            title={data.name}
            subtitle={data.city+" - "+data.country}
            onItemSelected={this.onItemSelected.bind(this, 'locations')}
            lastItem={i === num-1}
          />);
          break;
        case "orchestras":
          item = (<ListItemInfoCardSimple
            key={i}
            id={encodeId('Orchestra',data.id)}
            title={data.name}
            subtitle={''}
            onItemSelected={this.onItemSelected.bind(this, 'orchestras')}
            lastItem={i === num-1}
          />);
          break;
        case "artists":
          item = (<ListItemInfoCardSimple
            key={i}
            id={encodeId('Person',data.id)}
            title={data.first_name+" "+data.last_name}
            subtitle={getFormattedLifeDates(data.birth, data.death, true)}
            imageUrl={data.image_url}
            onItemSelected={this.onItemSelected.bind(this, 'artists')}
            lastItem={i === num-1}
          />);
          break;

        case "events":
          item = (<ListItemInfoCardSimple
            key={i}
            id={encodeId('Event',data.id)}
            title={data.name}
            subtitle={''}
            onItemSelected={this.onItemSelected.bind(this, 'events')}
            lastItem={i === num-1}
          />);
          break;
        default:
          break;

      }

      items.push(item)

    }

    return items;

  }



  render() {
    return (
      <div style={this.styles().container}>

        {this.renderResultClusters()}

      </div>
    );
  }

  styles(){


    return {

      container:{
        textAlign:'left',
      },
      clusterTitle:{
        fontWeight:'900',
        fontSize:'18px',
        marginBottom:'10px',
        color:'#191919',
        paddingLeft:'20px',
        paddingTop:'30px',
      },
      resultItemContainer:{
        borderTop:'1px solid #3F3F3F',
        lineHeight:'48px',
        fontSize:window.innerWidth >= 480 ? '18px' : '16px',
        color:'#999999',
        cursor:'pointer',
        height:'48px',
        overflow:'hidden',
        wordBreak:'break-all',
        position:'relative',
        ':hover':{
          background:'rgba(255,255,255,.03)',
        }
      },
      resultHighlight:{
        color:'#fff'
      },
      gridContainer:{
        display:'flex',
        flexWrap:'wrap',
        //justifyContent:'space-between',
        marginLeft:'-10px',
      },

      expandToggle:{
        color:'#1C6ECC',
        //borderTop:'1px solid #E6E6E6',
        lineHeight:'48px',
        paddingLeft:'20px',
        fontSize:window.innerWidth >= 480 ? '18px' : '16px',
        cursor:'pointer',
        ':hover':{
          background:'rgba(255,255,255,.03)',
        }
      },
      typeLabel:{
        position:'absolute',
        right:'10px',
        top:'17px',
      }

    }

  }
}

SearchResultsList.propTypes = {
  results:PropTypes.object.isRequired,
  onItemSelected:PropTypes.func.isRequired,
  visibleElements:PropTypes.number,
};

export default Radium(SearchResultsList);