import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../../views/ResponsiveView';

import Radium from 'radium';


class CircleFrameButtonToggle extends ResponsiveView {

  componentDidMount(){
    this.state = {
      isActive:false,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps){

    this.props = nextProps;

  }

  onViewResize(){
    return true;
  }

  onButtonClick(){

    let newButtonState = !this.props.isActive;

    if(this.props.onButtonClick) this.props.onButtonClick(newButtonState);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>

        {this.props.isActive
          ? <img style={this.styles().icon} src={this.props.iconActive} alt="icon: active state"/>
          : <img style={this.styles().icon} src={this.props.icon} alt="icon: idle state"/>
        }

      </div>
    );
  }

  styles(){


    return {

      container:{
        margin:this.props.margin ? this.props.margin : null,
        display:'inline-block',
        textAlign:'center',
        border:'1px solid ' + (this.props.isActive ? this.props.activeBorder : this.props.idleBorder),
        width:this.props.width ? this.props.width : '32px',
        height:this.props.height ? this.props.height : '32px',
        borderRadius:'50%',
        cursor:'pointer',
        overflow:'hidden',
        /*':hover':{
          opacity:.8,
        }*/
      },
      icon:{
        margin:'50% auto 0px auto',
        transform:'translateY(-50%)',
        display:'block',
      }



    }

  }
}

CircleFrameButtonToggle.propTypes = {
  width:PropTypes.string,
  height:PropTypes.string,
  isActive:PropTypes.bool.isRequired,
  idleBorder:PropTypes.string.isRequired,
  activeBorder:PropTypes.string.isRequired,
  iconActive:PropTypes.string.isRequired,
  icon:PropTypes.string.isRequired,
  onButtonClick:PropTypes.func.isRequired,
  margin:PropTypes.string
}

export default Radium(CircleFrameButtonToggle);