import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Radium from 'radium';


class EmailSendButton extends Component {

  onViewResize(){
    return true;
  }

  onButtonClick(){
    if(this.props.onButtonClick) this.props.onButtonClick(this.props.id);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.props.disabled ? null : this.onButtonClick.bind(this)}>
        {this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        background:this.props.background ? this.props.background : '#4A90E0',
        color:'#fff',
        fontSize:'13px',
        fontWeight:'700',
        borderRadius:'3px',
        lineHeight:'36px',
        //maxWidth:'320px',
        boxShadow:this.props.disabled ? null : '0 1px 2px rgba(0,0,0,.2)',
        cursor:this.props.disabled ? null : 'pointer',
        textAlign:'center',
        WebkitAppearance:'none',
        borderStyle:'hidden',
        outline:'none',
        width:'100%',
        ':hover':{
          opacity:this.props.disabled ? .3 : .8,
        },
        opacity:this.props.disabled ? .3 : null,
      },



    }

  }
}

EmailSendButton.propTypes = {
  onButtonClick:PropTypes.func.isRequired,
  id:PropTypes.number,
  label:PropTypes.string.isRequired,
  background:PropTypes.string,
  labelColor:PropTypes.string,
  disabled:PropTypes.bool,
};

export default Radium(EmailSendButton);