var FLAGS = {};

export const setFeatureFlag = function(key, val){
  FLAGS[key] = val;
};

export const getFeatureFlag = function(key){
  return FLAGS[key];
};

export const printFeatureFlags = function(){
  console.log("*** FEATURE FLAGS ***");
  console.log(FLAGS);
};