import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { getScreenOrientation } from '../utils/helpers';

import ResponsiveView from '../views/ResponsiveView';

import CircleButtonToggle from './buttons/CircleButtonToggle';

import MainNavigationMobileItem from './MainNavigationMobileItem';

import icnDashboard from '../img/icn_mainnav_dashboard_small.svg';
import icnSearch from '../img/icn_mainnav_search_small.svg';
import icnCollections from '../img/icn_mainnav_collections_small.svg';
import icnDiscover from '../img/icn_mainnav_discover_small.svg';
import icnStories from '../img/icn_mainnav_stories_small.svg';
import icnFavourites from '../img/icn_mainnav_favourites_small.svg';
import icnJourneys from '../img/icn_mainnav_journeys_small.svg';
import icnSettings from '../img/icn_mainnav_settings_small.svg';
import iconInfo from '../img/icn_mainnav_info_small.svg';

import icnMainMenu from '../img/icn_menu_light.svg';
import icnClose from '../img/icn_menu_close.svg';

import LanguageSwitcher from './LanguageSwitcher';
import i18n from './../i18n';
import { getFeatureFlag, setFeatureFlag, printFeatureFlags } from './../utils/featureFlags';
import { setLanguage } from '../utils/localStorageService';
import { withTranslation } from 'react-i18next';


class MainNavigationMobile extends ResponsiveView {

  constructor(props) {
    super(props);

    this.activateCurrentItem = this.activateCurrentItem.bind(this);
    this.onViewSelected = this.onViewSelected.bind(this);

    this.state = {
      isMenuVisible: false,
      activeIndex: 0,
      isKeyboardOpen: false,
    };
  }

  componentDidMount() {
    this.activateCurrentItem();

    this.setState({
      lastOrientation: getScreenOrientation(),
      lastWidth: window.innerWidth,
      lastHeight: window.innerHeight,
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.activateCurrentItem();
    }
  }

  onViewResize() {

    let width = window.innerWidth;
    let height = window.innerHeight;

    let currentOrientation = getScreenOrientation();
    let isKeyboardOpen = false;

    if (this.state.lastOrientation === 'Portrait' && currentOrientation === 'Landscape') {

      if (width === this.state.lastWidth) {
        isKeyboardOpen = true;
      }
    }

    if (this.state.lastOrientation === 'Landscape' && currentOrientation === 'Landscape') {

      if (height <= this.state.lastHeight) {
        isKeyboardOpen = true;
      }
    }

    this.setState({
      isKeyboardOpen: isKeyboardOpen,
      lastOrientation: currentOrientation,
      lastHeight: height,
      lastWidth: width,
    });
  }

  toggleNavigationDisplay(newButtonState) {
    console.log("toggleNavigationDisplay ", newButtonState);
    this.setState({
      isMenuVisible: newButtonState,
    })
  }


  activateCurrentItem() {

    let index = 0;

    if (this.props.history.location.pathname.indexOf('/search') > -1) {
      index = 1;
    } else if (this.props.location.pathname.indexOf('/collections') > -1) {
      index = 2;
    } else if (this.props.location.pathname.indexOf('/discover') > -1) {
      index = 3;
    } else if (this.props.location.pathname.indexOf('/blog') > -1) {
      index = 4;
    } else if (this.props.location.pathname.indexOf('/favourites') > -1) {
      index = 5;
    } else if (this.props.location.pathname.indexOf('/journeys') > -1) {
      index = 6;
    } else if (this.props.location.pathname.indexOf('/imprint') > -1) {
      index = 7;
    }

    this.setState({
      activeIndex: index,
    });

  }

  onViewSelected(index, path) {
    this.props.history.push(path);

    this.setState({
      activeIndex: Number(index),
      isMenuVisible: false,
    });

  }

  onOverlayClick() {
    this.toggleNavigationDisplay();
  }

  render() {

    if (this.state.isKeyboardOpen) return null;

    const { t } = this.props;

    let currentLanguage = "de";
    const handleLanguageChange = (language) => {
      i18n.changeLanguage(language);
      setFeatureFlag('LANGUAGE', language);
      setLanguage(language);
      window.location.reload();
    };

    return (

      <div style={this.styles().container}>

        {this.state.isMenuVisible
          ? <div style={this.styles().overlay} onClick={this.onOverlayClick.bind(this)} />
          : null
        }


        <div style={this.styles().navigationContainer}>





          {this.state.isMenuVisible
            ? <div style={this.styles().navigationItems}>

              <div style={this.styles().language_container}>

                {t('languageSwitcher.title')}

                <LanguageSwitcher
                  languages={['en', 'de']} // Add more languages if needed
                  defaultLanguage={i18n.language}
                  onLanguageChange={handleLanguageChange}
                />

              </div>


              <MainNavigationMobileItem label={t('navigation.mobile.imprint')} index="6" path="/imprint" onViewSelected={this.onViewSelected} position="bottom" isActive={this.state.activeIndex === 6} icon={iconInfo} />
              {getFeatureFlag('JOURNEYS') ? <MainNavigationMobileItem label="Journeys" index="6" path="/journeys" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 6} icon={icnJourneys} /> : null}
              <MainNavigationMobileItem label={t('navigation.mobile.favourites')} index="5" path="/favourites" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 5} icon={icnFavourites} />
              {/*<MainNavigationMobileItem label="Blog" index="4" path="/blog" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 4} icon={icnStories}/>*/}
              {/*<MainNavigationMobileItem label="Stories" index="4" path="/stories" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 4} icon={icnStories}/>*/}
              <MainNavigationMobileItem label={t('navigation.mobile.discover')} index="3" path="/discover" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 3} icon={icnDiscover} />
              {getFeatureFlag('COLLECTIONS') ? <MainNavigationMobileItem label="Collections" index="2" path="/collections" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 2} icon={icnCollections} /> : null}
              <MainNavigationMobileItem label={t('navigation.mobile.search')} index="1" path="/search" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 1} icon={icnSearch} />
              <MainNavigationMobileItem label={t('navigation.mobile.dashboard')} index="0" path="/" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 0} icon={icnDashboard} />

            </div>
            : null
          }


          <CircleButtonToggle icon={icnMainMenu} iconActive={icnClose} idleBackground="#1C6ECC" activeBackground="#1C6ECC" onButtonClick={this.toggleNavigationDisplay.bind(this)} isActive={this.state.isMenuVisible} />

        </div>
        {/*
         DAC46D
        */}
      </div>
    );
  }

  styles() {

    return {

      container: {
        zIndex: '3',
        position: 'fixed',
        bottom: '0px',
        right: '0px',
      },
      overlay: {
        fixed: 'fixed',
        height: '100vh',
        width: '100vw',
        background: 'rgba(255,255,255,.9)',
      },
      navigationContainer: {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
      },
      navigationItems: {
        position: 'absolute',
        right: '0px',
        bottom: '60px',
        textAlign: 'right',
        width: '15em',
      },
      language_container: {
        padding: '0 20px',
        lineHeight: '40px',
        height: '40px',
        background: '#fff',
        color: '#4A4A4A',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '900',
        borderRadius: '20px',
        marginBottom: '15px',
        boxShadow: '0 1px 2px rgba(0,0,0,.2)',
        display: 'inline-block',
      },

    }
  }
}

export default withTranslation()(withRouter(MainNavigationMobile));