import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';
import RecordingTypeLabel from './RecordingTypeLabel';

class ListItemTwoLineIcon extends ResponsiveView {


  onViewResize(){
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for ListItemTwoLineIcon");
      return;
    }

    this.props.onItemSelected(this.props.id);
  }

  render() {

    return (
      <div style={this.styles().container} onClick={this.onItemSelected.bind(this)}>


        <div style={this.styles().title}>
          {this.props.title}
        </div>

        <div style={this.styles().subtitle}>
          {this.props.subtitle}
        </div>


        {this.props.labelType
          ? <div style={this.styles().typeLabel}>
              <RecordingTypeLabel
                type={this.props.labelType}
                lightTheme={true}
              />
            </div>
          : null
        }
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        padding:'10px 10px',
        cursor:'pointer',
        textAlign:'left',
        //overflow:'hidden',
        fontSize:'18px',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      title: {
        fontWeight:'400',
        fontSize:'16px',
        color:'#4A4A4A',
        //whiteSpace:'nowrap',
        overflow:'hidden',
        paddingRight:this.props.labelType ? '54px' : null,
      },
      subtitle:{
        color:'#999999',
        fontSize:'13px',
        lineHeight:'21px',
      },
      typeLabel:{

        position:'absolute',
        right:'10px',
        top:'15px',
        //marginTop:'6px',
      }

    }

  }
}

ListItemTwoLineIcon.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  labelType: PropTypes.string,
};


export default Radium(ListItemTwoLineIcon);