import './App.css';

import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

import { getParamValue } from './utils/helpers';
import { getFeatureFlag, setFeatureFlag, printFeatureFlags } from './utils/featureFlags';

import ResponsiveView from './views/ResponsiveView';
import MainNavigationSideBar from './components/MainNavigationSideBar';
import MainNavigationMobile from './components/MainNavigationMobile';
import DashboardView from './views/DashboardView';
import CollectionsView from './views/CollectionsView';
import DiscoverView from './views/DiscoverView';
import StoriesView from './views/StoriesView';
import BlogView from './views/BlogView';
import SearchView from './views/SearchView';
import FavouritesView from './views/FavouritesView';
import JourneysView from './views/JourneysView';
import ImprintView from './views/ImprintView';
import CookiesNotification from './components/CookiesNotification';
import ShareDialog from './components/ShareDialog';

import i18n from './i18n';
import { getLanguage } from './utils/localStorageService';

class App extends ResponsiveView {

  constructor(props) {
    super(props);
    this.state = {
      isSharingActive: false,
      sharingData: null,
    };

    console.log(window.location.search);
    console.log(getParamValue(window.location.search, 'dev'));
    console.log(getParamValue(window.location.search, 'language'));

    let language = getParamValue(window.location.search, 'language');

    let languageSelected = false;

    if (typeof language === "undefined") {
      language = getLanguage();
      
      console.log("From local storage: " + language);
      if (language == null) {
        language = 'de';
      }
      setFeatureFlag('LANGUAGE', language);
      i18n.changeLanguage(language);
      languageSelected = true;
    }




    if (typeof language !== "undefined" && language != null && languageSelected === false) {
      if (language === "en" || language === "de") {
        setFeatureFlag('LANGUAGE', language);
        i18n.changeLanguage(language);
        languageSelected = true;
      } else {
        console.log("language not supported");
      }
    }


    if (languageSelected === false) {
      let browserLanguage = navigator.language || navigator.userLanguage;
      let parts = browserLanguage.split("-");
      if (parts.length > 0) {
        browserLanguage = parts[0];
        console.log(`browserLanguage: ${browserLanguage}`);
        setFeatureFlag('LANGUAGE', browserLanguage);
        i18n.changeLanguage(browserLanguage);
      }
    }





    if (window.location.hostname !== 'localhost' && getParamValue(window.location.search, 'dev') !== '1') {
      console.log = function () { };
      console.warn = function () { };
      console.info = function () { };
    }

    setFeatureFlag('COLLECTIONS', false);
    setFeatureFlag('RECOMMENDATIONS', false);
    setFeatureFlag('MULTI_EMAIL_INPUT', false);
    setFeatureFlag('JOURNEYS', false);

    if (getParamValue(window.location.search, 'dev') === '1' || sessionStorage.getItem('DEV_MODE') === '1') {
      setFeatureFlag('COLLECTIONS', true);
      setFeatureFlag('RECOMMENDATIONS', true);
      setFeatureFlag('MULTI_EMAIL_INPUT', true);
      setFeatureFlag('JOURNEYS', false);

      sessionStorage.setItem('DEV_MODE', '1');
    }

    printFeatureFlags();

  }

  onViewResize() {
    this.setState({
      resize: true,
    })
  }

  openSharingDialog(data) {

    console.log("triggerSharingDialog for data: ", data);

    this.setState({
      isSharingActive: true,
      sharingData: data,
    })

  }

  onCloseSharingDialog() {
    this.setState({
      isSharingActive: false,
    })
  }

  render() {
    return (
      <div className="App" style={{ minWidth: "320px" }}>
        <ShareDialog
          isActive={this.state.isSharingActive}
          onCloseDialog={this.onCloseSharingDialog.bind(this)}
          url={this.state.sharingData ? this.state.sharingData.url : ''}
          title={this.state.sharingData ? this.state.sharingData.title : ''}
          text={this.state.sharingData ? this.state.sharingData.text : ''}
        />

        <CookiesNotification />


        <Router>
          <div>

            {/* As MainNavigationSideBar needs access to the history for manipulating it, it is created as a Route object which will be displayed for any path*/}
            {window.innerWidth > 768
              ? <Route path="/" render={({ history }) => <MainNavigationSideBar />} />
              : <Route path="/" render={({ history }) => <MainNavigationMobile />} />
            }


            <Route exact path="/" render={(props) => (<DashboardView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} />
            <Route exact path="/index.html" render={(props) => (<DashboardView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} />
            <Route path="/search" component={SearchView} />
            {getFeatureFlag('COLLECTIONS') ? <Route path="/collections" render={(props) => (<CollectionsView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} /> : null}
            <Route path="/discover" render={(props) => (<DiscoverView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} />
            <Route path="/stories" render={(props) => (<StoriesView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} />
            <Route exact path="/blog" render={(props) => (<BlogView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} />
            <Route exact path="/blog/:articleId" render={(props) => (<BlogView {...props} onShareRequest={this.openSharingDialog.bind(this)} />)} />
            <Route path="/favourites" component={FavouritesView} />
            <Route path="/journeys" component={JourneysView} />

            <Route path="/imprint" render={(props) => (<ImprintView {...props} />)} />
          </div>
        </Router>

      </div>
    );
  }

}

export default App;
