export const products = [
  {
    id:1,
    imageUrl:"https://images-eu.ssl-images-amazon.com/images/I/41PYQZD6MHL._AC_US300_QL65_.jpg",
    shopUrl:"https://www.amazon.de/Sinfonien-Herbert-Von-Bp-Karajan/dp/B000001GJO/ref=sr_1_15?s=music-classical&ie=UTF8&qid=1504532928&sr=1-15&keywords=Herbert+Von+Karajan+beethoven"
  },
  {
    id:2,
    imageUrl:"https://images-eu.ssl-images-amazon.com/images/I/61K623CKB2L._AC_US300_QL65_.gif",
    shopUrl:"https://www.amazon.de/Sinfonie-Ouvert%C3%BCren-Herbert-Von-Karajan/dp/B000001GJQ/ref=sr_1_6?s=music-classical&ie=UTF8&qid=1504532183&sr=1-6&keywords=Herbert+Von+Karajan+beethoven"
  },
  {
    id:3,
    imageUrl:"https://images-eu.ssl-images-amazon.com/images/I/51yMCa+ikfL._AC_US436_QL65_.jpg",
    shopUrl:"https://www.amazon.de/Karajan-Seine-Solisten-Vol-2-1969-1984/dp/B00JDB4C42/ref=sr_1_10?s=music-classical&ie=UTF8&qid=1504532452&sr=1-10&keywords=Herbert+Von+Karajan+beethoven"
  }
]
