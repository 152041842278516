import React, {Component} from 'react';
import PropTypes from 'prop-types';

import EmailTag from './EmailTag';
import EmailSendButton from './EmailSendButton';

import { withTranslation } from 'react-i18next';

class MultiEmailInput extends Component {

  constructor(props){
    super(props);

    this.state = {
      emailTags:[],
      currentInput:"",
      selectedTag:null,
      hasInvalidEmails:false,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
  }


  componentWillUnmount(){
    document.removeEventListener('keydown', this.onKeyDown);
  }

  isValidEmail(email) {

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

  }

  onClickInputContainer(){
    console.log("onClickInputContainer");
    this.setState({
      selectedTag:null,
    });
    this.inlineInput.focus();
  }

  onItemSelected(id){
    console.log("onItemSelected ", id)

    if(id === this.state.selectedTag){
      id = null;
      document.removeEventListener('keydown', this.onKeyDown);
    } else {
      document.addEventListener('keydown', this.onKeyDown);
    }

    this.setState({
      selectedTag:id,
    })
  }

  onKeyDown(e){

    if(e.key === "Backspace"){
      console.log("BACKSPACE detected");
      if(this.state.currentInput.length === 0 && this.state.selectedTag === null){
        this.setState({
          selectedTag:this.state.emailTags.length-1,
        })
      }

      else if(this.state.currentInput.length === 0 && this.state.selectedTag !== null){

        let tags = this.state.emailTags;
        tags.splice(this.state.selectedTag,1);

        let hasInvalidEmails = tags.filter(function(el){return el.isValid === false}).length > 0;

        document.removeEventListener('keydown', this.onKeyDown);

        this.setState({
          emailTags:tags,
          selectedTag:null,
          hasInvalidEmails:hasInvalidEmails,
        })
      }
    } else {
      document.removeEventListener('keydown', this.onKeyDown);
    }
  }

  onChangeInput(e){
    //console.log(e.target.value);

    let str = e.target.value;
    let tags = this.state.emailTags.slice();
    let newTag = '';
    let input = e.target.value;
    let hasInvalidEmails = false;

    let bulkInsertedTags = null;
    let insertedWithCommas = str.split(',');
    let insertedWithSemicolons = str.split(';');
    let insertedWithSpaces = str.split(' ');

    if(insertedWithCommas.length > 1){
      insertedWithCommas = insertedWithCommas.map(function(el){
        el = el.replace(/\s/g,'');
        return el;
      });
      bulkInsertedTags = insertedWithCommas;
    } else if(insertedWithSemicolons.length > 1) {
      insertedWithSemicolons = insertedWithSemicolons.map(function(el){
        el = el.replace(/\s/g,'');
        return el;
      });
      bulkInsertedTags = insertedWithSemicolons;
    } else if(insertedWithSpaces.length > 1){
      bulkInsertedTags = insertedWithSpaces;
    }

    if(!bulkInsertedTags) {
      let hasSpace = str[str.length - 1] === " ";
      let hasComma = str[str.length - 1] === ",";
      let hasSemicolon = str[str.length - 1] === ";";

      if (str.length > 1 && (hasSpace || hasComma || hasSemicolon)) {
        newTag = str.substring(0, str.length - 1);
        tags.push({label: newTag, isValid: this.isValidEmail(newTag)});
        input = "";
      }
    } else {
      bulkInsertedTags.forEach(function(el){

        if(el.length > 1){
          tags.push({label: el, isValid: this.isValidEmail(el)});
        }

      }.bind(this));
      input = "";
    }

    hasInvalidEmails = tags.filter(function(el){return el.isValid === false}).length > 0;

    this.setState({
      currentInput:input,
      emailTags:tags,
      selectedTag:null,
      hasInvalidEmails:hasInvalidEmails,
    })
  }

  onSendForm(){

    let validEmails = this.state.emailTags.map(function(el){

      if(el.isValid){
        return el.label;
      } else {
        return null;
      }

    }).filter(function(el){
      return el !== null;
    });

    this.props.onSendForm(validEmails)
  }

  renderTags(){

    let items = [];

    for(let i=0; i<this.state.emailTags.length; i++){

      items.push(
        <EmailTag
          key={i}
          id={i}
          label={this.state.emailTags[i].label}
          validColor={this.props.validColor}
          invalidColor={this.props.invalidColor}
          isActive={i === this.state.selectedTag}
          isValid={this.state.emailTags[i].isValid}
          onItemSelected={this.onItemSelected.bind(this)}
        />
      );

    }

    return items;

  }

  render(){

    const {t} = this.props;

    return(
      <div style={this.styles().container}>
        <div style={this.styles().inputContainer} onClick={this.onClickInputContainer.bind(this)} onKeyDown={this.onKeyDown}>
          {this.renderTags()}
          <input type="text"
                 style={this.styles().inlineInput}
                 className="multiEmailInputText"
                 placeholder={t('shareDialog.emailPlaceholder')}
                 value={this.state.currentInput}
                 onChange={this.onChangeInput.bind(this)}
                 ref={(input) => {this.inlineInput = input;}}

          />
        </div>
        {this.state.hasInvalidEmails
          ? <div style={this.styles().invalidNotification}>
              {t('shareDialog.emailInvalid')}
            </div>
          : null
        }
        <EmailSendButton
          onButtonClick={this.onSendForm.bind(this)}
          label={t('shareDialog.send')}
          disabled={this.state.emailTags.length === 0 || this.state.hasInvalidEmails}
          background={this.props.buttonColor}
        />
      </div>
    );
  }

  styles(){

    return {
      container:{
        marginBottom:'30px',
      },
      inputContainer:{
        color:'#141414',
        background:'#fff',
        textAlign:'left',
        padding:'6px',
        marginBottom:'10px',
        minHeight:'40px',
        lineHeight:'40px',
      },
      inlineInput:{
        border:'none',
        width:'initial',
        display:'inline',
        padding:'0',
        minWidth:'10px',
      },
      invalidNotification:{
        color:this.props.invalidColor,
        fontSize:'13px',
        fontWeight:'700',
        marginBottom:'10px',
      }
    }
  }

}

MultiEmailInput.propTypes = {
  validColor:PropTypes.string,
  invalidColor:PropTypes.string,
  buttonColor:PropTypes.string,
  onSendForm:PropTypes.func.isRequired,
};

export default withTranslation()(MultiEmailInput);