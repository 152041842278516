import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import {getFormattedDate, getParamValue, linkify} from '../utils/helpers';
import {setFavourite, clearFavourite, isFavourite} from '../utils/localStorageService';
import {track} from '../utils/trackingService';

import ResponsiveView from '../views/ResponsiveView';

import LinkTag from './LinkTag';
import CircleFrameButtonToggle from './buttons/CircleFrameButtonToggle';
import SmallButtonRound from './SmallButtonRound';

import icnFavIdle from '../img/icn_ui_favourites_inactive.svg';
import icnFavActive from '../img/icn_ui_favourites_active.svg';

import icnShare from '../img/icn_share.svg';

class StoryItem extends ResponsiveView {


  constructor(props){
    super(props);

    this.state = {
      isFavourite:isFavourite('/stories?id='+this.props.id),
    };

    this.renderTags = this.renderTags.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps){

    if(nextProps.id !== this.props.id){
      this.state = {
        isFavourite:isFavourite('/stories?id='+nextProps.id),
      };
    }

    this.props = nextProps;

  }

  onViewResize(){
    return true;
  }

  onButtonClick(){
    if(this.props.onButtonClick) this.props.onButtonClick(this.props.id);
  }

  onLinkClick(path){
    console.log("onLinkClick: ", path);
    if(this.props.onLinkClick) this.props.onLinkClick(path);
  }

  onFavouriteToggle(){

    this.setState({
      isFavourite:!this.state.isFavourite,
    }, function(){

      if(this.state.isFavourite){
        track('favourite-add', {type:'STORY'});
        setFavourite('/stories?id='+encodeURIComponent(this.props.id), {type:'STORY', data:this.getFavouriteStorageData()});
      } else {
        clearFavourite('/stories?id='+encodeURIComponent(this.props.id));
      }
    })
  }

  getFavouriteStorageData(){

    let text = this.props.text;

    if(this.props.text.length > 160){
      text = this.props.text.substring(0,160);
      text += "...";
      console.log("String longer than 140.", text);
    }


    return {
      title:this.props.title,
      subtitle:null,
      description:text,
      imageUrl:this.props.imageUrl,
      link:'/stories?id='+encodeURIComponent(this.props.id),
    }

  }

  onShareItem(){

    console.log("location: ", window.location);
    let sharingData = {
      url:window.location.origin+'/#/stories?id='+encodeURIComponent(this.props.id),
      title:this.props.title,
      text:this.props.text,
    };

    if(this.props.onShareItem) this.props.onShareItem(sharingData);
  }

  onLinkButtonClick(){
    if(this.props.onLinkButtonClick) this.props.onLinkButtonClick('/stories?id='+this.props.id);
  }

  renderTags(){

    if(!this.props.tags) return;

    let items = [];

    for(let i=0; i<this.props.tags.length;i++){

      let item = this.props.tags[i];

      items.push(
        <LinkTag
          key={i}
          label={item.label}
          link={item.link}
          color="#1C6ECC"
          onLinkClick={this.onLinkClick.bind(this)}
          alignment={this.props.alignment}
        />
      )
    }

    return items;

  }

  render() {
    
    return (
      <div style={this.styles().container} id={"story"+this.props.id}>

        <div style={this.styles().storyCard}>

          <img src={this.props.imageUrl} alt="" style={this.styles().storyImage}/>

          <div style={this.styles().cardContent}>
            <div style={this.styles().title} className="serifFont">
              {this.props.title}
            </div>

            <div style={this.styles().inlineDate}>
              Veröffentlicht am {getFormattedDate(this.props.releaseDate)}
            </div>

            <div style={this.styles().text} className="serifFont" dangerouslySetInnerHTML={{__html:linkify(this.props.text)}}>
            </div>

            {this.props.standalone
              ? <div style={this.styles().buttonContainer}>
                  <SmallButtonRound
                    label="WEITER LESEN"
                    labelColor="#fff"
                    background="#1C6ECC"
                    onButtonClick={this.onLinkButtonClick.bind(this)}
                    id={99}
                  />
                </div>
              : <div>
                  <div style={this.styles().contextMenu}>
                    <CircleFrameButtonToggle
                      isActive={this.state.isFavourite}
                      idleBorder="#1C6ECC"
                      activeBorder="#1C6ECC"
                      iconActive={icnShare}
                      icon={icnShare}
                      onButtonClick={this.onShareItem.bind(this)}
                      width="36px"
                      height="36px"
                      margin="0 6px 0 0"
                    />

                    <CircleFrameButtonToggle
                      isActive={this.state.isFavourite}
                      idleBorder="#1C6ECC"
                      activeBorder="#1C6ECC"
                      iconActive={icnFavActive}
                      icon={icnFavIdle}
                      onButtonClick={this.onFavouriteToggle.bind(this)}
                      width="36px"
                      height="36px"
                    />
                  </div>

                  {window.innerWidth < 1024 || this.props.forceFullWidth
                    ? <div style={this.styles().tagsContainerInline}>
                    {this.renderTags()}
                  </div>
                    : null
                  }
              </div>
            }

          </div>

        </div>

        <div style={this.styles().connectorContainer}>
          <div style={this.styles().connector}></div>
        </div>

        <div style={this.styles().verticalLineContainer}>
          <div style={this.styles().verticalLine}></div>
        </div>

        <div style={this.styles().connectorContainer}>
          <div style={this.styles().connectorEmpty}></div>
        </div>

        <div style={this.styles().secondColumnContainer}>
          Veröffentlicht am {getFormattedDate(this.props.releaseDate)}

          <div style={this.styles().tagsContainer}>
            {this.renderTags()}
          </div>

        </div>


      </div>
    );
  }

  styles(){

    let floatDirection = this.props.alignment;//'right';

    return {

      container:{
        textAlign:'left',
        position:'relative',
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'block' : 'flex',
        flexDirection:floatDirection === 'right' ? 'row-reverse' : null,
      },
      storyCard:{
        marginTop:window.innerWidth <= 660 ? '1px' : '15px',
        marginBottom:window.innerWidth <= 660 ? '1px' : '15px',
        borderRadius:window.innerWidth <= 660 || this.props.forceFullWidth ? '0px' : '2px',
        color:'#3f3f3f',
        width:'100%',
        maxWidth:this.props.forceFullWidth ? '100%' : '660px',
        overflow:'hidden',
        marginLeft:window.innerWidth < 1024 || this.props.forceFullWidth ? 'auto' : null,
        marginRight:window.innerWidth < 1024 || this.props.forceFullWidth ? 'auto' : null,
      },
      storyImage:{
        display:'block',
        width:'100%',
      },
      cardContent:{
        padding:'24px 20px 30px 20px',
        background:'#fff',
      },
      title:{
        fontSize:'22px',
        fontWeight:'700',
        color:'#191919',
      },
      inlineDate:{
        color:'#3F3F3F',
        fontSize:'13px',
        fontWeight:'700',
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? null : 'none',
        lineHeight:'23px',
      },
      text:{
        fontSize:'14px',
        lineHeight:'23px',
        marginTop:'16px',
      },
      contextMenu:{
        marginTop:'30px',
        borderTop:'1px solid #E6E6E6',
        borderBottom:'1px solid #E6E6E6',
        padding:'10px',
        textAlign:'center',
      },
      connectorContainer:{
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'none' : null,
        paddingTop:'65px',
        //display:'inline-block',
        //float:floatDirection,
        verticalAlign:'top',
      },
      connector:{
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'none' : null,
        width:'16px',
        height:'2px',
        background:'#D9C56F',
      },
      connectorEmpty:{
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'none' : null,
        width:'16px',
        height:'2px',
      },
      verticalLineContainer:{
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'none' : null,
        background:'#D9C56F',
      },
      verticalLine:{
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'none' : null,
        width:'2px',
        height:'100%',
        background:'#D9C56F',
      },
      secondColumnContainer:{
        display:window.innerWidth < 1024 || this.props.forceFullWidth ? 'none' : null,
        width:'100%',
        verticalAlign:'top',
        paddingTop:'54px',
        textAlign:floatDirection,
        fontSize:'13px',
        fontWeight:'700',
        letterSpacing:'.5px',

      },
      tagsContainer:{
        marginTop:'8px',
        display:'flex',
        flexWrap:'wrap',
        flexDirection:floatDirection === 'right' ? 'row-reverse' : null,
      },
      tagsContainerInline:{
        marginTop:'20px',
        display:'flex',
        flexWrap:'wrap',
      },
      buttonContainer:{
        textAlign:'center',
        marginTop:'30px',
      }

    }
  }
}

StoryItem.propTypes = {
  id:PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title:PropTypes.string.isRequired,
  text:PropTypes.string.isRequired,
  releaseDate:PropTypes.object.isRequired,
  onLinkClick:PropTypes.func.isRequired,
  onShareItem:PropTypes.func,
  imageUrl:PropTypes.string,
  tags:PropTypes.array,
  alignment:PropTypes.string,
  forceFullWidth:PropTypes.bool,
  standalone:PropTypes.bool,
  onLinkButtonClick: PropTypes.func,
};

export default Radium(StoryItem);