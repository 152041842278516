import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Radium from 'radium';

import {getLocationsList} from '../api/kapiClient';

import ResponsiveView from './ResponsiveView';
import DiscoverCategorySelectionListView from './DiscoverCategorySelectionListView';
import DiscoverListView from './DiscoverListView';

import InfoCardConcert from './infocards/InfoCardConcert';
import InfoCardRecording from './infocards/InfoCardRecording';
import InfoCardOrchestra from './infocards/InfoCardOrchestra';
import InfoCardLocation from './infocards/InfoCardLocation';
import InfoCardArtist from './infocards/InfoCardArtist';
import InfoCardComposer from './infocards/InfoCardComposer';
import InfoCardWork from './infocards/InfoCardWork';
import InfoCardEvent from './infocards/InfoCardEvent';

import MapPreview from '../components/MapPreview';

import icnArrowRight from '../img/icn_ui_arrow_right.svg';

import { withTranslation } from 'react-i18next';

class DiscoverView extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      isInfoContainerVisible:true,
      mapCenter:[13.030000,47.798729], // Salzburg
      //mapCenter:[16.3772449,48.200953], // Wien
      mapBoundingRect:null,
      poiHighlights:null,
      poiData:null,
      poiFilterType:null,
      infoCardType:null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    // on desktop, the infoView is scrolled back up after a route change by setting the scroll position
    // of the container div
    // on mobile the views (e.g. InfoCardArtist) are handling the resetting themselves,
    // as the whole window is scrolled an not just the container
    let el = document.getElementById('infoView');
    console.log('el:', el );
    if(el && el.scrollTo !== undefined) el.scrollTo(0,0);
  }

  componentDidMount(){

    getLocationsList(false).then(function(data){
      console.log("getLocationsList in DiscoverView: ", data);
      let poiData = [];

      if(data && data.data && data.data.locations){
        data.data.locations.edges.forEach(function(el){
          poiData.push(el.node);
        })
      }

      this.setState({
        poiData:poiData
      });

      console.log('poiData: ', poiData);
    }.bind(this));

  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onMapButton(){
    console.log("onMapButton in DiscoverView");

    this.setState({
      isInfoContainerVisible:false,
    })
  }

  updateMapPosition(data){
    console.log("updateMapPosition in DiscoverView ", data);

    if(!data) return;

    let tmpData = data.location ? data.location : data;

    this.setState({
      mapCenter:[tmpData.longitude, tmpData.latitude]
    });
  }

  setMapBoundingRect(rect, pois, poiFilterType, infoCardType){
    console.log("setMapBoundingRect ", rect, pois, poiFilterType, infoCardType);

    this.setState({
      mapBoundingRect:rect,
      poiHighlights:pois,
      poiFilterType:poiFilterType,
      infoCardType:infoCardType,
    })
  }

  setPoiHighlights(pois){
    console.log("setPoiHighlights: ", pois);

    this.setState({
      poiHighlights:pois,
    })
  }

  onMarkerSelected(data){

    let id;

    console.log("onMarkerSelected / data: ", data);

    // subPlaces dont have the properties attribute
    if(data.properties){
      id = data.properties.id;
    } else {
      id = data.id;
    }
    console.log("onMarkerSelected in DiscoverView: ", id);

    if(id){
      this.props.history.push('/discover/locations/'+id);
    } else {
      console.warn("Id for location is not set.");
    }

  }

  onShareInfoCard(data){
    this.props.onShareRequest(data);
  }

  showInfoContainer(){
    this.setState({
      isInfoContainerVisible:true,
    })
  }

  render() {


    if(this.view){ console.log("Height of DiscoverView ", this.view.offsetHeight)}

    const {t} = this.props;

    return (
      <div style={this.styles().view} id="discoverViewContainer">

        <div style={this.styles().infoView} id="infoView">

            <Switch>

              <Route exact path='/discover' render={(props) => (<DiscoverCategorySelectionListView {...props} title={t('discover.header')} endpoint={'/discover'}/>)}/>

              <Route exact path='/discover/concerts/list' render={(props) => (<DiscoverListView {...props} title={t('discover.concerts')} query={'getConcertsList'} defaultSort='NUMERIC'/>)}/>
              <Route exact path='/discover/concerts/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.concerts')} query={'getConcertsList'}/>)}/>
              <Route exact path='/discover/concerts/:id' render={(props) => (<InfoCardConcert {...props} onMapButton={this.onMapButton.bind(this)} onDataReady={this.updateMapPosition.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setPoiHighlights={this.setPoiHighlights.bind(this)}/>)}/>

              <Route exact path='/discover/recordings/list' render={(props) => (<DiscoverListView {...props} title={t('discover.recordings')} query={'getRecordingsList'} defaultSort='NUMERIC'/>)}/>
              <Route exact path='/discover/recordings/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.recordings')} query={'getRecordingsList'}/>)}/>
              <Route exact path='/discover/recordings/:id' render={(props) => (<InfoCardRecording {...props} onMapButton={this.onMapButton.bind(this)} onDataReady={this.updateMapPosition.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setPoiHighlights={this.setPoiHighlights.bind(this)}/>)}/>

              <Route exact path='/discover/composers/list' render={(props) => (<DiscoverListView {...props} title={t('discover.composers')} query={'getComposersList'}/>)}/>
              <Route exact path='/discover/composers/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.composers')} query={'getComposersList'}/>)}/>
              <Route exact path='/discover/composers/:id' render={(props) => (<InfoCardComposer {...props} onMapButton={this.onMapButton.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)}/>)}/>

              <Route exact path='/discover/works/list' render={(props) => (<DiscoverListView {...props} title={t('discover.works')} query={'getWorksList'} disableSort={true}/>)}/>
              <Route exact path='/discover/works/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.works')} query={'getWorksList'}/>)}/>
              <Route exact path='/discover/works/:id' render={(props) => (<InfoCardWork {...props} onMapButton={this.onMapButton.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setMapBoundingRect={this.setMapBoundingRect.bind(this)}/>)}/>

              <Route exact path='/discover/orchestras/list' render={(props) => (<DiscoverListView {...props} title={t('discover.orchestras')} query={'getOrchestrasList'} disableSort={true}/>)}/>
              <Route exact path='/discover/orchestras/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.orchestras')} query={'getOrchestrasList'}/>)}/>
              <Route exact path='/discover/orchestras/:id' render={(props) => (<InfoCardOrchestra {...props} onMapButton={this.onMapButton.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setMapBoundingRect={this.setMapBoundingRect.bind(this)}/>)}/>

              <Route exact path='/discover/artists/list' render={(props) => (<DiscoverListView {...props} title={t('discover.artists')} query={'getArtistsList'}/>)}/>
              <Route exact path='/discover/artists/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.artists')} query={'getArtistsList'}/>)}/>
              <Route exact path='/discover/artists/:id' render={(props) => (<InfoCardArtist {...props} onMapButton={this.onMapButton.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setMapBoundingRect={this.setMapBoundingRect.bind(this)}/>)}/>

              <Route exact path='/discover/locations/list' render={(props) => (<DiscoverListView {...props} title={t('discover.places')} query={'getLocationsList'} disableSort={true}/>)}/>
              <Route exact path='/discover/locations/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.places')} query={'getLocationsList'}/>)}/>
              <Route exact path='/discover/locations/:id' render={(props) => (<InfoCardLocation {...props} onMapButton={this.onMapButton.bind(this)} onDataReady={this.updateMapPosition.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setPoiHighlights={this.setPoiHighlights.bind(this)}/>)}/>

              <Route exact path='/discover/events/list' render={(props) => (<DiscoverListView {...props} title={t('discover.events')} query={'getEventsList'} disableSort={true} defaultSort='NUMERIC'/>)}/>
              <Route exact path='/discover/events/list/:id' render={(props) => (<DiscoverListView {...props} title={t('discover.events')} query={'getEventsList'}/>)}/>
              <Route exact path='/discover/events/:id' render={(props) => (<InfoCardEvent {...props} onMapButton={this.onMapButton.bind(this)} onDataReady={this.updateMapPosition.bind(this)} onShareInfoCard={this.onShareInfoCard.bind(this)} setPoiHighlights={this.setPoiHighlights.bind(this)}/>)}/>

              <Route path='/discover' render={(props) => (<div>{t('discover.notFound')}</div>)}/>
            </Switch>

        </div>

        <div style={this.styles().showInfoContainerButton} onClick={this.showInfoContainer.bind(this)}>
          <img src={icnArrowRight} alt="Icon: Back to InfoContainer" style={{display:'block'}}/>
        </div>

        {window.innerWidth <= 480 && this.state.isInfoContainerVisible
          ? null
          : <div style={this.styles().mapView}>
              <MapPreview
                defaultCoordinates={[13.030000,47.798729]}
                coordinates={this.state.mapCenter}
                mapBoundingRect={this.state.mapBoundingRect}
                poiHighlights={this.state.poiHighlights}
                interactive={true}
                isFullScreen={!this.state.isInfoContainerVisible}
                onMarkerSelected={this.onMarkerSelected.bind(this)}
                isLocked={false}
                //poiDataUrl="/mapdata/places.json"
                poiData={this.state.poiData}
                poiFilterType={this.state.poiFilterType}
                infoCardType={this.state.infoCardType}
                //poiDataUrl="/mapdata/journey.json"
              />
            </div>
        }

      </div>
    );
  }

  styles(){

    return {
      view:{
        backgroundColor:'#FAFAFA',
        backgroundSize:'cover',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
        position:'relative',
        height:'100vh',
        width:'100%',
        boxSizing:'border-box',
        //overflow:window.innerWidth >= 480 ? 'hidden' : null,
      },
      infoView:{
        marginRight:'-20px',
        maxWidth:'480px',
        width:'100%',
        position:'absolute',
        top:'0px',
        left:window.innerWidth > 768 ? '60px' : '0px',
        height:'100vh',
        overflowY:window.innerWidth >= 480 ? 'auto' : null,
        overflowX:'hidden',
        zIndex:'2',
        background:'#fff',
        boxShadow:'0 0 12px rgba(0,0,0,.5)',
        visibility:this.state.isInfoContainerVisible ? null : 'hidden',
        paddingBottom:window.innerWidth >= 768 ? null : '100px',
      },
      scrollContainer:{

      },
      mapView:{
        marginLeft:this.state.isInfoContainerVisible ? '480px' : '0px',
        height:'100%',
      },
      showInfoContainerButton:{
        width:'50px',
        height:'60px',
        borderTopRightRadius:'5px',
        borderBottomRightRadius:'5px',
        background:'#fff',
        position:'absolute',
        top:window.innerWidth > 480 ? '0px' : '10px',
        left:window.innerWidth > 768 ? '60px' : '0px',
        zIndex:'1',
        boxShadow:'0px 2px 5px rgba(0,0,0,.3)',
        paddingTop:'10px',
        paddingLeft:'5px',
        ':hover':{
          background:'#E6E6E6',
          cursor:'pointer',
        }
      }

    }

  }
}

export default withTranslation()(Radium(DiscoverView));