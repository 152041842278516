export const artists = [
  {
    id:1,
    imageUrl:"http://static4.suedkurier.de/storage/image/6/9/2/4/4894296_galerie-detail_1oK0Jr_I45IOj.jpg",
    firstName:"Anne-Sophie",
    lastName:"Mutter",
    role:"Violine",
    birthDate:
    {
      day:28,
      month:6,
      year:1963
    },
    description:"Anne-Sophie Mutter arbeitete zum ersten Mal am 29.05.1977 mit Herbert von Karajan zusammen. Ihre Zusammenarbeit dauerte 11 Jahre. Gemeinsam nahmen sie in dieser Zeit 12 Werke auf.",
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
  {
    id:2,
    imageUrl:"http://static4.suedkurier.de/storage/image/6/9/2/4/4894296_galerie-detail_1oK0Jr_I45IOj.jpg",
    firstName:"Ira",
    lastName:"Malaniuk",
    role:"Fricka",
    birthDate:
    {
      day:28,
      month:1,
      year:1919
    },
    deathDate:
    {
      day:26,
      month:2,
      year:2009
    },
    description:"Ira Malaniuk arbeitete zum ersten Mal am 05.08.1951 mit Herbert von Karajan zusammen. Ihre Zusammenarbeit dauerte über 12 Jahre. Während ihrer Zusammenarbeit nahmen sie 4 Werke auf.",
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
  {
    id:3,
    imageUrl:"http://static4.suedkurier.de/storage/image/6/9/2/4/4894296_galerie-detail_1oK0Jr_I45IOj.jpg",
    firstName:"Lisa",
    lastName:"Della Casa",
    role:"Sopran",
    birthDate:
    {
      day:1,
      month:2,
      year:1919
    },
    deathDate:
    {
      day:9,
      month:12,
      year:2012
    },
    description:"Lisa Della Casa arbeitete zum ersten Mal am 09.01.1952 mit Herbert von Karajan zusammen. Ihre Zusammenarbeit dauerte über 10 Jahre. Während ihrer Zusammenarbeit nahmen sie 2 Werke auf.",
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  }
];
