import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';
import LightBox from './LightBox';
import LockedLabel from './LockedLabel';

class MediaGrid extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      lightboxVisible:false,
      selectedImage:0,
    }

  }

  onViewResize(){
    return true;
  }

  onImageSelected(pos){
    this.setState({
      lightboxVisible:true,
      selectedImage:pos,
    });
  }

  closeLightbox(){
    this.setState({
      lightboxVisible:false,
      selectedImage:0,
    });
  }

  renderGrid(){

    let elements = [];

    for(let i=0; i<this.props.media.length; i++){

      let data = this.props.media[i];
      let imageUrl = data.imageUrl;

      if(data.thumbUrl) imageUrl = data.thumbUrl;

      let item = <div style={Object.assign({},this.styles().thumbContainer)} key={'thumb'+i} onClick={this.onImageSelected.bind(this, i)}><img style={this.styles().thumb} src={imageUrl} alt="" /></div>;

      elements.push(item);
    }

    return elements;

  }

  render() {
    return (
      <div>
        <div style={this.styles().container}>

          {!this.props.isLocked
            ? <div style={this.styles().contentContainer}>
                {this.renderGrid()}
              </div>
            : <LockedLabel label="BONUSMATERIAL"/>
          }
        </div>

        {this.state.lightboxVisible
          ? <LightBox media={this.props.media} startPos={this.state.selectedImage} closeView={this.closeLightbox.bind(this)}/>
          : null
        }

      </div>


    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        //padding:'30px 0',
        margin:window.innerWidth >= 480 && !this.props.isLocked ? '0 -0.5em' : null,
        border:this.props.isLocked ? '1px solid #DAC46D' : null,
        height:this.props.isLocked ? '200px' : null,
      },
      contentContainer:{
        overflow:'hidden',
        display:window.innerWidth >= 480 ? 'flex' : 'block',
        flexWrap: window.innerWidth >= 480 ? 'wrap' : null,
        //justifyContent:'center',
      },
      thumbContainer:{
        //flex:'0 1 25%',
        background:'#ff0',
        boxSizing:'border-box',
        margin:window.innerWidth >= 480 ? '0.5em' : '0px 0px 20px 0px',
        maxWidth: window.innerWidth >= 480 ? 'calc(25% - 1em)' : '100%',
        cursor:'pointer',
        ':hover':{
          opacity:'.8'
        }
      },
      thumb:{
        width:'100%',
        display:'block',
      }


    }

  }
}

MediaGrid.propTypes = {
  media:PropTypes.array,
  isLocked:PropTypes.bool,
};

export default Radium(MediaGrid);