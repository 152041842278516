import React, { Component } from 'react';

class ResponsiveView extends Component {

  constructor(props, resetScrollOnMount) {
    super(props);

    this.onViewResize = this.onViewResize.bind(this);
    window.addEventListener('resize', this.onViewResize);

    this.resetScrollOnMount = resetScrollOnMount;

    if(resetScrollOnMount === true){
      this.onScrollView = this.onScrollView.bind(this);
      window.addEventListener('scroll', this.onScrollView);
    }
  }

  onScrollView() {
    if(this.resetScrollOnMount === true){
      console.log("Reset view to top");
      this.resetScrollOnMount = false;
      window.scrollTo(0,0);
      window.removeEventListener('scroll', this.onScrollView);
    }
  }

  UNSAFE_componentWillMount() {

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onViewResize);
    window.removeEventListener('scroll', this.onScrollView);
  }

  onViewResize() {
    return true;
  }

  render() {
    return (
      <div>RENDER ResponsiveView</div>
    );
  }
}

export default ResponsiveView;