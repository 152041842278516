import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import ProgressBar from 'progressbar.js';

import ResponsiveView from '../views/ResponsiveView';

import {getFormattedDurationString} from '../utils/helpers';

class PlaylistItem extends ResponsiveView {

  componentDidMount(){

    this.progressBar = null;
    this.setProgressBar(0);

  }

  UNSAFE_componentWillReceiveProps(nextProps){

    if(nextProps.currentProgress != this.props.currentProgress){

      this.props = nextProps;

      if(this.props.isActive) this.setProgressBar(this.props.currentProgress);

    }

  }

  setProgressBar(progress){

    if(!this.progressBar) {
      this.progressBar = new ProgressBar.Circle('#progressIndicator' + this.props.id, {
        color: '#1C6ECC',
        trailColor: '#ddd',
        strokeWidth: 8,
        duration: 1,
        easing: 'easeInOut'
      });
    }

    this.progressBar.set(progress);
  }

  onViewResize(){
    return true;
  }

  onItemSelected(){
    if(this.props.duration) this.props.onItemSelected(this.props.id);
  }

  render() {

    let background = this.props.isActive ? {background:'#f9f9f9'} : null;

    return (
      <div style={Object.assign({}, this.styles().container, background)} onClick={this.onItemSelected.bind(this)}>


        <div style={this.styles().progressIndicatorContainer}>
          <div style={this.styles().progressIndicator} id={"progressIndicator"+this.props.id}></div>
        </div>

        <div style={this.styles().title}>
          {this.props.title}
        </div>

        {!this.props.isActive && this.props.duration > 0 && this.props.showDuration
          ? <div style={this.styles().duration}>
              {getFormattedDurationString(this.props.duration)}
            </div>
          : null
        }


      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        width:'100%',
        height:'60px',
        lineHeight:'60px',
        fontSize:'16px',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        paddingLeft:this.props.insetContent ? '10px' : null,
        paddingRight:this.props.insetContent ? '10px' : '5px',
        boxSizing:'border-box',
        cursor:this.props.duration ? 'pointer' : null,
        ':hover':{
          background:this.props.duration ? '#f9f9f9' : null,
        },
      },
      itemControl:{
        float:'left',
        width:'4px',
        height:'51px',
        background:'#1C6ECC',
        marginTop:'4px',
        //marginLeft:'4px',
        marginRight:'10px',
        borderRadius:'2px',
      },
      title: {
        fontWeight:'400',
        float:'left',
        maxWidth:this.props.duration > 0 ? '80%' : '100%',
        whiteSpace:'nowrap',
        overflow:'hidden',
      },
      duration: {
        float:'right',
      },
      progressIndicatorContainer:{
        display:!this.props.isActive ? 'none' : null,
        float:'right',
        width:'24px',
        height:'24px',
        marginTop:'17px',
        marginRight:'10px',
        position:'relative',
      },
      progressIndicator:{
        position:'absolute',
        top:'0',
        left:'0',
      }

    }

  }
}

PlaylistItem.propTypes = {
  title:PropTypes.string.isRequired,
  isActive:PropTypes.bool.isRequired,
  duration:PropTypes.number,
  insetContent:PropTypes.bool,
  id:PropTypes.number.isRequired,
  currentProgress:PropTypes.number,
  showDuration:PropTypes.bool
};

export default Radium(PlaylistItem);