import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


const LanguageSwitcher = ({ languages, defaultLanguage, onLanguageChange }) => {
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    onLanguageChange(language);
  };

  const { t } = useTranslation();

  return (
    <div style={containerStyle}>
      <select
        title={t('languageSwitcher.title')}
        style={selectStyles}
        value={currentLanguage}
        onChange={(e) => handleLanguageChange(e.target.value)}
      >
        {languages.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
    </div>
  );
};

const containerStyle = {
  display: 'inline-flex'
}

const selectStyles = {
  width: '60px',
  padding: '8px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
};

export default LanguageSwitcher;
