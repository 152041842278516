import i18n from "../i18n.js";

export const joinComposersString = function (data) {

  if (!data) return;

  let string = '';

  for (let i = 0; i < data.length; i++) {

    let stringToAdd = data[i].composer ? data[i].composer.lastName : '';

    if (stringToAdd.length > 0 && string.indexOf(stringToAdd) === -1) {
      if (i > 0) string += ', ';
      string += data[i].composer ? data[i].composer.lastName : '';
    }

  }

  return string;
};

export const joinSingleComposerString = function (composer) {

  console.log(`join ${composer}`);
  console.log(composer);

  let composerString = composer && composer.firstName && composer.firstName !== "null" ? composer.firstName + " " : "";
  composerString += composer && composer.lastName ? composer.lastName + " " : "";

  return composerString;
};

export const formatConcertItemSubtitle = function (composers, orchestra, date) {

  let composersString = composers ? joinComposersString(composers) + " • " : "";
  let orchestraString = orchestra ? orchestra.name + " • " : "";
  let dateDtring = date ? getFormattedDate(date) : "";

  return composersString + orchestraString + dateDtring;
};

export const getInitials = function (artistName) {

  if (!artistName) return;

  let nameArray = artistName.split(" ");
  let initials = "";


  if (nameArray.length === 1) {
    initials = nameArray[0].charAt(0);
  } else if (nameArray.length >= 2) {
    initials += nameArray[0].charAt(0);
    initials += nameArray[nameArray.length - 1].charAt(0);
  }

  return initials;
};

export const getCooperationString = function (data) {

  let string = '';

  string += data.concerts && data.concerts.length > 0 ? i18n.t("discover.concerts")+':' + data.concerts.length : '';
  string += string.length > 0 && data.recordings.length > 0 ? ' / ' : '';
  string += data.recordings && data.recordings.length > 0 ?  i18n.t("discover.recordings")+':' + data.recordings.length : '';

  return string;

};

export const leadingZero = function (number) {

  let string = String(number);

  if (number < 10) {
    string = "0" + String(number);
  }

  return string;

};

export const formatRecordingItemSubtitle = function (composer, date) {

  let composerString = joinSingleComposerString(composer);
  composerString += composerString.length > 0 ? " • " : "";

  let dateDtring = date ? getFormattedDate(date) : "";

  return composerString + dateDtring;

};

export const convertDateStringToObject = function (date) {

  if (!date) return {};

  date = date.split('-');
  date.forEach(function (val, i, array) {
    array[i] = parseInt(val);
  });

  let dateObj = { day: null, month: null, year: null };

  for (let i = date.length; i >= 0; i--) {

    if (i === date.length - 1) dateObj.year = date[i];
    if (i === date.length - 2) dateObj.month = date[i];
    if (i === date.length - 3) dateObj.day = date[i];

  }

  return dateObj;

};

export const getFormattedDate = function (date) {

  let dateString = "";
  let monthNames = [
    i18n.t("date.jan"),
    i18n.t("date.feb"),
    i18n.t("date.mar"),
    i18n.t("date.apr"),
    i18n.t("date.may"),
    i18n.t("date.jun"),
    i18n.t("date.jul"),
    i18n.t("date.aug"),
    i18n.t("date.sep"),
    i18n.t("date.oct"),
    i18n.t("date.nov"),
    i18n.t("date.dec")
  ];

  if (date) {

    if (date.day) {
      dateString += leadingZero(date.day) + ".";

      if (date.month) dateString += leadingZero(date.month) + ".";
      if (date.year) dateString += leadingZero(date.year);

    } else {

      if (date.month) dateString += monthNames[date.month - 1] + " ";
      if (date.year) dateString += leadingZero(date.year);

    }

  }

  return dateString;

};


export const getFormattedDateFromTimestamp = function (timestamp) {

  let dateString = "";

  let date = new Date(timestamp);

  if (date) {

    dateString += leadingZero(date.getDate()) + ".";
    dateString += leadingZero(date.getMonth() + 1) + ".";
    dateString += date.getFullYear();

  }

  return dateString;

};


export const getFormattedLifeDates = function (birth, death, signed) {

  //console.log("getFormattedLifeDates");
  //console.log(birth);
  //console.log(death);


  let dateString = "";

  if (birth && birth.year > 0) {

    if (signed) dateString += "✻ "
    if (birth.day) dateString += leadingZero(birth.day) + ".";
    if (birth.month) dateString += leadingZero(birth.month) + ".";
    if (birth.year) dateString += leadingZero(birth.year);

  } else {
    if (signed) {
      dateString += "✻ " + i18n.t("date.unknown");
    } else {
      dateString += i18n.t("date.unknown");
    }
  }

  if (death) {

    if (death.year > 0) {

      if (signed) {
        dateString += "\xa0\xa0\xa0✝ "
      } else {
        dateString += "-";
      }


      if (death.day) dateString += leadingZero(death.day) + ".";
      if (death.month) dateString += leadingZero(death.month) + ".";
      if (death.year) dateString += leadingZero(death.year);

    } else {

      let age = 101;

      if (birth && birth.year > 0) {

        let d = new Date();
        let nowYear = d.getFullYear();

        age = nowYear - birth.year;
      }

      if (age > 100) {
        if (signed) {
          dateString += "\xa0\xa0\xa0✝ " + i18n.t("date.unknown")
        } else {
          dateString += "- " + i18n.t("date.unknown");
        }
      }


    }


  }

  return dateString;

};

export const getFormattedDurationString = function (seconds) {

  let m = leadingZero(Math.floor(seconds / 60));
  let s = leadingZero(seconds - (m * 60));

  return m + ":" + s;
  //leadingZero

};

export const getTextWidth = function (text, font) {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
};


export const getParamValue = function (search, paramName) {
  var url = search.substring(1);//window.location.search.substring(1); //get rid of "?" in querystring

  var qArray = url.split('&'); //get key-value pairs
  for (var i = 0; i < qArray.length; i++) {
    var pArr = qArray[i].split('='); //split key and value
    if (pArr[0] === paramName)
      return decodeURIComponent(pArr[1]); //return value
  }
};


export const randomIntFromInterval = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};


export const getScreenOrientation = function () {
  var orientation = window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
  return orientation;
};

export const getOffset = function (el) {
  if (!el) return;
  el = el.getBoundingClientRect();
  return {
    left: el.left + window.scrollX,
    top: el.top + window.scrollY
  }
};

export const readLocalJSON = function (path, callback) {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', path, true);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    if (this.status == 200) {
      let file = new File([this.response], 'temp');
      let fileReader = new FileReader();
      fileReader.addEventListener('load', function () {
        //do stuff with fileReader.result
        let resultJSON = JSON.parse(fileReader.result);
        callback(resultJSON);
      });
      fileReader.readAsText(file);
    }
  };
  xhr.send();
};

export const convertJSONtoGeoJSON = function (json, keepOrder, callback) {

  console.log("convertJSONtoGeoJSON ", json);
  let geoJson = {
    type: "FeatureCollection",
    features: [],
  };

  let consollidatedPlaces = [];
  let index = 0;

  for (let key in json) {
    let entry = json[key];

    // filter out incomplete places and consollidate main places with their subplaces
    if (entry.deleted !== "TRUE" && entry.name && entry.latitude && entry.longitude && !entry.parentLocation) {

      let orderKey = keepOrder ? index : entry.id;

      if (!consollidatedPlaces[orderKey]) consollidatedPlaces[orderKey] = {};
      consollidatedPlaces[orderKey].place = entry;

    }

    index++;
  }

  for (let key in consollidatedPlaces) {

    let entry = consollidatedPlaces[key];

    let returnObj = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [convertStringToFloat(entry.place.longitude), convertStringToFloat(entry.place.latitude)]
      },
      properties: {
        ...entry.place,
        subPlaces: entry.subPlaces,
        id: entry.place.id,
      }
    };

    geoJson.features.push(returnObj);

  }

  callback(geoJson);

};

export const getMapBoundingRect = function (coords) {

  let longSorted = coords.slice().sort(function (a, b) {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  });

  let latSorted = coords.slice().sort(function (a, b) {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    return 0;
  });

  return [[longSorted[0][0], latSorted[0][1]], [longSorted[longSorted.length - 1][0], latSorted[latSorted.length - 1][1]]];

};

export const convertStringToFloat = function (value) {

  if (typeof value === "string") {
    value = value.replace(",", ".");
  }

  return parseFloat(value);

};


export const linkify = function (text) {

  // http://, https://, ftp://
  var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

  // www. sans http:// or https://
  var pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

  // Email addresses
  var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

  return text
    .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
    .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
    .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
};


export const isStorageAvailable = function (type) {
  try {
    var storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch (e) {
    return false;
  }
};

export const decodeId = function (encodedId) {

  /*
  console.log(`decode: ${encodedId}`);

  if(!isNaN(encodedId)) return encodedId;

  let decodedId = atob(encodedId);

  decodedId = decodedId.split(':')[1];

  console.log(decodedId);

  return decodedId;
  */
  return encodedId;

};


export const encodeId = function (type, id) {
  return id;

  /*
    console.log(`encodeId: ${type} ${id}`);
  
  
  
    let encodedId = btoa(type+':'+id);
  
    console.log(encodedId);
  
    return encodedId;
  */
};

export const convertInfoCardLink = function (link) {
  //karajan://info_card/place/458
  // Concert:4134

  let parts = link.split('/');
  let urlType = parts[parts.length - 2];
  let id = parts[parts.length - 1];
  let idType = null;

  if (urlType === 'place') {
    urlType = 'location';
  }

  idType = urlType.charAt(0).toUpperCase() + urlType.slice(1);

  let convertedLink = '/discover/' + urlType + 's/' + encodeId(idType, id);

  return convertedLink;
};

export const getFirstCharInString = function (str) {

  if (!isNaN(str)) {
    console.warn('getFirstCharInString - parameter is not a string: ', str);
    return '*';
  }

  var check = /^[a-zA-Z]+$/;
  let char = '*';

  for (let i = 0; i < str.length; i++) {

    if (str[i].match(check)) {
      char = str[i];
      break;
    }
  }

  return char;

};

export const sortByDate = function (arrayToSort, direction) {


  return arrayToSort.sort(function (a, b) {

    if (a.date.year && b.date.year) {
      if (a.date.year > b.date.year) {
        return direction === "ASC" ? -1 : 1;
      } else if (a.date.year < b.date.year) {
        return direction === "ASC" ? 1 : -1;
      } else if (a.date.year === b.date.year) {

        if (a.date.month && b.date.month) {

          if (a.date.month > b.date.month) {
            return direction === "ASC" ? -1 : 1;
          } else if (a.date.month < b.date.month) {
            return direction === "ASC" ? 1 : -1;
          } else if (a.date.month === b.date.month) {

            if (a.date.day && b.date.day) {
              if (a.date.day > b.date.day) {
                return direction === "ASC" ? -1 : 1;
              } else if (a.date.day < b.date.day) {
                return direction === "ASC" ? 1 : -1;
              } else {
                return 0;
              }
            }

            return 0;
          }
        }

        return 0;

      }
    }

    return 0;

  });

};

export const capitalizeTitle = function (str) {

  if (!isNaN(str)) {
    console.warn('capitalizeTitle - parameter is not a string: ', str);
    return '*';
  }


  let title = '';

  let parts = str.split(' ');

  for (var i = 0; i < parts.length; i++) {
    parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
  }

  return parts.join(' ');
};
export const capitalizeFirstLetter = function (str) {

  if (!isNaN(str)) {
    console.warn('capitalizeTitle - parameter is not a string: ', str);
    return '*';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};