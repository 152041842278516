import React, { Component } from 'react';

class MainNavigationMobileItem extends Component {


  onSelected(){
    this.props.onViewSelected(this.props.index, this.props.path);
  }

  render() {

    //let containerStyle = this.props.isActive ? this.styles().activeContainer : this.styles().container;

    return (
      <div style={this.styles().container} onClick={this.onSelected.bind(this)}>
        <div style={this.styles().label}> {this.props.label}</div>
        <img style={this.styles().icon} src={this.props.icon} alt={this.props.label}/>
      </div>
    );
  }

  styles(){

    return {

      container: {
        padding:'0 20px',
        lineHeight:'40px',
        height:'40px',
        background:'#fff',
        color:'#4A4A4A',
        cursor:'pointer',
        fontSize:'14px',
        fontWeight:'900',
        borderRadius:'20px',
        marginBottom:'15px',
        boxShadow:'0 1px 2px rgba(0,0,0,.2)',
        display:'inline-block',
      },
      label:{
        display:'inline',
        verticalAlign:'middle',
      },
      icon:{
        display:'inline',
        verticalAlign:'middle',
        marginLeft:'10px',
      }

    }
  }
}

export default MainNavigationMobileItem;