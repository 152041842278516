import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

class LinkTag extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onLinkClick(){
    console.log("this.props onLinkClick: ", this.props);
    if(!this.props.onLinkClick || !this.props.link) { console.warn("'onLinkClick' or 'link' is not defined in LinkTag"); return;};
    this.props.onLinkClick(this.props.link);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onLinkClick.bind(this)}>
        {this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        flexGrow:'1',
        padding:'0 10px',
        lineHeight:'32px',
        borderWidth:'1px',
        borderStyle:'solid',
        borderColor:this.props.color,
        borderRadius:'3px',
        color:this.props.color,
        fontSize:'11px',
        fontWeight:'700',
        flex:'0 1 auto',
        marginLeft:this.props.alignment === 'right' ? '5px' : '0',
        marginRight:this.props.alignment === 'right' ? '0px' : '5px',
        marginBottom:'5px',
        cursor:'pointer',
        textTransform:'uppercase',
        ':hover':{
          opacity:'.8',
        }
      },

    }

  }
}

LinkTag.propTypes = {
  label:PropTypes.string.isRequired,
  onLinkClick:PropTypes.func.isRequired,
  color:PropTypes.string,
  alignment:PropTypes.string,
};

export default Radium(LinkTag);