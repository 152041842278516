import {artists} from './data/artists';
import {composers} from './data/composers';
import {concerts} from './data/concerts';
import {events} from './data/events';
import {locations} from './data/locations';
import {orchestras} from './data/orchestras';
import {recordings} from './data/recordings';
import {works} from './data/works';

import {products} from './data/products';
import {playlists} from './data/playlists';

import {collections} from './data/collections';
import {tags} from './data/tags';
import {stories} from './data/stories';
import {mediaAssets} from './data/mediaAssets';
import {credits} from './data/credits';
import {recommendations} from './data/recommendations';


export const resolvers = {

  Query:{
    artists: () => (artists),
    artist: (obj, {id}) => { return artists.filter(function(el){return (id === el.id)})[0] },

    composers: () => (composers),
    composer: (obj, {id}) => { return composers.filter(function(el){return (id === el.id)})[0] },

    concerts: () => (concerts),
    concert: (obj, {id}) => { return concerts.filter(function(el){return (id === el.id)})[0] },

    events: () => (events),
    event:  (obj, {id}) => {
      return events.filter(function(el){return (id === el.id)})[0]
    },

    locations: () => (locations),
    location:  (obj, {id}) => {
      return locations.filter(function(el){return (id === el.id)})[0]
    },

    orchestras: () => (orchestras),
    orchestra: (obj, {id}) => { return orchestras.filter(function(el){return (id === el.id)})[0]},

    recordings: () => (recordings),
    recording: (obj, {id}) => { return recordings.filter(function(el){return (id === el.id)})[0]},

    musicworks: () => (works),
    musicwork: (obj, {id}) => {
      return works.filter(function(el){return (id === el.id)})[0]
    },

    collections: (obj, {maxCount}) => {
      let result = collections;
      if(maxCount) result = collections.slice(0,maxCount);
      return result;
    },
    collection: (obj, {id}) => { return collections.filter(function(el){return (id === el.id)})[0]},

    stories: (obj, {maxCount}) => {
      let result = stories;
      if(maxCount) result = stories.slice(0,maxCount);
      return result;
    },
    recommendations: (obj, {maxCount}) => {
      let result = stories;
      if(maxCount) result = recommendations.slice(0,maxCount);
      return result;
    },
  },

  Event: {
    location: (event) => (locations.filter(function(el){return (event.locationId === el.id)})[0]),
  },

  Person: {
    works: (person) => {
      return works.filter(function(el){return (person.id === el.composerId)});
    },
    concerts: (person) => {

      let result = [];

      person.concertIds.forEach(function(id){
        result = result.concat(concerts.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
    recordings: (person) => {

      let result = [];

      person.recordingIds.forEach(function(id){
        result = result.concat(recordings.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
  },


  Concert:{
    composers: (concert) => {
      let result = [];

      concert.composerIds.forEach(function(id){
        result = result.concat(composers.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;

    },
    artists: (concert) => {
      let result = [];

      concert.artistIds.forEach(function(id){
        result = result.concat(artists.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;

    },
    orchestra: (concert) => (orchestras.filter(function(el){return (concert.orchestraId === el.id)})[0]),
    location: (concert) => (locations.filter(function(el){return (concert.locationId === el.id)})[0]),
    works: (concert) => {
      let result = [];

      concert.workIds.forEach(function(id){
        result = result.concat(works.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;

    },
  },


  Location:{
    concerts: (location) => {

      let result = [];

      location.concertIds.forEach(function(id){
        result = result.concat(concerts.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
    recordings: (location) => {

      let result = [];

      location.recordingIds.forEach(function(id){
        result = result.concat(recordings.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
  },


  Orchestra: {
    concerts: (orchestra) => {

      let result = [];

      orchestra.concertIds.forEach(function(id){
        result = result.concat(concerts.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
    recordings: (orchestra) => {

      let result = [];

      orchestra.recordingIds.forEach(function(id){
        result = result.concat(recordings.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
  },


  Recording:{
    composer: (recording) => (composers.filter(function(el){return (recording.composerId === el.id)})[0]),
    orchestra: (recording) => (orchestras.filter(function(el){return (recording.orchestraId === el.id)})[0]),
    location: (recording) => (locations.filter(function(el){return (recording.locationId === el.id)})[0]),
    artists: (recording) => {
      let result = [];

      recording.artistIds.forEach(function(id){
        result = result.concat(artists.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;

    },
    program: (recording) => (recording.program),
    products: (recording) => {
      let result = [];

      recording.productIds.forEach(function(id){
        result = result.concat(products.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
  },


  MusicWork: {
    composer: (work) => (composers.filter(function(el){return (work.composerId === el.id)})[0]),
    concerts: (work) => {

      let result = [];

      work.concertIds.forEach(function(id){
        result = result.concat(concerts.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
    recordings: (work) => (recordings),
    parts: (work) => (work.parts),

  },

  Collection: {
    program: (recording) => (recording.program),
    location: (recording) => (locations.filter(function(el){return (recording.locationId === el.id)})[0]),
    story: (recording) => (stories.filter(function(el){return (recording.storyId === el.id)})[0]),
    tags: (collection) => {
      let result = [];

      collection.tagIds.forEach(function(id){
        result = result.concat(tags.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
    media: (collection) => {
      let result = [];

      collection.mediaIds.forEach(function(id){
        result = result.concat(mediaAssets.filter(function(innerEl){
          return innerEl.id === id;
        }));
      });

      return result;
    },
    credits: (collection) => (credits.filter(function(el){return (collection.creditsId === el.id)})[0]),
  }


};