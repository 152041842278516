import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../views/ResponsiveView';

import badgePremium from '../img/badge_premium.svg';

class LockedLabel extends ResponsiveView {

  render() {
    return (
      <div style={this.styles().container}>
        <img src={badgePremium} alt="" style={this.styles().badge}/>
        {this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'absolute',
        width:'100%',
        textAlign:'center',
        top:'50%',
        transform:'translateY(-50%)',
        color:'#3F3F3F',
        fontSize:'16px',
        fontWeight:'900',
        fontFamily:'Lato',
      },
      badge:{
        display:'block',
        margin:'0 auto 8px auto',
      }

    }

  }
}

LockedLabel.propTypes = {
  label:PropTypes.string.isRequired,
};

export default LockedLabel;