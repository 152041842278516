export const locations = [
  {
    id:1,
    latitude:48.200953,
    longitude:16.3772449,
    name:"Konzerthaus",
    city:"Wien",
    country:"Österreich",
    imageUrl:"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/GFestspielhaus_Salzburg.jpg/1200px-GFestspielhaus_Salzburg.jpg",
    description:"Herbert von Karajan trat hier zum ersten Mal am 28.08.1927 auf",
    eventIds:[1,2],
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
  {
    id:2, //3
    latitude:47.798707,
    longitude:13.0417204,
    name:"Felsenreitschule",
    city:"Salzburg",
    country:"Österreich",
    imageUrl:"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/GFestspielhaus_Salzburg.jpg/1200px-GFestspielhaus_Salzburg.jpg",
    description:"Herbert von Karajan trat hier zum ersten Mal am 28.08.1927 auf",
    eventIds:[1,2],
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
  {
    id:3, //4
    latitude:48.199466,
    longitude:16.3642426,
    name:"Theater an der Wien",
    city:"Wien",
    country:"Österreich",
    imageUrl:"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/GFestspielhaus_Salzburg.jpg/1200px-GFestspielhaus_Salzburg.jpg",
    description:"Herbert von Karajan trat hier zum ersten Mal am 28.08.1927 auf",
    eventIds:[1,2],
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
  {
    id:4, // 6
    latitude:48.203172,
    longitude:16.3691082,
    name:"Staatsoper",
    city:"Wien",
    country:"Österreich",
    imageUrl:"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/GFestspielhaus_Salzburg.jpg/1200px-GFestspielhaus_Salzburg.jpg",
    description:"Herbert von Karajan trat hier zum ersten Mal am 28.08.1927 auf",
    eventIds:[1,2],
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
  {
    id:5,
    latitude:47.798729,
    longitude:13.040992,
    name:"Großes Festspielhaus",
    city:"Salzburg",
    country:"Österreich",
    imageUrl:"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/GFestspielhaus_Salzburg.jpg/1200px-GFestspielhaus_Salzburg.jpg",
    description:"Herbert von Karajan trat hier zum ersten Mal am 28.08.1927 auf",
    eventIds:[1,2],
    recordingIds:[1,2,3],
    concertIds:[1, 2],
  },
];

