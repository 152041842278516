import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';

import ResponsiveView from '../views/ResponsiveView';
import RecordingTypeLabel from '../components/RecordingTypeLabel';

class ListItemSearch extends ResponsiveView {

  componentDidMount(){

    let containerWidth = '100%';

    if(this.props.labelType){
      let percent = 100-((60*100)/this.contentContainer.offsetWidth);
      containerWidth = percent+'%';
    }

    this.setState({
      containerWidth:containerWidth,
    })
  }

  onItemSelected(){

    this.props.onItemSelected(this.props.id)

  }

  onViewResize(){

    let containerWidth = '100%';

    if(this.props.labelType){
      let percent = 100-((60*100)/this.contentContainer.offsetWidth);
      containerWidth = percent+'%';
    }

    this.setState({
      resize:true,
      containerWidth:containerWidth,
    })
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onItemSelected.bind(this)}>


         <div style={this.styles().itemContentContainer} ref={content => {this.contentContainer = content;}}>
            {window.innerWidth >= 480
              ? <span style={this.styles().resultHighlight}>{this.props.highlightText}</span>
              : null
            }
            <span style={this.styles().contentPart}>
              {this.props.content}
            </span>

            {window.innerWidth < 480
             ? <div style={this.styles().resultHighlightSmall}>{this.props.highlightText}</div>
             : null
            }

          </div>

        {this.props.labelType
          ? <div style={this.styles().typeLabel}>
              <RecordingTypeLabel
                type={this.props.labelType}
              />
            </div>
          : null
        }


      </div>
    );
  }

  styles(){


    return {

      container:{
        borderTop:'1px solid #3F3F3F',
        //lineHeight:'48px',
        fontSize:window.innerWidth >= 480 ? '18px' : '16px',
        color:'#999999',
        cursor:'pointer',
        padding:'10px 0',
        //height:'48px',
        //overflow:'hidden',
        //wordBreak:'break-all',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        position:'relative',
        ':hover':{
          background:'rgba(255,255,255,.03)',
        },
      },
      itemContentContainer:{
        overflow:'hidden',
        textOverflow:'ellipsis',
        width:this.state.containerWidth,
        minWidth:'200px',
      },
      resultHighlight:{
        color:'#fff'
      },
      resultHighlightSmall:{
        color:'#fff',
        fontSize:'13px',
        lineHeight:'18px',
      },
      typeLabel:{
        position:'absolute',
        right:'10px',
        top:'17px',
      }
    }

  }
}

ListItemSearch.propTypes = {
  onItemSelected:PropTypes.func.isRequired,
  id:PropTypes.number.isRequired,
  highlightText:PropTypes.string.isRequired,
  content:PropTypes.string.isRequired,
  labelType:PropTypes.string,
}

export default Radium(ListItemSearch);