
export const track = function(eventId, props) {

  //const { trackPageView, trackEvent } = useMatomo();

  console.log(`TRACKER!! ${eventId}, ${JSON.stringify(props)}`);

  var _paq = window._paq = window._paq || [];
  


  switch (eventId) {
    case "search-request":
      _paq.push(['trackSiteSearch', props.query]);
      break;

    case "load-page":
      _paq.push(['setDocumentTitle', props.pageId]);
      
      //_paq.push(['setCustomVariable','1','VisitorType','Member']);
      
      
      _paq.push(['trackPageView']);
      break;

    default:
      //console.log(`RESOLVE!! ${eventId}, ${JSON.stringify(props)}`);
      _paq.push(['trackEvent', 'Tracker event unresolved!', eventId]);


  
  }


};