import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

class SuggestionItem extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onItemSelected(e){
    e.stopPropagation();
    this.props.onItemSelected(this.props.string);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onItemSelected.bind(this)}>
        {this.props.string}
        <div style={this.styles().type}>{this.props.type}</div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        lineHeight:'40px',
        height:'40px',
        overflow:'hidden',
        color:'#4A4A4A',
        fontSize:'16px',
        cursor:'pointer',
        padding:'0 15px',
        borderBottom:'1px solid #E6E6E6',
        ':hover':{
          background:'#f9f9f9',
        }
      },
      type:{
        display:window.innerWidth < 375 ? 'none' : null,
        float:'right',
        fontSize:'12px',
        fontWeight:'700',
        color:'#1A73CC',
      }

    }

  }
}

SuggestionItem.propTypes = {
  id:PropTypes.number.isRequired,
  string:PropTypes.string.isRequired,
  type:PropTypes.string.isRequired,
  onItemSelected:PropTypes.func.isRequired,
};

export default Radium(SuggestionItem);