
import httpClient from 'react-http-client';
import { getFeatureFlag } from '../utils/featureFlags';



const apiUri = sessionStorage.getItem("DEV_MODE") === "1" ? "http://localhost:8080/discoverApi" : "https://kapi.cloud/discoverApi";
//const apiUri = "https://kapi.cloud/discoverApi";




export const getDashboardData = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getDashboardData?language=${language}`
    );
};


export const getCategoryTotals = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getCategoryTotals?language=${language}`
    );
};


export const getConcertsList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getConcertsList?language=${language}`
    );
};

export const getConcertDetails = function (concertId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getConcertDetails?id=${concertId}&language=${language}`
    );
};


export const getRecordingsList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getRecordingsList?language=${language}`
    );
};

export const getRecordingDetails = function (recordingId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getRecordingDetails?id=${recordingId}&language=${language}`
    );
};

//getArtistsList
//getArtistDetails
export const getArtistsList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getArtistsList?language=${language}`
    );
};

export const getArtistDetails = function (artistId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getArtistDetails?id=${artistId}&language=${language}`
    );
};

// getLocationsList
export const getLocationsList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getLocationsList?language=${language}`
    );
};

//getLocationDetails
export const getLocationDetails = function (locationId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getLocationDetails?id=${locationId}&language=${language}`
    );
};


export const getComposersList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getComposersList?language=${language}`
    );
};

export const getComposerDetails = function (composerId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getComposerDetails?id=${composerId}&language=${language}`
    );
};

//getWorksList
export const getWorksList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getWorksList?language=${language}`
    );
};
//getWorkDetails
export const getWorkDetails = function (workId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getWorkDetails?id=${workId}&language=${language}`
    );
};

//getOrchestrasList
export const getOrchestrasList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getOrchestrasList?language=${language}`
    );
};

//getOrchestraDetails
export const getOrchestraDetails = function (orchestraId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getOrchestraDetails?id=${orchestraId}&language=${language}`
    );
};

//getEventsList
export const getEventsList = function () {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getEventsList?language=${language}`
    );
};

//getEventDetails
export const getEventDetails = function (orchestraId) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/getEventDetails?id=${orchestraId}&language=${language}`
    );
};


// full text query
export const fullTextQuery = function (query) {
    let language = getFeatureFlag("LANGUAGE");
    return httpClient.get(
        `${apiUri}/fullTextQuery?query=${query}&language=${language}`
    );
};
