import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


import MainNavigationSideBarItem from './MainNavigationSideBarItem';

import icnDashboard from '../img/icn_mainnav_dashboard_light.svg';
import icnSearch from '../img/icn_mainnav_search_light.svg';
import icnCollections from '../img/icn_mainnav_collections_light.svg';
import icnDiscover from '../img/icn_mainnav_discover_light.svg';
// import icnStories from '../img/icn_mainnav_stories_light.svg';
import icnFavourites from '../img/icn_mainnav_favourites_light.svg';
import icnJourneys from '../img/icn_mainnav_journeys.svg';
// import icnSettings from '../img/icn_mainnav_settings_light.svg';
import icnInfo from '../img/icn_mainnav_info_light.svg';
import LanguageSwitcher from './LanguageSwitcher';

import i18n from './../i18n';
import {getFeatureFlag, setFeatureFlag, printFeatureFlags} from './../utils/featureFlags';
import { setLanguage } from '../utils/localStorageService';


class MainNavigationSideBar extends Component {

  constructor(props) {
    super(props);

    this.activateCurrentItem = this.activateCurrentItem.bind(this);
    this.onViewSelected = this.onViewSelected.bind(this);

    this.state = {
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.activateCurrentItem();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.activateCurrentItem();
    }
  }

  activateCurrentItem() {

    let index = 0;

    if (this.props.history.location.pathname.indexOf('/search') > -1) {
      index = 1;
    } else if (this.props.location.pathname.indexOf('/collections') > -1) {
      index = 2;
    } else if (this.props.location.pathname.indexOf('/discover') > -1) {
      index = 3;
    } else if (this.props.location.pathname.indexOf('/blog') > -1) {
      index = 4;
    } else if (this.props.location.pathname.indexOf('/favourites') > -1) {
      index = 5;
    } else if (this.props.location.pathname.indexOf('/journeys') > -1) {
      index = 6;
    } else if (this.props.location.pathname.indexOf('/imprint') > -1) {
      index = 7;
    }

    this.setState({
      activeIndex: index,
    });

  }

  onViewSelected(index, path) {
    this.props.history.push(path);

    this.setState({
      activeIndex: Number(index),
    });

  }

  render() {

    let currentLanguage = "de";
    const handleLanguageChange = (language) => {
      i18n.changeLanguage(language);
      setFeatureFlag('LANGUAGE', language);
      setLanguage(language);
      window.location.reload();
    };


    return (
      <div style={this.styles().navigationContainer}>
        <MainNavigationSideBarItem label="Dashboard" index="0" path="/" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 0} icon={icnDashboard} />
        <MainNavigationSideBarItem label="Suche" index="1" path="/search" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 1} icon={icnSearch} />
        {getFeatureFlag('COLLECTIONS') ? <MainNavigationSideBarItem label="Collections" index="2" path="/collections" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 2} icon={icnCollections} /> : null}
        <MainNavigationSideBarItem label="Discover" index="3" path="/discover" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 3} icon={icnDiscover} />
        {/*<MainNavigationSideBarItem label="Stories" index="4" path="/stories" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 4} icon={icnStories}/>*/}
        {/*<MainNavigationSideBarItem label="Blog" index="4" path="/blog" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 4} icon={icnStories}/>*/}
        <MainNavigationSideBarItem label="Favourites" index="5" path="/favourites" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 5} icon={icnFavourites} />
        {getFeatureFlag('JOURNEYS') ? <MainNavigationSideBarItem label="Journeys" index="6" path="/journeys" onViewSelected={this.onViewSelected} isActive={this.state.activeIndex === 6} icon={icnJourneys} /> : null}

        <div style={this.styles().bottomIcon}>
          <LanguageSwitcher
            languages={['en', 'de']} // Add more languages if needed
            defaultLanguage={i18n.language}
            onLanguageChange={handleLanguageChange}
          />
          <MainNavigationSideBarItem label="Impressum" index="7" path="/imprint" onViewSelected={this.onViewSelected} position="bottom" isActive={this.state.activeIndex === 7} icon={icnInfo} />
        </div>
      </div>
    );
  }

  styles() {

    return {

      navigationContainer: {
        width: '60px',
        height: '100vh',
        background: '#3F3F3F',
        position: 'fixed',
        top: '0',
        zIndex: '5',
        boxShadow: '2px 0 4px rgba(0,0,0,.3)',
      },
      bottomIcon: {
        position: 'absolute',
        bottom: '0',
      }

    }
  }
}

export default withRouter(MainNavigationSideBar);