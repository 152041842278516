import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from './ResponsiveView';

import {Switch, Route} from 'react-router-dom';

import CollectionListView from './CollectionListView';
import CollectionDetailView from './CollectionDetailView';



class CollectionsView extends ResponsiveView {

  onViewResize(){

  }

  onShareRequest(data){
    console.log("onShareRequest in Colletionsview with data: ", data);
    this.props.onShareRequest(data);
  }

  render() {

    return (
      <div style={this.styles().container}>

        <Switch>
          <Route exact path='/collections' component={CollectionListView}/>
          <Route path='/collections/:id' render={(props) => (<CollectionDetailView {...props} onShareRequest={this.onShareRequest.bind(this)}/>)}/>
        </Switch>


      </div>
    );
  }

  styles(){

    return {
      container:{
        //background:'#131313',
        color:'#fff',
        overflow:'hidden',
        //paddingBottom:'100px',
      },
      contentContainer: {
        maxWidth:'860px',
        margin:'0 auto',
      },
    }

  }
}

CollectionsView.propTypes = {
  onShareRequest:PropTypes.func,
};

export default CollectionsView;