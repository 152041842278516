import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import {getLocationsList} from '../../api/kapiClient';
import {getLocationDetails} from '../../api/kapiClient';

import {getFormattedDate, formatConcertItemSubtitle, formatRecordingItemSubtitle, sortByDate, decodeId} from '../../utils/helpers';
import {track} from '../../utils/trackingService';

import InfoCard from './InfoCard';

import ListItemTwoLineIcon from '../../components/ListItemTwoLineIcon';
import ViewToggle from '../../components/ViewToggle';

import { withTranslation } from 'react-i18next';
import { capitalizeTitle } from '../../utils/helpers';


class InfoCardLocation extends ResponsiveView {

  constructor(props){
    super(props, true);

    this.state = {
      viewData:null,
      subViewId:0,
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps in InfoCardLocation: ", nextProps);

    if(this.props.match.params.id !== nextProps.match.params.id){
      this.props = nextProps;
    }
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.props.setPoiHighlights) this.props.setPoiHighlights(null);
  }

  onDataReady(result){
    console.log("-> onDataReady ", result, this.props);

    let data = Object.assign({}, result);

    if(data && data.concerts){
      data.concerts = sortByDate(data.concerts.slice(), 'ASC');
    }

    if(data && data.recordings){
      data.recordings = sortByDate(data.recordings.slice(), 'ASC');
    }

    this.setState({
      viewData:data,
      subViewId:!data.recordings || data.recordings.length < 1 ? 1 : 0,
    });

    if(data){
      if(this.props.onDataReady) this.props.onDataReady(data);
      if(this.props.setPoiHighlights) this.props.setPoiHighlights([ [data.longitude, data.latitude] ]);

      track('load-page', {pageId:'InfoCard', category:'Ort', id:decodeId(this.props.match.params.id), name:data.name, city:data.city, country:data.country});
    }

  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onNavigateTo(path){
    console.log("onNavigateTo: ", path)
    this.props.history.push(path);
  }

  onBackButton(){
    this.props.history.goBack();
  }

  onMapButton(){
    this.props.onMapButton();
  }

  onShareInfoCard(){
    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:this.state.viewData.name,
      text:this.state.viewData.city+', '+this.state.viewData.country,
    };

    this.props.onShareInfoCard(sharingData)
  }

  onEventItemSelected(id){
    this.props.history.push('/discover/events/'+id);
  }

  onRecordingItemSelected(id){
    this.props.history.push('/discover/recordings/'+id);
  }

  onConcertItemSelected(id){
    this.props.history.push('/discover/concerts/'+id);
  }

  onSubViewSelected(id){
    this.setState({
      subViewId:id,
    })
  }

  generateDescriptionText(){

    let description = "";

    if(this.state.viewData.karajansFirstConcert){
      description += "Herbert von Karajan trat hier zum ersten Mal ";
      description += (this.state.viewData.karajansFirstConcert.date.day ? "am " : "im ") + getFormattedDate(this.state.viewData.karajansFirstConcert.date);
      description += " auf."
    } else if (this.state.viewData.karajansFirstRecording){
      description += "Herbert von Karajan nahm hier zum ersten Mal ";
      description += (this.state.viewData.karajansFirstRecording.date.day ? "am " : "im ") + getFormattedDate(this.state.viewData.karajansFirstRecording.date);
      description += " auf."
    }

    return description;

  }

  renderEventListItems(){

    if(!this.state.viewData.events) return;

    let items = [];

    for(let i=0; i<this.state.viewData.events.length;i++){

      let pItem = this.state.viewData.events[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={getFormattedDate(pItem.date)}
          onItemSelected={this.onEventItemSelected.bind(this)}
          lastItem={i===this.state.viewData.events.length-1}
        />
      )

    }

    return items;
  }


  renderRecordingListItems(){

    if(!this.state.viewData.recordings) return;

    let items = [];

    for(let i=0; i<this.state.viewData.recordings.length;i++){

      let pItem = this.state.viewData.recordings[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={formatRecordingItemSubtitle(pItem.composer,pItem.date)}
          onItemSelected={this.onRecordingItemSelected.bind(this)}
          lastItem={i===this.state.viewData.recordings.length-1}
          labelType={pItem.type}
        />
      )

    }

    return items;
  }

  renderConcertsListItems(){

    if(!this.state.viewData.concerts) return;

    let items = [];

    for(let i=0; i<this.state.viewData.concerts.length;i++){

      let pItem = this.state.viewData.concerts[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={formatConcertItemSubtitle(pItem.composers, pItem.orchestra, pItem.date)}
          onItemSelected={this.onConcertItemSelected.bind(this)}
          lastItem={i===this.state.viewData.recordings.length-1}
        />
      )

    }

    return items;

  }

  render() {

    const {t} = this.props;

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getLocationsList}
          resultKeyList={'locations'}
          queryFunc={getLocationDetails}
          resultKey={'location'}
          title={t('infocard.place')}
          loadMockData={false}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          id={this.props.match.params.id}
          total={337}
          type="IC_LOCATION"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
            {
              title:this.state.viewData.name,
              subtitle:this.state.viewData.city+', '+this.state.viewData.country,
              imageUrl:this.state.viewData.imageUrl,
              link:this.props.location.pathname,
            } : null
          }
        >

          {this.state.viewData
            ?
            <div>
              <div style={this.styles().infoContainer}>

                <div style={this.styles().name}>
                  {this.state.viewData.name ? this.state.viewData.name : this.state.viewData.city}
                  {this.state.viewData.hall ? " - "+this.state.viewData.hall : null}
                </div>

                <div style={this.styles().subTitle}>
                  {this.state.viewData.city}, {this.state.viewData.country}
                </div>

                <div style={this.styles().description}>
                  <div style={this.styles().generatedDescription}>{this.state.viewData.karajanCredits}</div>
                  {this.state.viewData.description}
                </div>

              </div>

              {this.state.viewData.events && this.state.viewData.events.length > 0
                ? <div style={this.styles().eventListContainer}>

                    <div style={this.styles().listHeader}>EVENTS • {this.state.viewData.events.length}</div>

                    <div style={this.styles().list}>
                      {this.renderEventListItems()}
                    </div>

                  </div>
                : null
              }

              <div style={this.styles().toggleContainer}>
                {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
                && this.state.viewData.concerts && this.state.viewData.concerts.length > 0

                  ? <ViewToggle
                  activeView={this.state.subViewId}
                  labelLeft={t('infocard.work.recordings')+" • "+this.state.viewData.recordings.length}
                  labelRight={t('infocard.work.concerts')+" • "+this.state.viewData.concerts.length}
                  onViewSelected={this.onSubViewSelected.bind(this)}
                />
                  : <div>
                  {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
                    ? <div style={this.styles().toggleReplaceHeader}>{t('infocard.work.recordings')+" • "+this.state.viewData.recordings.length}</div>
                    : null
                  }
                  {this.state.viewData.concerts && this.state.viewData.concerts.length > 0
                    ? <div style={this.styles().toggleReplaceHeader}>{t('infocard.work.concerts')+" • "+this.state.viewData.concerts.length}</div>
                    : null
                  }
                </div>
                }

              </div>

              <div style={this.styles().toggleListContainer}>
                {this.state.subViewId === 0
                  ? this.renderRecordingListItems()
                  : this.renderConcertsListItems()
                }
              </div>
            </div>

            : null
          }
        </InfoCard>
      </div>
    );
  }

  styles(){

    return {
      infoContainer:{
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        padding:'20px 20px',
        textAlign:'left',
        fontSize:'18px',
        color:'#191919',
      },
      name: {
        fontWeight:'700',
        fontSize:'20px',
        color:'#191919',
        overflow:'hidden',
      },
      subTitle:{
        //color:'#3F3F3F',
        fontSize:'16px',
      },
      description:{
        fontSize:'16px',
        lineHeight:'21px',
        color:'#3F3F3F',
        marginTop:'15px'
      },
      generatedDescription:{
        marginBottom:'16px',
      },
      eventListContainer:{
        textAlign:'left',
        padding:'0 10px 30px 10px',
      },
      listHeader: {
        color:'#191919',
        fontWeight:'700',
        fontSize:'14px',
        padding:'20px 10px 10px 10px',
      },
      toggleContainer:{
        padding:'0 20px 20px 20px',
      },
      toggleListContainer:{
        padding:'0 10px 40px 10px',
      },
      toggleReplaceHeader:{
        fontSize:'16px',
        fontWeight:'700',
        color:'#3F3F3F',
        textAlign:'left',
      }
    }
  }
}

InfoCardLocation.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  onShareInfoCard: PropTypes.func,
  setPoiHighlights: PropTypes.func,
};

export default withTranslation()(InfoCardLocation);