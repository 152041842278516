export const works = [
  {
    id:1,
    composerId: 1,
    title:"La Mer / Trois esquisses symphoniques",
    recordings:13,
    concertIds:[1, 2],
    type:"ORCHESTERWERK",
    parts:[
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
        "duration":1357,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
      },
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
        "duration":256,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
        "duration":703,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
        "duration":787,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève, Pelleas: 'Grand-père'",
        "duration":1301,
        "audioUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3",
        "audioPreviewUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3"
      }
    ]
  },
  {
    id:2,
    composerId: 1,
    title:"Prélude à l’après-midi d’un faune",
    recordings:16,
    concertIds:[2, 3, 4],
    type:"ORCHESTERWERK",
    parts:[
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
        "duration":1357,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
      },
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
        "duration":256,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
        "duration":703,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
        "duration":787,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève, Pelleas: 'Grand-père'",
        "duration":1301,
        "audioUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3",
        "audioPreviewUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3"
      }
    ]
  },
  {
    id:3,
    composerId: 1,
    title:"Pélleas et Mélisande",
    recordings:3,
    concertIds:[],
    type:"OPER",
    parts:[
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
        "duration":1357,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
      },
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
        "duration":256,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
        "duration":703,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
        "duration":787,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève, Pelleas: 'Grand-père'",
        "duration":1301,
        "audioUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3",
        "audioPreviewUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3"
      }
    ]
  },
  {
    id:4,
    composerId: 1,
    title:"La Nocturnes",
    recordings:0,
    concertIds:[1],
    type:"ORCHESTERWERK",
    parts:[
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
        "duration":1357,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
      },
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
        "duration":256,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
        "duration":703,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
        "duration":787,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève, Pelleas: 'Grand-père'",
        "duration":1301,
        "audioUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3",
        "audioPreviewUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3"
      }
    ]
  },
  {
    id:5,
    composerId: 3,
    title:"Eine kleine Nachtmusik",
    recordings:10,
    concertIds:[1,2,3,4],
    type:"ORCHESTERWERK",
    parts:[
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
        "duration":1357,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
      },
      {
        "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
        "duration":256,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
        "duration":703,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
        "duration":787,
        "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
        "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
      },
      {
        "title":"1. Akt, 2. Szene: Arkel, Geneviève, Pelleas: 'Grand-père'",
        "duration":1301,
        "audioUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3",
        "audioPreviewUrl":"https://danke-dass-sie-die-classical-music-experiences-app-testen.s3.amazonaws.com/playlists/single-mp3/p242b528d.mp3"
      }
    ]
  },
];