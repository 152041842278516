import React from 'react';
import PropTypes from 'prop-types';

import { joinComposersString, getFormattedDate, getFormattedLifeDates, encodeId } from '../utils/helpers';

import ResponsiveView from '../views/ResponsiveView';
import ListItemSearch from '../components/ListItemSearch';

import Radium from 'radium';

import icnArtist from '../img/icn_artists_60x60.svg';
import icnComposer from '../img/icn_composer_60x60.svg';
import icnEvent from '../img/icn_events_60x60.svg';
import icnLocation from '../img/icn_locations_60x60.svg';
import icnOrchestra from '../img/icn_orchestra_60x60.svg';

import { withTranslation } from 'react-i18next';

class SearchResultCluster extends ResponsiveView {


  constructor(props) {
    super(props);

    this.state = {
      dataFrom: 0,
      dataTo: 4,
      rerender: false,
      gridCluster: (this.props.type == "COMPOSERS" || this.props.type == "ORCHESTRAS" || this.props.type == "ARTISTS" || this.props.type == "LOCATIONS" || this.props.type == "EVENTS"),
    }
  }

  componentDidMount() {
    this.setState({
      isExpanded: false,
      rerender: true,

    })
  }

  onViewResize() {
    return true;
  }

  onItemSelected(id) {

    console.log("Item in cluster selcted: ", id);
    this.props.onItemSelected(this.props.type, id);
  }

  renderResultItems() {

    let items = [];

    let start = Math.min(this.state.dataFrom, this.props.data.length);
    let end = Math.min(this.state.dataTo, this.props.data.length);


    for (let i = start; i < end; i++) {

      let data = this.props.data[i];
      let contentString = "";

      switch (this.props.type) {

        case "CONCERTS":

          let orchestraName = data.orchestra ? data.orchestra.name : "";

          if (window.innerWidth >= 480) {
            if (data.date) contentString = " • ";
            contentString += data.title + " • " + joinComposersString(data.composers) + " • " + orchestraName

          } else {
            contentString = data.title + " • " + joinComposersString(data.composers) + " • " + orchestraName
          }

          let date = getFormattedDate(data.date);

          items.push(
            <ListItemSearch
              highlightText={date}
              content={contentString}
              key={i}
              id={encodeId('Concert', data.id)}
              onItemSelected={this.onItemSelected.bind(this)}
            />
          );
          break;
        case "RECORDINGS":

          let kwvLabel = `KWV: ${data.kwv ? data.kwv : "none"}`;
          

          if (window.innerWidth >= 480) {
            if (data.date && data.date.year) contentString = " • ";
            contentString += kwvLabel + " • " + data.title + " • " + data.composer + " • " + data.orchestra;
          } else {
            contentString = kwvLabel + " • " + data.title + " • " + data.composer + " • " + data.orchestra
          }

          items.push(
            <ListItemSearch
              highlightText={getFormattedDate(data.date)}
              content={contentString}
              labelType={data.type}
              key={i}
              id={encodeId('Recording', data.id)}
              onItemSelected={this.onItemSelected.bind(this)}
            />

          );
          break;
        case "COMPOSERS":
          items.push(
            <div key={i} style={this.styles().resultItemImageContainer} onClick={this.onItemSelected.bind(this, encodeId('Person', data.id))}>
              <div style={this.styles().itemImageContainer}>
                <div style={this.styles().itemImageContainer}>
                  {data.imageUrl && data.imageUrl.length > 0
                    ? <img src={data.imageUrl} alt={data.firstName + " " + data.lastName} style={this.styles().itemImage} />
                    : <img src={icnComposer} alt="" style={this.styles().itemImagePlaceholder} />
                  }
                </div>
              </div>

              <div style={this.styles().name}>{data.firstName + " " + data.lastName}</div>
              <div style={this.styles().date}>{getFormattedLifeDates(data.birthDate, data.deathDate)}</div>
            </div>
          );
          break;
        case "WORKS":

          let composerName = data.composer ? data.composer.firstName + " " + data.composer.lastName : ""

          items.push(
            <ListItemSearch
              highlightText={window.innerWidth >= 480 ? data.title : composerName}
              content={window.innerWidth >= 480 ? " • " + composerName : data.title}
              key={i}
              id={encodeId('Work', data.id)}
              onItemSelected={this.onItemSelected.bind(this)}
            />
          );
          break;
        case "ORCHESTRAS":
          items.push(
            <div key={i} style={this.styles().resultItemImageContainer} onClick={this.onItemSelected.bind(this, encodeId('Orchestra', data.id))}>
              <div style={this.styles().itemImageContainer}>
                {data.imageUrl && data.imageUrl.length > 0
                  ? <img src={data.imageUrl} alt={data.name} style={this.styles().itemImage} />
                  : <img src={icnOrchestra} alt="" style={this.styles().itemImagePlaceholder} />
                }
              </div>
              <div style={this.styles().name}>{data.name}</div>
            </div>
          );
          break;
        case "ARTISTS":
          items.push(
            <div key={i} style={this.styles().resultItemImageContainer} onClick={this.onItemSelected.bind(this, encodeId('Person', data.id))}>
              <div style={this.styles().itemImageContainer}>
                {data.imageUrl && data.imageUrl.length > 0
                  ? <img src={data.imageUrl} alt={data.firstName + " " + data.lastName} style={this.styles().itemImage} />
                  : <img src={icnArtist} alt="" style={this.styles().itemImagePlaceholder} />
                }
              </div>
              <div style={this.styles().name}>{data.firstName + " " + data.lastName}</div>
              <div style={this.styles().date}>{getFormattedLifeDates(data.birthDate, data.deathDate)}</div>
            </div>
          );
          break;
        case "LOCATIONS":
          items.push(
            <div key={i} style={this.styles().resultItemImageContainer} onClick={this.onItemSelected.bind(this, encodeId('Location', data.id))}>
              <div style={this.styles().itemImageContainer}>
                {data.imageUrl && data.imageUrl.length > 0
                  ? <img src={data.imageUrl} alt={data.name} style={this.styles().itemImage} />
                  : <img src={icnLocation} alt="" style={this.styles().itemImagePlaceholder} />
                }
              </div>
              {data.name
                ? <div>
                  <div style={this.styles().name}>{data.name}</div>
                  <div style={this.styles().date}>{data.hall}</div>
                  <div style={this.styles().date}>{data.city + ", " + data.country}</div>
                </div>
                : <div>
                  <div style={this.styles().name}>{data.city + ", " + data.country}</div>
                </div>
              }

            </div>
          );
          break;
        case "EVENTS":
          items.push(
            <div key={i} style={this.styles().resultItemImageContainer} onClick={this.onItemSelected.bind(this, encodeId('Event', data.id))}>


              {data.imageUrl && data.imageUrl.length > 0
                ?
                <div style={this.styles().itemImageContainer}>
                  <img src={data.imageUrl} alt={data.title} style={this.styles().itemImage} />
                </div>
                :
                /*
                <div style={this.styles().itemImageContainer}>
                  <img src={icnEvent} alt="" style={this.styles().itemImagePlaceholder} />
                </div>
                */
                null

              }


              <div style={this.styles().name}>{data.title}</div>
              <div style={this.styles().date}>{getFormattedDate(data.date)}</div>
            </div>
          );
          break;
        default:
          break;

      }


    }

    return items;

  }

  expandCluster() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

  paginateClusterNext() {
    let from = Math.max(0, Math.min(this.state.dataFrom + 4, this.props.data.length));
    let to = Math.min(this.state.dataTo + 4, this.props.data.length);

    this.setState({
      dataFrom: from,
      dataTo: to
    })
  }
  paginateClusterPrev() {

    let from = Math.max(0, Math.min(this.state.dataFrom - 4, this.props.data.length));
    let to = Math.max(4, Math.min(this.state.dataTo - 4, this.props.data.length));

    this.setState({
      dataFrom: from,
      dataTo: to
    })
  }

  getExpandToggleLabel() {

    const { t } = this.props;

    let label = "";
    let diff = String(this.props.data.length - this.props.visibleElements);

    if (this.state.isExpanded) {
      label += "- " + diff + " " + t('searchresults.showLess')
    } else {
      label += "+ " + diff + " " + t('searchresults.showMore')
    }

    return label;
  }

  render() {
    return (
      <div style={this.styles().container}>
        <div style={this.styles().clusterTitle}>
          {this.props.title}:


          {this.props.data.length > this.props.visibleElements
            ?
            <span style={this.styles().numberMatches}>
              {this.props.data.length} matches
            </span>
            : null
          }

        </div>

        {this.state.gridCluster
          ? <div style={this.styles().gridContainer}>{this.renderResultItems()}</div>
          : this.renderResultItems()
        }

        {this.state.dataFrom > 0

          ? <span key="pagePrev" style={this.styles().expandTogglePrevNoLine} onClick={this.paginateClusterPrev.bind(this)}>&larr; </span>
          : null

        }

        {this.props.data.length > this.state.dataTo

          ? <span key="pageNext" style={this.styles().expandToggleNextNoLine} onClick={this.paginateClusterNext.bind(this)}>&rarr;</span>
          : null

        }





      </div>
    );
  }


  styles() {


    return {

      container: {
        textAlign: 'left',
        marginTop: '40px',
      },
      clusterTitle: {
        fontWeight: '900',
        fontSize: '18px',
        marginBottom: '15px',
      },
      resultItemContainer: {
        borderTop: '1px solid #3F3F3F',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '18px' : '16px',
        color: '#999999',
        cursor: 'pointer',
        height: '48px',
        overflow: 'hidden',
        wordBreak: 'break-all',
        position: 'relative',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      resultHighlight: {
        color: '#fff'
      },
      gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        //justifyContent:'space-between',
        marginLeft: '-10px',
      },
      resultItemImageContainer: {
        marginLeft: '10px',
        marginBottom: '20px',
        //flex:'0 1 0',
        flex: window.innerWidth >= 768 ? '1 1 20%' : '1 1 45%',
        maxWidth: window.innerWidth >= 768 ? '25%' : '50%',
        overflow: 'hidden',
        cursor: 'pointer',
        ':hover': {
          //background:'rgba(255,255,255,.03)',
          opacity: .7,
        }
      },
      itemImageContainer: {
        height: window.innerWidth >= 768 ? '210px' : (window.innerWidth / 2) + 'px',
        background: '#3f3f3f',
        position: 'relative',
      },
      itemImage: {
        height: window.innerWidth >= 768 ? '210px' : (window.innerWidth / 2) + 'px',
        display: 'block',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        fontSize: '13px',
        textAlign: 'left',
        color: '#3f3f3f',
      },
      itemImagePlaceholder: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        width: '50%',
      },
      name: {
        fontSize: '16px',
        color: '#fff',
        marginTop: '10px',
      },
      date: {
        color: '#E1E1E1',
        fontSize: '11px',
        lineHeight: '17px',
      },
      /*
      expandToggle: {
        color: '#DAC46D',
        borderTop: '1px solid #3F3F3F',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '18px' : '16px',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      expandToggleNoLine: {
        color: '#DAC46D',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '18px' : '16px',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      */
      expandToggleNext: {
        color: '#DAC46D',
        borderTop: '1px solid #3F3F3F',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '38px' : '36px',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      expandToggleNextNoLine: {
        color: '#DAC46D',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '38px' : '36px',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      expandTogglePrev: {
        color: '#DAC46D',
        borderTop: '1px solid #3F3F3F',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '38px' : '36px',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      expandTogglePrevNoLine: {
        color: '#DAC46D',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '38px' : '36px',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(255,255,255,.03)',
        }
      },
      typeLabel: {
        position: 'absolute',
        right: '10px',
        top: '17px',
      },
      numberMatches: {
        color: '#DAC46D',
        lineHeight: '48px',
        fontSize: window.innerWidth >= 480 ? '14px' : '12px',
        fontWeight: '100',
        paddingLeft: '1em',
      }

    }

  }
}

SearchResultCluster.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  visibleElements: PropTypes.number,
  onItemSelected: PropTypes.func.isRequired,
}

export default withTranslation()(Radium(SearchResultCluster));