import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import {getInitials} from '../utils/helpers';

import ResponsiveView from '../views/ResponsiveView';

class ListItemInfoCardSimple extends ResponsiveView {


  constructor(props){
    super(props);
    this.state = {
      imageError:false,
    };
  }

  onViewResize(){
    return true;
  }

  onImageLoadError(){
    console.warn("ImageLoad error for url: ", this.props.imageUrl);
    this.setState({
      imageError:true,
    })
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for ListItemInfoCardSimple");
      return;
    }

    this.props.onItemSelected(this.props.id);
  }

  render() {

    let background = this.props.isActive ? {background:'#f9f9f9'} : null;

    return (
      <div style={Object.assign({}, this.styles().container, background)} onClick={this.onItemSelected.bind(this)}>

        {this.props.imageExpected
          ? <div style={this.styles().imageContainer} className={"artistItem"}>
              {this.props.imageUrl && !this.state.imageError
                ? <img style={this.styles().image} src={this.props.imageUrl} alt="" data-key={'0'} id={this.props.id} onError={this.onImageLoadError.bind(this)}/>
                : <span>{getInitials(this.props.title)}</span>
              }
            </div>
          : null
        }

        <div style={this.styles().title}>
          {this.props.title}
        </div>

        <div style={this.styles().subtitle}>
          {this.props.subtitle}
        </div>

      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        background:'#fff',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        padding:'16px 20px',
        cursor:'pointer',
        textAlign:'left',
        overflow:'hidden',
        fontSize:'18px',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      imageContainer:{
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        overflow: 'hidden',
        background: '#4A4A4A',
        float: 'left',
        //fontStyle: 'italic',
        lineHeight: '44px',
        textAlign: 'center',
        fontSize: '17px',
        color:'#fff',
        position:'relative',
        marginRight:'10px',
      },
      image:{
        //width:'100%',
        height:'100%',
        marginLeft:'50%',
        transform:'translateX(-50%)',
      },
      title: {
        fontWeight:'700',
        fontSize:'20px',
        color:'#191919',
        whiteSpace:'nowrap',
        overflow:'hidden',
      },
      subtitle:{
        color:'#3F3F3F',
        fontSize:'16px',
        lineHeight:'24px',
      },

    }

  }
}

ListItemInfoCardSimple.propTypes = {
  id:PropTypes.number.isRequired,
  title:PropTypes.string.isRequired,
  subtitle:PropTypes.string,
  imageUrl:PropTypes.string,
  onItemSelected:PropTypes.func.isRequired,
  imageExpected:PropTypes.bool,
};

export default Radium(ListItemInfoCardSimple);