import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import {getWorksList} from '../../api/kapiClient';
import {getWorkDetails} from '../../api/kapiClient';

import {getParamValue, formatConcertItemSubtitle, formatRecordingItemSubtitle, joinSingleComposerString, getMapBoundingRect, sortByDate, decodeId} from '../../utils/helpers';
import {track} from '../../utils/trackingService';

import InfoCard from './InfoCard';

import ListItemArtist from '../../components/ListItemArtist';
import ListItemTwoLineIcon from '../../components/ListItemTwoLineIcon';
import ViewToggle from '../../components/ViewToggle';
import PlaylistPlayer from '../../components/PlaylistPlayer';

import placeholderWork from '../../img/placeholder_work.png';
import { withTranslation } from 'react-i18next';

import { capitalizeTitle } from '../../utils/helpers';

class InfoCardWork extends ResponsiveView {

  constructor(props){
    super(props, true);

    let sub = parseInt(getParamValue(this.props.location.search, 'sub'));

    this.state = {
      viewData:null,
      isPlaybackActive:false,
      subViewId:sub ? sub : 0,
      playlistExpanded:false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps){

    if(this.props.match.params.id != nextProps.match.params.id){
      this.props = nextProps;
    }
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    this.props.setMapBoundingRect(null, null);
  }

  onDataReady(result){
    console.log("-> onDataReady ", result, this.props);

    let data = Object.assign({}, result);

    let concertCoordinates = [];
    let concertsBoundingRect = null;

    let recordingCoordinates = [];
    let recordingsBoundingRect = null;


    if(data && data.concerts){

      data.concerts = sortByDate(data.concerts.slice(), 'ASC');

      data.concerts.forEach(function(concert){
        if(concert.location) concertCoordinates.push([concert.location.longitude,concert.location.latitude]);
      });

    }

    if(concertCoordinates.length >= 2) concertsBoundingRect = getMapBoundingRect(concertCoordinates);


    if(data && data.recordings){

      data.recordings = sortByDate(data.recordings.slice(), 'ASC');

      data.recordings.forEach(function(recording){
        if(recording.location) recordingCoordinates.push([recording.location.longitude,recording.location.latitude]);
      })
    }

    if(recordingCoordinates.length >= 2) recordingsBoundingRect = getMapBoundingRect(recordingCoordinates);

    this.setState({
      viewData:data,
      concertCoordinates:concertCoordinates,
      concertsBoundingRect:concertsBoundingRect,
      recordingCoordinates:recordingCoordinates,
      recordingsBoundingRect:recordingsBoundingRect,
      subViewId:!data.recordings || data.recordings.length < 1 ? 1 : 0,
    }, function(){
      this.setMapBoundingRect();

      track('load-page', {pageId:'InfoCard', category:'Werk', id:decodeId(this.props.match.params.id), title:data.title, composer:joinSingleComposerString(data.composer)});
    });

  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onNavigateTo(path){
    console.log("onNavigateTo: ", path)
    this.props.history.push(path);
  }

  onBackButton(){
    this.props.history.goBack();
  }

  onMapButton(){
    this.props.onMapButton();
  }

  onShareInfoCard(){
    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:'Werk: '+this.state.viewData.title,
      text:joinSingleComposerString(this.state.viewData.composer)
    };

    this.props.onShareInfoCard(sharingData)
  }

  onTogglePlayback(){
    this.setState({
      isPlaybackActive:!this.state.isPlaybackActive,
    })
  }

  onPlaybackStart(){
    console.log("onPlaybackStart in InfoCard");
    if(!this.state.isPlaybackActive) track('start-playback', {context:'IC_WORK', recordingId:decodeId(this.props.match.params.id), title:this.state.viewData.title, composer:joinSingleComposerString(this.state.viewData.composer)});

    this.setState({
      isPlaybackActive:true,
    })
  }

  onPlaybackStop(){
    this.setState({
      isPlaybackActive:false,
    })
  }

  onComposerItemSelected(id){
    let path = '/discover/composers/'+id;
    this.props.history.push(path);
  }

  onPlaylistItemSelected(id){
    alert("Playback not implemented yet: "+id);
  }

  onRecordingItemSelected(id){
    this.props.history.push('/discover/recordings/'+id);
  }

  onConcertItemSelected(id){
    this.props.history.push('/discover/concerts/'+id);
  }

  onSubViewSelected(id){
    this.setState({
      subViewId:id,
    }, function(){

      this.setMapBoundingRect();

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: '?sub='+this.state.subViewId,
      });


    })
  }

  setMapBoundingRect(){

    if(this.state.subViewId === 0 && this.props.setMapBoundingRect && this.state.recordingsBoundingRect){
      this.props.setMapBoundingRect(this.state.recordingsBoundingRect, this.state.recordingCoordinates, 'Aufnahmen', 'Werkes');
    } else if(this.state.subViewId === 1 && this.props.setMapBoundingRect && this.state.concertsBoundingRect) {
      this.props.setMapBoundingRect(this.state.concertsBoundingRect, this.state.concertCoordinates, 'Konzerte', 'Werkes');
    } else {
      this.props.setMapBoundingRect(null);
    }

  }

  renderRecordingListItems(){

    if(!this.state.viewData.recordings) return;

    let items = [];

    for(let i=0; i<this.state.viewData.recordings.length;i++){

      let pItem = this.state.viewData.recordings[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={formatRecordingItemSubtitle(pItem.composer,pItem.date)}
          onItemSelected={this.onRecordingItemSelected.bind(this)}
          lastItem={i===this.state.viewData.recordings.length-1}
          labelType={pItem.type}
        />
      )

    }

    return items;
  }

  renderConcertsListItems(){

    if(!this.state.viewData.concerts) return;

    let items = [];

    for(let i=0; i<this.state.viewData.concerts.length;i++){

      let pItem = this.state.viewData.concerts[i];

      items.push(
        <ListItemTwoLineIcon
          key={i}
          id={pItem.id}
          title={pItem.title}
          subtitle={formatConcertItemSubtitle(pItem.composers, pItem.orchestra, pItem.date)}
          onItemSelected={this.onConcertItemSelected.bind(this)}
          lastItem={i===this.state.viewData.recordings.length-1}
        />
      )

    }

    return items;

  }

  render() {

    const {t} = this.props;

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getWorksList}
          resultKeyList={'musicworks'}
          queryFunc={getWorkDetails}
          resultKey={'musicwork'}
          title={t('infocard.work')}
          loadMockData={false}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          id={this.props.match.params.id}
          total={337}
          type="IC_WORK"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
            {
              title:this.state.viewData.title,
              subtitle:joinSingleComposerString(this.state.viewData.composer),
              imageUrl:placeholderWork,
              link:this.props.location.pathname,
            } : null
          }
          isPlaybackActive={this.state.isPlaybackActive}
          onTogglePlayback={this.onTogglePlayback.bind(this)}
          placeholderImage={placeholderWork}
        >

        {this.state.viewData
          ?
          <div>

            <div style={this.styles().infoContainer}>

              <div style={this.styles().title}>
                {capitalizeTitle(this.state.viewData.title)}
              </div>

            </div>

            {this.state.viewData.composer ? 
              <ListItemArtist
              id={this.state.viewData.composer.id}
              name={joinSingleComposerString(this.state.viewData.composer)}
              imageUrl={this.state.viewData.composer.imageUrl}
              role={t('infocard.composer')}
              onItemSelected={this.onComposerItemSelected.bind(this)}
              standalone={true}
            />
            : null}

            

            {this.state.viewData.parts && this.state.viewData.parts.length > 0
              ? <div style={this.styles().programContainer}>

                  <div style={this.styles().listHeader}>
                    {t("infocard.work.parts")} • {this.state.viewData.parts.length}
                  </div>

                  <div style={this.styles().list}>
                    <PlaylistPlayer
                      playlist={this.state.viewData.parts}
                      headless={true}
                      isPlaying={this.state.isPlaybackActive}
                      onPlaybackStart={this.onPlaybackStart.bind(this)}
                      onPlaybackStop={this.onPlaybackStop.bind(this)}
                      collapseAfter={4}
                    />
                  </div>

                </div>
              : null
            }


            <div style={this.styles().toggleContainer}>
              {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
              && this.state.viewData.concerts && this.state.viewData.concerts.length > 0

                ? <ViewToggle
                activeView={this.state.subViewId}
                labelLeft={t('infocard.work.recordings')+" • "+this.state.viewData.recordings.length}
                labelRight={t('infocard.work.concerts')+" • "+this.state.viewData.concerts.length}
                onViewSelected={this.onSubViewSelected.bind(this)}
              />
                : <div>
                {this.state.viewData.recordings && this.state.viewData.recordings.length > 0
                  ? <div style={this.styles().toggleReplaceHeader}>{t('infocard.work.recordings')+" • "+this.state.viewData.recordings.length}</div>
                  : null
                }
                {this.state.viewData.concerts && this.state.viewData.concerts.length > 0
                  ? <div style={this.styles().toggleReplaceHeader}>{t('infocard.work.concerts')+" • "+this.state.viewData.concerts.length}</div>
                  : null
                }
              </div>
              }

            </div>

            <div style={this.styles().toggleListContainer}>
              {this.state.subViewId === 0
                ? this.renderRecordingListItems()
                : this.renderConcertsListItems()
              }
            </div>

          </div>
          : null
        }
        </InfoCard>
      </div>
    );
  }

  styles(){

    return {
      programContainer:{
        textAlign:'left',
        padding:'10px',
      },
      listHeader: {
        color:'#191919',
        fontWeight:'700',
        fontSize:'14px',
        padding:'20px 10px 10px 10px',
      },
      infoContainer:{
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        padding:'20px 20px 20px 20px',
        textAlign:'left',
        fontSize:'18px',
        color:'#191919',
      },
      title: {
        fontWeight:'700',
        fontSize:'20px',
        color:'#191919',
        overflow:'hidden',
      },
      toggleContainer:{
        padding:'20px 20px 20px 20px',
      },
      toggleListContainer:{
        padding:'0 10px 40px 10px',
      },
      toggleReplaceHeader:{
        fontSize:'16px',
        fontWeight:'700',
        color:'#3F3F3F',
        textAlign:'left',
      }
    }
  }
}

InfoCardWork.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  onShareInfoCard: PropTypes.func,
};

export default withTranslation()(InfoCardWork);