//import babelPolyfill from 'babel-polyfill';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import './i18n';


/*
const instance = createInstance({
    urlBase: 'https://karajan.matomo.cloud/',
    siteId: 7,
    //userId: 'UID76903202', // optional, default value: `undefined`.
    //trackerUrl: 'https://karajan.matomo.cloud/', // optional, default value: `${urlBase}matomo.php`
    srcUrl: 'https:////cdn.matomo.cloud/karajan.matomo.cloud/matomo.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },


    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
        //trackPageView: true,
        //requireConsent: true,
        //requireCookieConsent: true,
    }
})
*/

//<!-- Matomo -->
var _paq = window._paq = window._paq || [];
// tracker methods like "setCustomDimension" should be called before "trackPageView" 
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
_paq.push(['enableHeartBeatTimer']);
_paq.push(['requireConsent']);
_paq.push(['requireCookieConsent']);

(function () {
    var u = "https://karajan.matomo.cloud/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '7']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = '//cdn.matomo.cloud/karajan.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g, s);
})();
//<!-- End Matomo Code -->


ReactDOM.render(
    <App />,
    document.getElementById('root')
);

//registerServiceWorker();
