import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from './ResponsiveView';
import $ from 'jquery';

import {getCollectionDetails} from '../api/graphqlClient';
import {getLocationsList} from '../api/kapiClient';
import {setFavourite, clearFavourite, isFavourite} from '../utils/localStorageService';

import LoadingIndicator from '../components/LoadingIndicator';
import PlaylistPlayer from '../components/PlaylistPlayer';
import MapPreview from '../components/MapPreview';
import LinkCloud from '../components/LinkCloud';
import MediaGrid from '../components/MediaGrid';
import LockedLabel from '../components/LockedLabel';
import PremiumTeaser from '../components/PremiumTeaser';
import CircleFrameButtonToggle from '../components/buttons/CircleFrameButtonToggle';

import icnFavIdle from '../img/icn_ui_favourites_inactive.svg';
import icnFavActive from '../img/icn_ui_favourites_active.svg';

import icnShare from '../img/icn_share.svg';

class CollectionDetailView extends ResponsiveView {

  constructor(props){
    super(props);

    console.log("CONSTRUCTOR CollectionDetailView with id: ", this.props.match.params.id);

    this.state = {
      collectionData:null,
      isLocked:true,
      heroImageLoaded:false,
      isFavourite:isFavourite('/collections/'+this.props.match.params.id),
    };

    this.onDataReady = this.onDataReady.bind(this);
  }

  componentDidMount(){

    getCollectionDetails(this.props.match.params.id, true).then(function(result){

      if(result && result.data && result.data.collection){
        console.log("getCollectionDetails returned: ",result.data.collection);
        this.onDataReady(result.data.collection);
      } else if(result.errors) {
        console.error('There was an error for query getCollectionDetails:', result.errors[0].message);
        this.setState({isLoading:false, errorMessage:'Es ist ein Fehler aufgetreten.'});
      } else {
        console.log('getCollectionDetails did not return any results for id: ', this.props.match.params.id, result)
        this.setState({isLoading:false, errorMessage:'Die Collection konnte nicht geladen werden.'});
      }

    }.bind(this), function(error){
      console.log("There was a problem querying getCollectionDetails: ", error);
    });

    getLocationsList(false).then(function(data){
      console.log("getLocationsList in DiscoverView: ", data);
      let poiData = [];

      if(data && data.data && data.data.locations){
        data.data.locations.edges.forEach(function(el){
          poiData.push(el.node);
        })
      }

      this.setState({
        poiData:poiData
      });

      console.log('poiData: ', poiData);
    }.bind(this));
  }

  onDataReady(data){
    console.log("-> onDataReady in CollectionDetailView", data);

    let consolidatedProgram = [];

    // consolidate program parts to one program array
    if(data.program && data.program.length > 0){
      data.program.forEach(function(el){
        el.parts.forEach(function(part){
          consolidatedProgram.push(part);
        })
      })
    }


    let d = Object.assign({}, data, {program:consolidatedProgram});

    this.setState({
      collectionData:d,
    }, function () {

      $("#heroImage").one("load", function() {
        console.log("HERO IMAGE LOADED");
        this.setState({
          heroImageLoaded:true,
        })
      }.bind(this))

    })
  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onLinkTagClicked(link){
    console.log("Navigate to link: ", link);
    this.props.history.push(link);
  }

  onActivatePremium(){
    this.setState({
      isLocked:false,
    })
  }

  onFavouriteToggle(){

    this.setState({
      isFavourite:!this.state.isFavourite,
    }, function(){

      if(this.state.isFavourite){
        setFavourite('/collections/'+this.props.match.params.id, {type:'COLLECTION', data:this.getFavouriteStorageData()});
      } else {
        clearFavourite('/collections/'+this.props.match.params.id);
      }
    })
  }

  getFavouriteStorageData(){

    let text = this.state.collectionData.story.teaser;

    if(this.state.collectionData.story.teaser.length > 160){
      text = this.state.collectionData.story.teaser.substring(0,160);
      text += "...";
    }


    return {
      title:this.state.collectionData.title,
      subtitle:this.state.collectionData.subTitle,
      description:text,
      imageUrl:this.state.collectionData.imageUrl,
      link:'/collections/'+this.props.match.params.id,
    }

  }

  onShareItem(){

    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:this.state.collectionData.title,
      text:this.state.collectionData.story.teaser,
    };

    console.log("SharingData: ", sharingData);

    if(this.props.onShareRequest) this.props.onShareRequest(sharingData);

  }

  renderFooterBlocks(){

    if(!this.state.collectionData.credits || !this.state.collectionData.credits.creditItems) return false;

    let blocks = [];

    for(let i=0; i<this.state.collectionData.credits.creditItems.length; i++){

      let item = this.state.collectionData.credits.creditItems[i];

      blocks.push(
        <div style={this.styles().footerBlock} key={i}>
          <div style={this.styles().footerBlockLabel}>{item.label}</div>
          <div style={this.styles().footerBlockDescription}>
            {item.description}
          </div>
        </div>
      );

    }

    return blocks;
  }


  renderFooterLogos(){

    if(!this.state.collectionData.credits || !this.state.collectionData.credits.logoUrls) return false;

    let logos = [];

    for(let i=0; i<this.state.collectionData.credits.logoUrls.length; i++){

      let logo = this.state.collectionData.credits.logoUrls[i];

      logos.push(
        <div style={this.styles().footerLogoContainer} key={i}>
          <img src={logo} alt="" style={this.styles().logoImage}/>
        </div>
      );

    }

    return logos;
  }

  render() {

    return (

      <div style={this.styles().view}>
        {!this.state.collectionData
          ?
            <div style={this.styles().container}>
              <LoadingIndicator
                label={'Daten werden geladen'}
              />
            </div>

          :
            <div>
              <div style={this.styles().container}>

                <div style={this.styles().heroImageContainer}>
                  <img style={this.styles().heroImage} src={this.state.collectionData.imageUrl} alt="" id="heroImage"/>
                </div>

                <div style={this.styles().contentContainer}>

                  <div style={this.styles().storyTeaserContainer}>

                    <div style={this.styles().storyTeaserHeader}>
                      <div style={this.styles().storyTeaserHeadline} className="serifFont">
                        {this.state.collectionData.title}
                      </div>

                      <div style={this.styles().subTitle}>{this.state.collectionData.subTitle}</div>
                      <div style={this.styles().caption}>{this.state.collectionData.caption}</div>
                    </div>

                    <div style={this.styles().storyTeaserText} className="serifFont">

                      {this.state.collectionData.story.teaser}

                    </div>

                  </div>


                  <div style={this.styles().actionContainer}>
                    <CircleFrameButtonToggle
                      isActive={false}
                      idleBorder="#1C6ECC"
                      activeBorder="#1C6ECC"
                      iconActive={icnShare}
                      icon={icnShare}
                      onButtonClick={this.onShareItem.bind(this)}
                      width="36px"
                      height="36px"
                      margin="0 6px 0 0"
                    />

                    <CircleFrameButtonToggle
                      isActive={this.state.isFavourite}
                      idleBorder="#1C6ECC"
                      activeBorder="#1C6ECC"
                      iconActive={icnFavActive}
                      icon={icnFavIdle}
                      onButtonClick={this.onFavouriteToggle.bind(this)}
                      width="36px"
                      height="36px"
                    />
                  </div>


                  <div style={this.styles().playlistContainer}>
                    <PlaylistPlayer
                      title="Dimitri Schostakowitsch (1906-1975)"
                      subtitle="Symphonie Nr. 10 e-moll op.93"
                      playlist={this.state.collectionData.program}
                      isPreview={this.state.isLocked}
                    />
                  </div>

                </div>
              </div>

              {this.state.isLocked
                ? <div style={this.styles().premiumTeaserContainer}>
                    <PremiumTeaser
                      onActivatePremium={this.onActivatePremium.bind(this)}
                    />
                  </div>
                : null
              }


              <div style={this.styles().container}>
                <div style={this.styles().contentContainer}>

                  <div style={this.styles().twoComponentContainer}>
                    <div style={this.styles().mapPreviewContainer}>
                      <MapPreview
                        coordinates={[this.state.collectionData.location.longitude,this.state.collectionData.location.latitude]}
                        interactive={false}
                        isLocked={this.state.isLocked}
                        zoomLevel={16}
                        //poiDataUrl={'/mapdata/places.json'}
                        poiData={this.state.poiData}
                      />
                    </div>
                    <div style={this.styles().linkCloudContainer}>
                      <LinkCloud
                        linkTags={this.state.collectionData.tags}
                        onLinkClick={this.onLinkTagClicked.bind(this)}
                        isLocked={this.state.isLocked}
                      />
                    </div>
                  </div>

                  <div style={this.styles().storyFullContainer} className="serifFont">

                    {!this.state.isLocked
                      ? <div>
                          <div style={this.styles().storyFullHeadline} >
                            {this.state.collectionData.story.title}
                          </div>

                          <div style={this.styles().storyFullSubTitle}>{this.state.collectionData.story.subtitle}</div>

                          <div style={this.styles().storyFullText}>
                            {this.state.collectionData.story.fullText}
                          </div>
                        </div>
                      : <LockedLabel label={'KOMPLETTE STORY'} />
                    }
                  </div>

                </div>

                <div style={this.styles().bonusMaterialContainer}>
                  <MediaGrid
                    media={this.state.collectionData.media}
                    isLocked={this.state.isLocked}
                  />
                </div>

              </div>

              <div style={this.styles().footerContainer}>
                <div style={this.styles().footerContent}>
                  <div style={this.styles().footerColumn}>
                    <div style={this.styles().footerHeader}>Text & Credit</div>

                    {this.renderFooterBlocks()}

                  </div>
                  <div style={this.styles().footerLogoColumn}>
                    {this.renderFooterLogos()}
                  </div>

                </div>
              </div>
            </div>
        }
      </div>
    );
  }

  styles(){

    return {
      view: {
        background:'#fff',
        height:'100vh',
        position:'absolute',
        width:'100%',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
        boxSizing:'border-box',
      },
      container:{
        background:'#fff',
        color:'#191919',
        overflow:'hidden',
        padding:window.innerWidth >= 480 ? '0 40px 0px 40px' : '0 20px 00px 20px',
        maxWidth:'740px',
        width:'100%',
        margin:'0 auto',
        boxSizing:'border-box',
      },
      spinnerContainer:{
        padding:'50px 30px',
        textAlign:'center',
      },
      contentContainer: {
        maxWidth:'740px',
        width:'100%',
        margin:'0 auto',
        textAlign:'left',
      },
      heroImageContainer:{
        background:'#DAC46D',
        boxSizing:'border-box',
        overflow:'hidden',
        minWidth:'200px',
        maxWidth:'400px',
        minHeight:this.state.heroImageLoaded ? '200px' : '400px',
        maxHeight:'400px',
        width:'85%',
        borderRadius:'50%',
        margin:'30px auto 60px auto',
      },
      heroImage:{
        width:'100%',
      },
      storyTeaserContainer: {
        marginTop:window.innerWidth >= 768 ? '100px' : '50px',
      },
      storyTeaserHeader: {
        borderLeft:window.innerWidth >= 768 ? 'solid 6px #191919' : 'solid 4px #191919',
        paddingLeft:window.innerWidth >= 768 ? '46px' : '20px',
        paddingTop:window.innerWidth >= 768 ? '20px' : '10px',
        marginBottom:'60px',
      },
      storyTeaserHeadline: {
        fontSize:window.innerWidth >= 768 ? '48px' : '30px',
        lineHeight:window.innerWidth >= 768 ? '51px' : '34px',
        wordBreak:'break-word',
        fontWeight:'700',
      },
      subTitle: {
        fontSize:'16px',
        marginTop:window.innerWidth >= 768 ? '30px' : '20px',
      },
      caption: {
        fontSize:'12px',
      },
      storyTeaserText: {
        fontSize:window.innerWidth >= 768 ? '18px' : '16px',
        lineHeight:window.innerWidth >= 768 ? '31px' : '28px',
        marginBottom:'30px',
      },
      playlistContainer:{
        marginBottom:'60px',
      },
      twoComponentContainer:{
        display:window.innerWidth >= 768 ? 'flex' : 'block',
        flexDirection:'row',
        alignItems:'stretch',
        marginBottom:'60px',
      },
      mapPreviewContainer:{
        position:'relative',
        flexGrow:window.innerWidth >= 768 ? '1' : null,
        flexBasis:window.innerWidth >= 768 ? '50%' : null,
        paddingRight:window.innerWidth >= 768 ? '10px' : null,
      },
      linkCloudContainer:{
        position:'relative',
        flexGrow:window.innerWidth >= 768 ? '1' : null,
        flexBasis:window.innerWidth >= 768 ? '50%' : null,
        paddingLeft:window.innerWidth >= 768 ? '10px' : null,
        marginTop:window.innerWidth >= 768 ? null : '60px',
      },
      storyFullContainer:{
        position:'relative',
        marginBottom:'60px',
        height:this.state.isLocked ? '800px' : null,
        border:this.state.isLocked ? '1px solid #DAC46D' : null,
      },
      storyFullHeadline:{
        fontSize:window.innerWidth >= 768 ? '30px' : '24px',
        lineHeight:window.innerWidth >= 768 ? '38px' : '28px',
      },
      storyFullSubTitle:{
        fontSize:window.innerWidth >= 768 ? '16px' : '14px',
        marginTop:window.innerWidth >= 768 ? null : '8px'
      },
      storyFullText:{
        fontSize:window.innerWidth >= 768 ? '18px' : '16px',
        lineHeight:window.innerWidth >= 768 ? '31px' : '28px',
        marginTop:'20px',
        whiteSpace:'pre-line',
      },
      bonusMaterialContainer:{
        marginBottom:'60px',
      },
      footerContainer:{
        background:'#3F3F3F',
      },
      footerContent:{
        maxWidth:'740px',
        padding:window.innerWidth >= 480 ? '30px 40px' : '30px 20px',
        width:'100%',
        margin:'0 auto',
        display:window.innerWidth >= 768 ? 'flex' : 'block',
        boxSizing:'border-box',
      },
      footerColumn:{
        width:window.innerWidth >= 768 ? '50%' : '100%',
        textAlign:'left',
      },
      footerHeader:{
        fontWeight:'700',
        fontSize:'20px',
      },
      footerBlock:{
        marginTop:'16px',
      },
      footerBlockLabel:{
        fontSize:'14px',
        fontWeight:'700',
      },
      footerBlockDescription:{
        marginTop:'4px',
        fontSize:'14px',
        lineeHeight:'17px',
        color:'#999999',
        whiteSpace:'pre-line',
      },
      footerLogoColumn:{
        width:window.innerWidth >= 768 ? '50%' : '100%',
        paddingTop:window.innerWidth >= 768 ? '40px' : '20px',
        textAlign:'center',
      },
      footerLogoContainer:{
        marginBottom:window.innerWidth >= 768 ? '40px' : '0px',
      },
      logoImage:{
        transform:'scale(0.5)',
      },
      premiumTeaserContainer:{
        marginBottom:'60px',
      },
      actionContainer:{
        marginBottom:'30px',
        borderTop:'1px solid #E6E6E6',
        borderBottom:'1px solid #E6E6E6',
        padding:'10px',
        textAlign:'center',
      },
    }
  }
}

CollectionDetailView.propTypes = {
  onShareRequest:PropTypes.func,
};

export default CollectionDetailView;