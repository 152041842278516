import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../../views/ResponsiveView';

import Radium from 'radium';


class CircleButtonToggle extends ResponsiveView {

  componentDidMount(){
    this.state = {
      isActive:false,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps){

    if(nextProps.isActive === this.props.isActive)

    this.setState({
      isActive:nextProps.isActive,
    });

  }

  onViewResize(){
    return true;
  }

  onButtonClick(){

    let newButtonState = !this.props.isActive;

    if(this.props.onButtonClick) this.props.onButtonClick(newButtonState);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>

        {this.props.isActive
          ? <img style={this.styles().icon} src={this.props.iconActive} alt="icon: active state"/>
          : <img style={this.styles().icon} src={this.props.icon} alt="icon: idle state"/>
        }

      </div>
    );
  }

  styles(){


    return {

      container:{
        textAlign:'center',
        background:this.state.isActive ? this.props.activeBackground : this.props.idleBackground,
        width:this.props.width ? this.props.width : '60px',
        height:this.props.height ? this.props.height : '60px',
        borderRadius:'50%',
        cursor:'pointer',
        boxShadow:'0 1px 10px rgba(0,0,0,.3)',
        overflow:'hidden',
        /*':hover':{
          opacity:.8,
        }*/
      },
      icon:{
        margin:'50% auto 0px auto',
        transform:'translateY(-50%)',
        display:'block',    
      }



    }

  }
}

CircleButtonToggle.propTypes = {
  width:PropTypes.string,
  height:PropTypes.string,
  isActive:PropTypes.bool.isRequired,
  idleBackground:PropTypes.string.isRequired,
  activeBackground:PropTypes.string.isRequired,
  iconActive:PropTypes.string.isRequired,
  icon:PropTypes.string.isRequired,
  onButtonClick:PropTypes.func.isRequired,
}

export default Radium(CircleButtonToggle);