import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../views/ResponsiveView';

class DummyItemCluster extends ResponsiveView {


  onViewResize(){
    return true;
  }

  renderDummyItems(){

    let count = this.props.itemCount ? this.props.itemCount : 5;
    let items = [];

    for(let i=0; i<count;i++){

      items.push(
        <div style={this.styles().dummyItem} key={i}></div>
      )

    }

    return items;

  }

  render() {

    return (
      <div style={this.styles().container}>

        <div style={this.styles().headline}></div>

        {this.renderDummyItems()}

      </div>
    );
  }

  styles(){

    return {

      container:{
        position:'relative',
        width:'100%',
        marginTop:'40px',
      },
      headline: {
        background:this.props.color ? this.props.color : '#3F3F3F',
        height:'10px',
        width:'25%',
        marginBottom:window.innerWidth > 480 ? '20px' : '12px',
      },
      dummyItem:{
        background:this.props.color ? this.props.color : '#3F3F3F',
        height:window.innerWidth > 480 ? '48px' : '40px',
        width:'100%',
        marginTop:'6px',
      }

    }

  }
}

DummyItemCluster.propTypes = {
  itemCount:PropTypes.number,
  color:PropTypes.string,
}


export default DummyItemCluster;