import React from 'react';
import PropTypes from 'prop-types';

import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import SuggestionsList from './SuggestionsList';

import icnSearch from '../img/icn_search_light.svg';
import loader from '../img/loader_tail_spin.svg';
import { withTranslation } from 'react-i18next';


class MainSearchBox extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      isActive:false,
      queryString:this.props.defaultQuery ? this.props.defaultQuery : '',
      suggestionQuery:'',
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.props = newProps;

    this.setState({
      queryString:this.props.defaultQuery ? this.props.defaultQuery : '',
    });
  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onSearchFocus(){
    this.setState({
      isActive:true,
    });
  }

  onSearchInput(e){
    this.setState({
      queryString:e.target.value,
      suggestionQuery:e.target.value
    });
  }


  onSearchBlur(){
    this.setState({
      isActive:false,
    });
  }

  onKeyDown(event){

    if(event.keyCode === 13 && this.state.queryString.length > 1){
      this.setState({
        suggestionQuery:'',
      });
      this.startSearch();
    }
  }

  onSuggestionSelected(suggestion){

    this.setState({
      queryString:suggestion,
      suggestionQuery:'',
    }, function(){
      this.startSearch();
    })

  }

  startSearch(){
    if(!this.props.onStartSearch) console.warn("onStartSearch not defined in MainSearchBox");

    if(this.state.queryString.length > 0) this.props.onStartSearch(this.state.queryString)

  }




  render() {

    const { t } = this.props;

    let containerStyle = this.state.isActive ? this.styles().container : this.styles().container;
    let labelStyle = this.state.isActive || this.state.queryString.length > 0 ? this.styles().actionLabelActive : this.styles().actionLabel;
    let placeholder = window.innerWidth >= 768 ? t('searchbox.placeholderLong') : t('searchbox.placeholderShort');

    return (
      <div>
        <div style={containerStyle}>
          <div style={this.styles().inputContainer}>
            {!this.props.searchActive
              ? <div style={labelStyle}>{t('searchbox.title')}</div>
              : <div style={this.styles().spinnerContainer}>
                  <img src={loader} alt="" style={this.styles().loaderIcon}/>
                </div>
            }
            <input type="text"
                   style={this.styles().inputField}
                   placeholder={placeholder}
                   value={this.state.queryString}
                   onClick={(e)=>e.stopPropagation()}
                   onChange={this.onSearchInput.bind(this)}
                   onFocus={this.onSearchFocus.bind(this)}
                   onBlur={this.onSearchBlur.bind(this)}
                   onKeyDown={this.onKeyDown.bind(this)}/>
          </div>
          <div style={this.styles().searchButton} onClick={this.startSearch.bind(this)}>
            <img src={icnSearch} alt="Search" />
          </div>

        </div>
        {this.props.displaySuggestions
          ? <SuggestionsList
              query={this.state.suggestionQuery}
              endpoint={'/search/suggestions/'}
              onSuggestionSelected={this.onSuggestionSelected.bind(this)}
            />
          : null
        }

      </div>
    );
  }



  styles(){

    return {

      container: {
        maxWidth:'480px',
        height:'48px',
        color:'#191919',
        lineHeight:'48px',
        textAlign:'left',
        cursor:'pointer',
        display:'flex',
      },
      inputContainer:{
        //width:'400px',
        background:'#fff',
        display:'flex',
        paddingLeft:window.innerWidth >= 768 ? '15px' : '10px',
        borderTopLeftRadius:'3px',
        borderBottomLeftRadius:'3px',
        boxSizing:'border-box',
        flex: '0 1 400px',
      },
      actionLabel: {
        display:window.innerWidth < 480 ? 'none' : 'inline-block',
        fontWeight:'900',
        fontSize:'16px',
        display:'inline-block',
        marginRight:'10px',
        verticalAlign:'top',
        //marginLeft:'20px',

      },
      actionLabelActive: {
        display:window.innerWidth < 480 ? 'none' : 'inline-block',
        fontWeight:'900',
        fontSize:'16px',
        marginRight:'10px',
        color:'#1C6ECC',
        verticalAlign:'top',
        //marginLeft:'20px',
      },
      inputField: {
        border:'none',
        height:'48px',
        lineHeight:'48px',
        fontSize:'16px',
        padding:'0',
        //width:'329px',
        outline:'none',
        verticalAlign:'top',
        flex: '0 1 100%',
      },
      searchButton:{
        width:'80px',
        height:'48px',
        lineHeight:'48px',
        background:'#1C6ECC',
        display:'inline-block',
        color:'#fff',
        textAlign:'center',
        verticalAlign:'top',
        paddingTop:'8px',
        borderTopRightRadius:'3px',
        borderBottomRightRadius:'3px',
        boxSizing:'border-box',
        ':hover':{
          opacity:'.8',
        }

      },
      spinnerContainer:{
        padding:'7px 22px 0 1px',
      },
      loaderIcon:{
        width:'28px'
      },

    }
  }
}

MainSearchBox.propTypes = {
  onStartSearch:PropTypes.func.isRequired,
  defaultQuery:PropTypes.string,
  searchActive:PropTypes.bool,
  displaySuggestions:PropTypes.bool
};

export default withTranslation()(Radium(MainSearchBox));
