import {executeQuery} from '../graphqlClient';

export const getStoriesList = function(amount, cursor){

  let cacheId = 'getStoriesList';
  let variableValues = {first:amount};
  if(cursor) {
    variableValues.after = cursor;
    cacheId = cacheId+cursor;
  }

  let query = `
    query getStoriesList($first: Int, $after: String){
      stories (first: $first, after: $after){
        edges {
          node {
            id
            title
            subtitle
            teaser
            fullText
            imageUrl
            releaseDate {
              day
              month
              year
            }
          },
          cursor
        }
        pageInfo{
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  `;

  return executeQuery(query, variableValues, cacheId, false);
};

export const getStoriesIndices = function(amount){

  let cacheId = 'getStoriesIndices';
  let variableValues = {first:amount};

  let query = `
    query getStoriesIndices($first: Int, $after: String){
      stories (first: $first, after: $after){
        edges {
          cursor
        }
      }
    }
  `;

  return executeQuery(query, variableValues, cacheId, false);
};
