import React from 'react';
import ResponsiveView from '../../views/ResponsiveView';

import Radium from 'radium';


class BigButtonRoundToggle extends ResponsiveView {

  componentDidMount(){
    this.state = {
      isActive:false,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps){

    //console.log("BigButtonRoundToggle -> componentWillReceiveProps: ", nextProps);

    if(nextProps.isActive === this.props.isActive)

      this.setState({
        isActive:nextProps.isActive,
      });

  }

  onViewResize(){
    return true;
  }

  onButtonClick(){

    let newButtonState = !this.props.isActive;

    console.log("BigButtonRoundToggle -> newButtonState: ", newButtonState);

    if(this.props.onButtonClick) this.props.onButtonClick(newButtonState);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>

        {this.props.isActive
          ? <span>{this.props.activeLabel}</span>
          : <span>{this.props.label}</span>
        }

      </div>
    );
  }

  styles(){

    return {

      container:{
        textAlign:'center',
        background:this.props.background,
        color:this.props.labelColor,
        fontSize:'14px',
        fontWeight:'700',
        height:'48px',
        borderRadius:'24px',
        padding:'0 30px',
        lineHeight:'48px',
        display:'inline-block',
        cursor:'pointer',
        minWidth:this.props.minWidth ? this.props.minWidth : null,
        boxShadow:'0 1px 3px rgba(0,0,0,.3)',
        ':hover':{
          opacity:.8,
        }
      },

    }

  }
}

export default Radium(BigButtonRoundToggle);