export const tags =
[
  {
    id:1,
    link:"/discover/locations/1",
    label:"MOZARTEUM"
  },
  {
    id:2,
    link:"/discover/concerts/1",
    label:"KONZERTPROGRAMM"
  }
];
