import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import {getComposersList} from '../../api/kapiClient';
import {getComposerDetails} from '../../api/kapiClient';

import {getFormattedLifeDates, joinSingleComposerString, getFormattedDate, decodeId} from '../../utils/helpers';
import {track} from '../../utils/trackingService';

import InfoCard from './InfoCard';

import ListItemWork from '../../components/ListItemWork';
import WorkTypeTag from '../../components/WorkTypeTag';

import { WithTranslation, withTranslation } from 'react-i18next';


class InfoCardComposer extends ResponsiveView {

  constructor(props){
    super(props, true);

    this.state = {
      viewData:null,
      subViewId:0,
      selectedWorkTag:'',
    };

    this.onDataReady = this.onDataReady.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps){

    if(this.props.match.params.id != nextProps.match.params.id){
      this.props = nextProps;
    }
  }

  onDataReady(data){
    console.log("-> onDataReady ", data);

    this.setState({
      viewData:data,
    });

    track('load-page', {pageId:'InfoCard', category:'Komponist', id:decodeId(this.props.match.params.id), name:joinSingleComposerString(data)});

    if(this.props.onDataReady) this.props.onDataReady(data);
  }

  /*
  DEPRECATED
  generateDescriptionText(){

    let composerName = joinSingleComposerString(this.state.viewData);

    let noOfConcerts = this.state.viewData.karajanNumberOfPerformedComposerConcerts;
    let noOfWorksInConcerts = this.state.viewData.karajanNumberOfPerformedWorksByComposerInConcerts;

    let firstConcert = this.state.viewData.karajanFirstConcertPerformanceComposer ? this.state.viewData.karajanFirstConcertPerformanceComposer.date : null;
    let firstRecording = this.state.viewData.karajanFirstRecordedPerformanceComposer ? this.state.viewData.karajanFirstRecordedPerformanceComposer.date : null;

    let description = "";

    if(firstRecording){
      description += "Herbert von Karajan nahm ";
      description += firstRecording.day ? "am " : "im ";
      description += getFormattedDate(firstRecording) + " zum ersten Mal ein Werk von " + composerName + " auf. ";
    } else if(firstConcert){
      description += "Herbert von Karajan dirigierte ";
      description += firstConcert.day ? "am " : "im ";
      description += getFormattedDate(firstConcert) + " zum ersten Mal ein Werk von " + composerName + " auf. ";
    }

    if(noOfConcerts && noOfWorksInConcerts){
      description += "Er spielte in ";
      description += (noOfConcerts === 1 ? "einem Konzert " : noOfConcerts+" Konzerten ");
      description += (noOfWorksInConcerts === 1 ? "ein Werk" : noOfWorksInConcerts+" Werke ")+" des Komponisten.";
    }

    return description;
  }
  */


  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onNavigateTo(path){
    console.log("onNavigateTo: ", path)
    this.props.history.push(path);
  }


  onBackButton(){
    this.props.history.goBack();
  }

  onMapButton(){
    this.props.onMapButton();
  }

  onShareInfoCard(){
    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:joinSingleComposerString(this.state.viewData),
      text:this.state.viewData.description,
    };

    this.props.onShareInfoCard(sharingData)
  }

  onWorkItemSelected(id){
    let path = '/discover/works/'+id;
    this.props.history.push(path);
  }

  onTypeTagSelected(id){
    this.setState({
      selectedWorkTag:id,
    })
  }

  onClearSelectedTag(){
    this.setState({
      selectedWorkTag:'',
    })
  }

  renderWorkListItems(){

    if(!this.state.viewData.works) return;

    let items = [];

    // only show works that have been recorded OR performed by Karajan
    let filteredWorks = this.state.viewData.works.filter ( (el) => {
      return el.concerts.length > 0 || el.recordings.length > 0;
    });

    if(this.state.selectedWorkTag.length > 0){

      //filteredWorks = this.state.viewData.works.filter(function(el){
      filteredWorks = filteredWorks.filter(function(el){

        console.log(el);

        return el.type === this.state.selectedWorkTag;

      }.bind(this))

    }

    for(let i=0; i<filteredWorks.length;i++){

      let wItem = filteredWorks[i];

      items.push(
        <ListItemWork
          key={i}
          id={wItem.id}
          title={wItem.title}
          recordings={wItem.recordings.length}
          concerts={wItem.concerts.length}
          type={wItem.type ? wItem.type : ""}
          onItemSelected={this.onWorkItemSelected.bind(this)}
          onTagSelected={this.onTypeTagSelected.bind(this)}
        />
      )

    }

    return items;
  }

  render() {

    console.log("Render InfoCardComposer with viewData: ", this.state.viewData);
    const {t} = this.props;

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getComposersList}
          resultKeyList={'persons'}
          queryFunc={getComposerDetails}
          loadMockData={false}
          resultKey={'person'}
          title={t('infocard.composer')}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          id={this.props.match.params.id}
          total={337}
          type="IC_COMPOSER"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
            {
              title:joinSingleComposerString(this.state.viewData),
              subtitle:getFormattedLifeDates(this.state.viewData.birthDate, this.state.viewData.deathDate, true),
              imageUrl:this.state.viewData.imageUrl,
              link:this.props.location.pathname,
            } : null
          }
        >

        {this.state.viewData
          ?
          <div style={this.styles().container}>
                <div>
                  <div style={this.styles().infoContainer}>

                    <div style={this.styles().name}>
                      {joinSingleComposerString(this.state.viewData)}
                    </div>

                    <div style={this.styles().subTitle}>
                      {getFormattedLifeDates(this.state.viewData.birthDate, this.state.viewData.deathDate, true)}
                    </div>

                    <div style={this.styles().description}>
                      <div style={this.styles().generatedDescription}>{this.state.viewData.karajanCredits}</div>
                      {this.state.viewData.description}
                    </div>

                  </div>
                  {this.state.viewData.works && this.state.viewData.works.length > 0
                    ?
                    <div style={this.styles().workListContainer}>

                      <div style={this.styles().listHeader}>{t('infocard.composer.works')} • {this.state.viewData.works.length}


                      <div style={this.styles().selectedTypeTagContainer}>
                        <WorkTypeTag
                          isActive={true}
                          label={this.state.selectedWorkTag.toUpperCase()}
                          background="#1C6ECC"
                          onButtonClick={this.onClearSelectedTag.bind(this)}
                        />
                      </div>
                      </div>

                      <div style={this.styles().list}>
                        {this.renderWorkListItems()}
                      </div>

                    </div>
                  : null
                }
                </div>
            </div>
          : null
        }
        </InfoCard>
      </div>
    );
  }

  styles(){

    return {
      container:{
        boxSizing:'border-box',
        background:'#fff',
        color:'#131313',
        overflow:'hidden',
        width:'100%',
      },
      infoContainer:{
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        padding:'20px 20px 20px 20px',
        textAlign:'left',
        fontSize:'18px',
        color:'#191919',
      },
      name: {
        fontWeight:'700',
        fontSize:'20px',
        color:'#191919',
        overflow:'hidden',
      },
      subTitle:{
        //color:'#3F3F3F',
        fontSize:'16px',
      },
      description:{
        fontSize:'16px',
        lineHeight:'21px',
        color:'#3F3F3F',
        marginTop:'15px',
        marginBottom:'10px',
      },
      generatedDescription:{
        marginBottom:'16px',
      },
      workListContainer:{
        textAlign:'left',
        padding:'10px 10px 30px 10px',
      },
      listHeader: {
        color:'#191919',
        fontWeight:'700',
        fontSize:'14px',
        padding:'0px 10px 10px 10px',
        position:'relative',
      },
      selectedTypeTagContainer:{
        position:'absolute',
        bottom:'10px',
        right:'10px',
      }
    }

  }
}

InfoCardComposer.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  onShareInfoCard: PropTypes.func,
};

export default withTranslation()(InfoCardComposer);