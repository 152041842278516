import React from 'react';
import ResponsiveView from './ResponsiveView';
import CollectionPreviewItem from '../components/CollectionPreviewItem';

import {getCollectionsList} from '../api/graphqlClient';

import LoadingIndicator from '../components/LoadingIndicator';

import icnCollections from '../img/icn_collections_big.svg';


class CollectionListView extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      collectionListData:null,
    };

    this.onDataReady = this.onDataReady.bind(this);
  }

  componentDidMount(){

    getCollectionsList(true).then(function(result){

      if(result && result.data && result.data.collections){
        console.log("getCollectionsList returned: ",result.data.collections);
        this.onDataReady(result.data.collections);
      } else if(result.errors) {
        console.error('There was an error for query getCollectionsList:', result.errors[0].message);
        this.setState({isLoading:false, errorMessage:'Es ist ein Fehler aufgetreten.'});
      } else {
        console.log('getCollectionsList did not return any results for id: ', this.props.match.params.id, result)
        this.setState({isLoading:false, errorMessage:'Die Collections konnten nicht geladen werden.'});
      }

    }.bind(this), function(error){
      console.log("There was a problem querying getArtistDetails: ", error);
    }.bind(this));

  }

  onDataReady(data){
    console.log("-> onDataReady ", data);

    this.setState({
      collectionListData:data,
    })
  }

  onViewResize(){

  }


  renderPrewievItems(){

    let items = [];

    for(let i=0; i<this.state.collectionListData.length;i++){

      let item = (<CollectionPreviewItem
                  path={"/collections/"+this.state.collectionListData[i].id}
                  key={i}
                  title={this.state.collectionListData[i].title}
                  subtitle={this.state.collectionListData[i].subtitle}
                  caption={this.state.collectionListData[i].caption}
                  imageUrl={this.state.collectionListData[i].imageUrl}
                  />);

      items.push(item);

    }

    return items;
  }

  render() {

    return (
      <div style={this.styles().view}>

          <div style={this.styles().container}>

            <div style={this.styles().contentContainer}>

              <div style={this.styles().pageIconContainer}>
                <img src={icnCollections} alt="Icon: Collections" style={this.styles().pageIcon}/>
              </div>
              <div style={this.styles().pageTitle}>
                Collections
              </div>

              {!this.state.collectionListData
                ? <LoadingIndicator
                    label={'Liste wird geladen'}
                  />
                : this.renderPrewievItems()
              }
            </div>

          </div>
        }
      </div>
    );
  }

  styles(){

    return {
      container:{
        background:'#fff',
        color:'#131313',
        overflow:'hidden',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
        paddingBottom:'100px',
      },
      spinnerContainer:{
        padding:'50px 20px',
        textAlign:'center',
      },
      contentContainer: {
        maxWidth:'860px',
        margin:'0 auto',
        //padding:window.innerWidth >= 1024 ? '0 0' : '0 20px',
      },
      pageTitle:{
        fontSize:'28px',
        fontWeight: '900',
        paddingBottom:'15px',
      },
      pageIconContainer: {
        marginTop:'40px',
        minHeight:'48px',
      },
      pageIcon:{
        margin:'0 auto',
        display:'block',
      },
    }

  }
}

export default CollectionListView;