import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import {getEventsList} from '../../api/kapiClient';
import {getEventDetails} from '../../api/kapiClient';

import {getFormattedDate, decodeId, convertInfoCardLink} from '../../utils/helpers';
import {track} from '../../utils/trackingService';

import InfoCard from './InfoCard';
import LinkTag from '../../components/LinkTag';


class InfoCardEvent extends ResponsiveView {

  constructor(props){
    super(props, true);

    this.state = {
      viewData:null,
      subViewId:0,
      navIndex:1,
      listData:null,
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps){

    if(this.props.match.params.id !== nextProps.match.params.id){
      this.props = nextProps;
    }
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.props.setPoiHighlights) this.props.setPoiHighlights(null);
  }

  onDataReady(data){

    this.setState({
      viewData:data,
    });

    if(data){
      if(this.props.onDataReady) this.props.onDataReady(data);
      if(this.props.setPoiHighlights && data.location && data.location.longitude) this.props.setPoiHighlights([ [data.location.longitude, data.location.latitude] ]);

      track('load-page', {pageId:'InfoCard', category:'Ereignis', id:decodeId(this.props.match.params.id), title:data.title});
    }
  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onNavigateTo(path){
    console.log("onNavigateTo: ", path)
    this.props.history.push(path);
  }

  onBackButton(){
    this.props.history.goBack();
  }

  onMapButton(){
    this.props.onMapButton();
  }

  onShareInfoCard(){
    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:this.state.viewData.title,
      text:this.state.viewData.description,
    };

    this.props.onShareInfoCard(sharingData)
  }

  onLinkClick(link){
    console.log("ON link click: ", link);
    this.props.history.push(link);
  }

  renderTags(){

    if(!this.state.viewData.tags) return;

    let items = [];

    for(let i=0; i<this.state.viewData.tags.length;i++){

      let item = this.state.viewData.tags[i];

      items.push(
        <LinkTag
          key={i}
          label={item.label}
          link={convertInfoCardLink(item.link)}
          color="#1C6ECC"
          onLinkClick={this.onLinkClick.bind(this)}
        />
      )
    }

    return items;

  }

  render() {

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getEventsList}
          resultKeyList={'events'}
          queryFunc={getEventDetails}
          resultKey={'event'}
          loadMockData={false}
          title={'Ereignis'}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          //id={parseInt(this.props.match.params.id)}
          id={this.props.match.params.id}
          total={337}
          type="IC_EVENT"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
            {
              title:this.state.viewData.title,
              subtitle:getFormattedDate(this.state.viewData.date),
              imageUrl:this.state.viewData.imageUrl,
              link:this.props.location.pathname
            } : null
          }
        >

        {this.state.viewData
          ?
            <div>

              <div style={this.styles().infoContainer}>

                <div style={this.styles().name}>
                  {this.state.viewData.title}
                </div>

                <div style={this.styles().subTitle}>
                  {getFormattedDate(this.state.viewData.date)}
                </div>

                <div style={this.styles().description}>
                  {this.state.viewData.description}
                </div>

              </div>

              {this.state.viewData.tags && this.state.viewData.tags.length > 0
                ? <div style={this.styles().tagsSectionContainer}>

                    <div style={this.styles().listHeader}>
                      TAGS • {this.state.viewData.tags.length}
                    </div>
                    <div style={this.styles().tagsContainer}>
                      {this.renderTags()}
                    </div>
                  </div>
                : null
              }

            </div>

          : null
        }
        </InfoCard>
      </div>
    );
  }

  styles(){

    return {
      infoContainer:{
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        padding:'20px 20px 30px 20px',
        textAlign:'left',
        fontSize:'18px',
        color:'#191919',
      },
      name: {
        fontWeight:'700',
        fontSize:'20px',
        color:'#191919',
        overflow:'hidden',
      },
      subTitle:{
        //color:'#3F3F3F',
        fontSize:'16px',
      },
      description:{
        fontSize:'16px',
        lineHeight:'21px',
        color:'#3F3F3F',
        marginTop:'15px'
      },
      tagsSectionContainer:{
        textAlign:'left',
        padding:'0 20px 20px 20px',
      },
      listHeader: {
        color:'#191919',
        fontWeight:'700',
        fontSize:'14px',
        paddingBottom:'10px',
      },
      tagsContainer:{
        display:'flex',
        flexWrap:'wrap',
      }
    }

  }
}

InfoCardEvent.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  onShareInfoCard: PropTypes.func,
  setPoiHighlights: PropTypes.func,
};

export default InfoCardEvent;