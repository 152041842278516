import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../views/ResponsiveView';
import LinkTag from './LinkTag';
import LockedLabel from './LockedLabel';

class LinkCloud extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onLinkClick(link){
    this.props.onLinkClick(link)
  }

  renderLinkTags(){

    let tags = [];

    for(let i=0;i<this.props.linkTags.length;i++){
      tags.push( <LinkTag color='#1C6ECC'
                          label={this.props.linkTags[i].label}
                          link={this.props.linkTags[i].link}
                          key={i}
                          onLinkClick={this.onLinkClick.bind(this)}
                  />)
    }

    return tags;

  }

  render() {
    return (

        <div style={this.styles().contentContainer}>

          {!this.props.isLocked
            ? <div>
                <div style={this.styles().headline}> WEITERFÜHRENDE LINKS</div>

                <div style={this.styles().linkContainer}>

                  {this.renderLinkTags()}

                </div>
              </div>
            : <LockedLabel label="LINKS"/>
          }
        </div>

    );
  }

  styles(){

    return {

      contentContainer:{
        //background:'#ff0',
        height:'100%',
        position:'relative',
        minHeight:this.props.isLocked ? '340px' : null,
        //paddingLeft:'10px',
        border:this.props.isLocked ? '1px solid #DAC46D' : null,
      },
      headline: {
        fontSize:'16px',
        fontWeight:'700',
      },
      linkContainer: {
        marginTop:'10px',
        display:'flex',
        flexWrap:'wrap',
      }

    }

  }
}

LinkCloud.propTypes = {
  linkTags:PropTypes.array.isRequired,
  onLinkClick:PropTypes.func.isRequired,
  isLocked:PropTypes.bool,
};

export default LinkCloud;