import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../ResponsiveView';

import {getRecordingsList} from '../../api/kapiClient';
import {getRecordingDetails} from '../../api/kapiClient';

import {getFormattedDate, joinSingleComposerString, decodeId} from '../../utils/helpers';
import {track} from '../../utils/trackingService';

import InfoCard from './InfoCard';

import ListItemInfoCard from '../../components/ListItemInfoCard';
import ArtistListDropdown from '../../components/ArtistListDropdown';
import RecordingTypeLabel from '../../components/RecordingTypeLabel';
import PlaylistPlayer from '../../components/PlaylistPlayer';

import icnNoimageCover from '../../img/icn_noimage_coverart.svg';

import { withTranslation } from 'react-i18next';

class InfoCardRecording extends ResponsiveView {

  constructor(props){
    super(props, true);

    this.state = {
      viewData:null,
      isPlaybackActive:false,
      productImagesError:[],
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps in InfoCardRecording: ", nextProps);

    if(this.props.match.params.id != nextProps.match.params.id){
      this.props = nextProps;
    }
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.props.setPoiHighlights) this.props.setPoiHighlights(null);
  }

  onDataReady(data){
    console.log("-> onDataReady ", data, this.props);

    let consolidatedProgram = [];

    // consolidate program parts to one program array
    if(data.program && data.program.length > 0){
      data.program.forEach(function(el){
        el.parts.forEach(function(part){
          consolidatedProgram.push(part);
        })
      })
    }


    let d = Object.assign({}, data, {program:consolidatedProgram});
    let productImagesError = [];

    if(d.products && d.products.length > 0){
      d.products.forEach(function(el){
        productImagesError.push(false);
      })
    }


    this.setState({
      viewData:d,
      productImagesError:productImagesError,
    });

    if(data){
      if(this.props.onDataReady) this.props.onDataReady(data);
      if(this.props.setPoiHighlights && data.location && data.location.longitude) this.props.setPoiHighlights([ [data.location.longitude, data.location.latitude] ]);

      track('load-page', {pageId:'InfoCard', category:'Aufnahme', id:decodeId(this.props.match.params.id), title:data.title, composer:joinSingleComposerString(data.musicWork.composer)});
    }
  }

  onViewResize(){
    this.setState({
      resize:true,
    })
  }

  onNavigateTo(path){
    console.log("onNavigateTo: ", path)
    this.props.history.push(path);
  }

  onBackButton(){
    this.props.history.goBack();
  }

  onMapButton(){
    this.props.onMapButton();
  }

  onShareInfoCard(){
    let sharingData = {
      url:window.location.origin+'/#'+this.props.location.pathname,
      title:'Aufnahme: '+this.state.viewData.title,
      text:joinSingleComposerString(this.state.viewData.composer)
    };

    this.props.onShareInfoCard(sharingData)
  }

  onTogglePlayback(){
    this.setState({
      isPlaybackActive:!this.state.isPlaybackActive,
    })
  }

  onPlaybackStart(){
    console.log("onPlaybackStart in InfoCard");

    if(!this.state.isPlaybackActive) track('start-playback', {context:'IC_RECORDING', recordingId:decodeId(this.props.match.params.id), title:this.state.viewData.title, composer:joinSingleComposerString(this.state.viewData.musicWork.composer)});

    this.setState({
      isPlaybackActive:true,
    })
  }

  onPlaybackStop(){
    this.setState({
      isPlaybackActive:false,
    })
  }

  onArtistItemSelected(id){
    let path = '/discover/artists/'+id;
    this.props.history.push(path);
  }

  onPlaylistItemSelected(id){
    alert("Playback not implemented yet: "+id);
  }


  navigateToProduct(shopUrl){

    track('open-product-link', {shopUrl:shopUrl, recordingId:decodeId(this.props.match.params.id), title:this.state.viewData.title, composer:joinSingleComposerString(this.state.viewData.musicWork.composer)});

    window.open(shopUrl, '_blank');
  }

  onProductImageError(i, e){
    //console.log("onImageError: ", i, this.state.productImagesError);

    let pImages = this.state.productImagesError.slice();
    pImages[i] = true;

    this.setState({
      productImagesError:pImages,
    });
  }

  renderProductItems(){


    if(!this.state.viewData.products) return;

    let items = [];

    for(let i=0; i<this.state.viewData.products.length;i++){

      let pItem = this.state.viewData.products[i];

      if(pItem.shopUrl){
        items.push(
          <div key={i} style={this.styles().cover} onClick={this.navigateToProduct.bind(this, pItem.shopUrl)}>
            {this.state.productImagesError[i] === false
              ? <img src={pItem.imageUrl} alt="" style={this.styles().coverAsset} onError={this.onProductImageError.bind(this, i)}/>
              : <div style={this.styles().coverPlaceholder}>
                  <img src={icnNoimageCover} alt="" style={this.styles().coverPlaceholderImage}/>
                </div>
            }

          </div>
        )
      }

    }

    return items;

  }

  render() {
    
    const {t} = this.props;

    return (
      <div style={this.styles().view}>

        <InfoCard
          queryFuncList={getRecordingsList}
          resultKeyList={'recordings'}
          queryFunc={getRecordingDetails}
          resultKey={'recording'}
          title={t('infocard.recording')}
          loadMockData={false}
          onMapButton={this.props.onMapButton}
          onDataReady={this.onDataReady.bind(this)}
          pathname={this.props.location.pathname}
          id={this.props.match.params.id}
          total={337}
          type="IC_RECORDING"
          onNavigateTo={this.onNavigateTo.bind(this)}
          onBackButton={this.onBackButton.bind(this)}
          onShareInfoCard={this.onShareInfoCard.bind(this)}
          favouriteStorageData={
            this.state.viewData ?
            {
              title:this.state.viewData.title,
              subtitle:getFormattedDate(this.state.viewData.date),
              imageUrl:this.state.viewData.imageUrl,
              link:this.props.location.pathname,
            } : null
          }
          isPlaybackActive={this.state.isPlaybackActive}
          onTogglePlayback={this.onTogglePlayback.bind(this)}
        >

        {this.state.viewData
          ?
          <div>

            <ListItemInfoCard
              id={this.state.viewData.id}
              title={this.state.viewData.title}
              subtitle={joinSingleComposerString(this.state.viewData.musicWork.composer)}
              kwvTitle={"KWV: "+this.state.viewData.kwv}
              caption={this.state.viewData.orchestra ? this.state.viewData.orchestra.name : ''}
              dateString={getFormattedDate(this.state.viewData.date)}
              wrapTitle={true}
              label={this.state.viewData.type}
            />

            {this.state.viewData.artists && this.state.viewData.artists.length > 1
              ? <ArtistListDropdown
                  label={t('discover.artists')+" ("+this.state.viewData.artists.length+")"}
                  artists={this.state.viewData.artists}
                  onItemSelected={this.onArtistItemSelected.bind(this)}
                />
              : null
            }
            {this.state.viewData.artists && this.state.viewData.artists.length == 1
              ? <ArtistListDropdown
                  label={t('infocard.artist')+" ("+this.state.viewData.artists.length+")"}
                  artists={this.state.viewData.artists}
                  onItemSelected={this.onArtistItemSelected.bind(this)}
                />
              : null
            }

            {this.state.viewData.musicWork && this.state.viewData.musicWork.parts.length > 0
              ? <div style={this.styles().programContainer}>

                  <div style={this.styles().listHeader}>
                    {t('infocard.recording.parts')} • {this.state.viewData.musicWork.parts.length}
                  </div>

                  <div style={this.styles().list}>
                    <PlaylistPlayer
                      playlist={this.state.viewData.musicWork.parts}
                      headless={true}
                      isPlaying={this.state.isPlaybackActive}
                      onPlaybackStart={this.onPlaybackStart.bind(this)}
                      onPlaybackStop={this.onPlaybackStop.bind(this)}
                      collapseAfter={4}
                    />
                  </div>
                </div>
              : null
            }

            {this.state.viewData.products && this.state.viewData.products.length > 0
              ? <div style={this.styles().programContainer}>

                  <div style={this.styles().listHeader}>
                    ERSCHIENEN AUF
                  </div>


                  <div style={this.styles().coverGridContainer}>
                    {this.renderProductItems()}
                  </div>

                </div>
              : null
            }

          </div>

          : null
        }
        </InfoCard>
      </div>
    );
  }

  styles(){

    return {
      programContainer:{
        textAlign:'left',
        padding:'10px',
      },
      listHeader: {
        color:'#191919',
        fontWeight:'700',
        fontSize:'14px',
        padding:'20px 10px 10px 10px',
      },
      recordingType:{
        float:'right',
      },
      coverGridContainer:{
        display:'flex',
        flexWrap:'wrap',
        padding: window.innerWidth >= 480 ? null : '5px',
      },
      cover:{
        flex:'0 1 50%',
        minWidth:'50%',
        padding: window.innerWidth >= 480 ? '10px' : '5px',
        cursor:'pointer',
        position:'relative',
      },
      coverAsset:{
        width:'100%',
        display:'block',
        //border:'1px solid #f0f0f0',
        position:'relative',
        minHeight:'100px',
      },
      coverPlaceholder:{
        width:'100%',
        display:'block',
        border:'1px solid #f0f0f0',
        minHeight:'100%',
        //background:'#f4f4f4'
        position:'relative',
      },
      coverPlaceholderImage:{
        position:'relative',
        top:'0',
        bottom:'0',
        margin:'auto',
        maxWidth:'100%',
      }
    }

  }
}

InfoCardRecording.propTypes = {
  onMapButton: PropTypes.func.isRequired,
  onDataReady: PropTypes.func,
  onShareInfoCard: PropTypes.func,
  setPoiHighlights: PropTypes.func,
};

export default withTranslation()(InfoCardRecording);