import { makeExecutableSchema, addMockFunctionsToSchema } from 'graphql-tools';
import { graphql } from 'graphql';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';

import $ from 'jquery';

import {typeDefs} from './schema';
import {resolvers as mockResolvers} from './mock/resolvers';

import {storeApiResultInCache, isApiCacheExpired, getApiResultFromCache} from './caching';


// Make a GraphQL schema with no resolvers
export const schema = makeExecutableSchema({typeDefs:typeDefs, resolvers:mockResolvers});
// Add mocks, modifies schema in place
//addMockFunctionsToSchema({schema:schema, preserveResolvers:true});

const client = new ApolloClient({
  link: new HttpLink({ uri: process.env.REACT_APP_API_URL}),
  cache: new InMemoryCache(),
});

export const executeQuery = function(query, variableValues, name, isMock){

  let promise;
  let myQuery = gql(query);

  if(isMock){
    promise = graphql(schema, query, null, null, variableValues);
  } else {

    if(isApiCacheExpired(name)){
      console.log("Load data from api with url: ",process.env.REACT_APP_API_URL);
      console.log("name: ", name);
      console.log("variableValues: ", variableValues);

      promise = client.query({ query: myQuery, variables:variableValues});

      promise.then(function(data){
        storeApiResultInCache(name, data);
      });

    } else {
      console.log("Load data from cache for endpoint: ", name);
      let cachedData = getApiResultFromCache(name);
      console.log("cachedData: ", cachedData);
      var dfd = $.Deferred();
      promise = dfd.promise();
      dfd.resolve(cachedData);
    }

  }

  promise.then(function(data){
    if(isMock) console.log('** MOCK **');
    console.log('Response to query with name: ', name , variableValues,  data.data);

  });

  return promise;
};


// DASHBOARD

// TODO: Add collections and recommendations once available
export const getDashboardData = function(storiesAmount){

  let variableValues = {storiesAmount:storiesAmount};

  let query = `
    query getDashboardData($storiesAmount: Int){
      stories (first: $storiesAmount){
        edges {
          node {
            id
            title
            subtitle
            teaser
            fullText
            imageUrl
            releaseDate {
              day
              month
              year
            }
          },
          cursor
        }
      }
    }
  `;


  return executeQuery(query, variableValues, 'getDashboardData', false);

};


export const getDashboardDataWithCollections = function(isMock){

  let variableValues = {maxCount:1, maxRecommendations:4};

  let query = `
    query getDashboardData($maxCount: Int, $maxRecommendations: Int) {
     stories(maxCount: $maxCount) {
        id
        title
	      subtitle
        teaser
        fullText
        imageUrl
        releaseDate {
          day
          month
          year
        }
        tags {
          label
          link
        }
      }
      collections(maxCount: $maxCount) {
        id
        title
        subtitle
        caption
        imageUrl
      }
      recommendations(maxCount: $maxRecommendations) {
        title
        subtitle
        imageUrl
        link
      }
    }
    `;

  return executeQuery(query, variableValues, 'getDashboardData', isMock);

};


// COLECTIONS

export const getCollectionsList = function(isMock){

  let query = `
    query getCollectionsList{
      collections {
        id
        title
        subtitle
        caption
        imageUrl
      }
    }
  `;

  return executeQuery(query, null, 'getCollectionsList', isMock);
};


export const getCollectionDetails = function(collectionId, isMock){
  let variableValues = {id:collectionId};
  let query = `
    query getCollectionDetails($id: Int!) {
      collection(id: $id) {
        id
        title
        subtitle
        caption
        imageUrl
        program{
          parts {
            title
            duration
            audioUrl
            audioPreviewUrl
          }
        }
        location {
          id
          name
          latitude
          longitude
        }
        tags {
          label
          link
        }
        story {
          title
          subtitle
          teaser
          fullText
        }
        media {
          thumbUrl
          imageUrl
        }
        credits {
          logoUrls
          creditItems {
            label
            description
          }
        }
      }
    }
  `;

  return executeQuery(query, variableValues, 'getCollectionDetails/'+collectionId, isMock);
};












