import React, {Component} from 'react';
import PropTypes from 'prop-types';

class EmailTag extends Component {

  constructor(props){
    super(props);

    this.state = {

    }
  }

  componentDidMount(){

  }

  onItemSelected(e){
    e.stopPropagation();
    this.props.onItemSelected(this.props.id);
  }

  render(){

    return(
      <div style={this.styles().container} onClick={this.onItemSelected.bind(this)}>
        {this.props.label}
      </div>
    );
  }

  styles(){

    let bgColor = null;

    if(this.props.isValid){
      if(this.props.validColor) bgColor = this.props.validColor;
    } else {
      if(this.props.invalidColor) bgColor = this.props.invalidColor;
    }

    return {
      container:{
        fontSize:'13px',
        background:bgColor,
        color:bgColor ? '#fff' : null,
        lineHeight:'32px',
        border:'1px solid '+(bgColor ? bgColor : '#f0f0f0'),
        borderRadius:'3px',
        display:'inline-block',
        padding:'0 4px',
        margin:'0 4px 0px 0',
        cursor:'pointer',
        opacity:this.props.isActive ? '.8' : null,
      },
    }
  }

}

EmailTag.propTypes = {
  id:PropTypes.number.isRequired,
  label:PropTypes.string.isRequired,
  isActive:PropTypes.bool,
  isValid:PropTypes.bool,
  validColor:PropTypes.string,
  invalidColor:PropTypes.string,
  onItemSelected:PropTypes.func.isRequired,
};

export default EmailTag;