import React from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import Radium from 'radium';

import {getFeatureFlag} from '../utils/featureFlags';
import ResponsiveView from '../views/ResponsiveView';

import ButtonSubmitMain from './ButtonSubmitMain';
import MultiEmailInput from './multiEmailInput/MultiEmailInput';

import icnClose from '../img/icn_close_big_dark.svg';

import '../cssAnimations/ShareDialogAnims.css';

import { withTranslation } from 'react-i18next';

class ShareDialog extends ResponsiveView {

  constructor(props){
    super(props);

    this.state = {
      linkCopied:false,
    }

  }

  componentDidMount(){
    this.clipboard = new Clipboard('#btn');

    this.clipboard.on('success', function(e) {
      this.onLinkCopied();
    }.bind(this));

    this.clipboard.on('error', function(e) {
      this.onLinkCopyError();
    });
  }

  onLinkCopied(){
    this.setState({
      linkCopied:true,
    });

    this.timeout = setTimeout(function(){
      this.setState({
        linkCopied:false,
      });
    }.bind(this),3000)
  }

  onLinkCopyError(){
    console.log("Error while copying link.");
  }

  getBodyTextForEmail(){

    let bodyString = this.props.title+'%0D%0A%0D%0A'; // double line-break

    bodyString += this.props.text+'%0D%0A%0D%0A';

    bodyString += 'Direkter Link: '+this.props.url;

    return bodyString;
  }

  onFbClick(){
    console.log("onFbClick ", this.props.title);

    window.FB.ui({
      method: 'share',
      href: this.props.url,
      quote: this.props.title,
      //mobile_iframe:true,
    }, function(response){});
  }


  onCloseDialog(){
    this.props.onCloseDialog();
  }

  render() {
    const {t} = this.props;
    return (
      <div style={this.styles().container} id="shareDialog" className={this.props.isActive ? 'active' : null}>
        <div style={this.styles().contentContainer}>

          <div style={this.styles().closeBtn} onClick={this.onCloseDialog.bind(this)}>
            <img src={icnClose} alt="" />
          </div>

          <div style={this.styles().headline}>{t('shareDialog.header')}</div>

          <div style={this.styles().description}></div>

          <div style={this.styles().linkContainer}>
            {/*<label>LINK</label>*/}
            <input type="text" value={this.props.url}/>

            <div style={this.styles().copyLinkContainer}>
              {!this.state.linkCopied
                ? <ButtonSubmitMain
                    id="btn"
                    label={t('shareDialog.copyLink')}
                    dataClipboardText={this.props.url}
                    background={'#1C6ECC'}
                  />
                : <div style={this.styles().copySuccess}>{t('shareDialog.copySuccess')}</div>
              }
            </div>

            {getFeatureFlag('MULTI_EMAIL_INPUT')
              ? <div style={this.styles().emailContainer}>
                  <MultiEmailInput
                    validColor={'#1C6ECC'}
                    invalidColor={'#D0011B'}
                    onSendForm={(emails) => console.log("Send emails: ", emails)}
                    buttonColor={'#1C6ECC'}
                  />
                </div>
              : null
            }



            <div style={this.styles().emailContainer}>
              <a href={"mailto:?subject="+this.props.title+" / karajan.de&body="+this.getBodyTextForEmail()} style={this.styles().linkButton} key={'email'}>EMAIL</a>
              <span style={this.styles().linkButton} key={'facebook'} onClick={this.onFbClick.bind(this)}>FACEBOOK</span>
              <a href={"https://twitter.com/intent/tweet?text="+this.props.title+"&hashtags=karajan-webapp&url="+encodeURIComponent(this.props.url)+"&original_referer="+encodeURIComponent(this.props.url)} target="_blank" rel="noopener noreferrer" style={this.styles().linkButton} key={'twitter'}>TWITTER</a>

            </div>
          </div>
        </div>
      </div>
    );
  }

  styles(){

    return {

      container:{
        padding:window.innerWidth <= 480 ? '40px 20px' : '60px 60px',
        width:'100%',
        height:'100vh',
        zIndex:'15',
        background:'rgba(240,240,240,.98)',
        position:'fixed',
        top:'0',
        overflow:'scroll',
      },
      contentContainer:{
        maxWidth:'500px',
        margin:'0 auto',
        textAlign:'left'
      },
      closeBtn:{
        position:'absolute',
        top:window.innerWidth <= 480 ? '10px' : '20px',
        right:window.innerWidth <= 480 ? '10px' : '20px',
        cursor:'pointer',
      },
      headline:{
        fontSize:window.innerWidth <= 480 ? '40px' : '50px',
        fontWeight:'900',
        lineHeight:window.innerWidth <= 480 ? '40px' : '50px',
        textAlign:'center',
      },
      description:{
        marginTop:'30px',
        textAlign:'center',
        display:'none',
      },
      linkContainer:{
        marginTop:'50px',
      },
      copyLinkContainer:{
        marginTop:'10px',
      },
      copySuccess:{
        lineHeight:'36px',
        textAlign:'center',
        color:'#75C51F',
        border:'1px solid #75C51F',
      },
      emailContainer: {
        marginTop:'50px',
      },
      linkButton:{
        width:'100%',
        display:'inline-block',
        color:'#1C6ECC',
        fontSize:'13px',
        fontWeight:'700',
        lineHeight:'36px',
        borderTop:'1px solid #e0e0e0',
        borderBottom:'1px solid #e0e0e0',
        cursor:'pointer',
        textAlign:'center',
        textDecoration:'none',
        width:'100%',
        ':hover':{
          background:'#e0e0e0'
        },
        marginBottom:'16px',
      }

    }
  }
}

ShareDialog.defaultProps = {
  url:'',
};

ShareDialog.propTypes = {
  isActive:PropTypes.bool.isRequired,
  onCloseDialog:PropTypes.func.isRequired,
  url:PropTypes.string,
  title:PropTypes.string,
  text:PropTypes.string,
};

export default withTranslation()(Radium(ShareDialog));