export const events = [
  {
    id:1,
    imageUrl:"http://www.mozarteum.at/assets/galleries/177/cs_gr_saal_03072013_006.jpg",
    title:"Erstes öffentliches Dirigat",
    description:"Erstes öffentliches Auftreten Karajans als Dirigent im Mozarteum in Salzburg. Für diesen Zweck mietet sein Vater eigens das Mozarteum Orchester an.\n\nIm Publikum sitzt der Intendant des Ulmer Stadttheaters, der Karajan daraufhin zum Probedirigat an sein Haus einlädt.",
    date:
    {
      day:22,
      month:1,
      year:1929
    },
    tags:
    [
      {
        id:1,
        link:"/discover/locations/12",
        label:"MOZARTEUM"
      },
      {
        id:2,
        link:"/discover/concerts/73",
        label:"KONZERTPROGRAMM"
      }
    ],
    locationId:5,
  }
];