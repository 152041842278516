import React from 'react';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';

import iconConcerts from '../img/icn_concert_60x60.svg';
import iconRecordings from '../img/icn_recording_60x60.svg';
import iconComposers from '../img/icn_composer_60x60.svg';
import iconWorks from '../img/icn_works_60x60.svg';
import iconOrchestras from '../img/icn_orchestra_60x60.svg';
import iconArtists from '../img/icn_artists_60x60.svg';
import iconLocations from '../img/icn_locations_60x60.svg';
import iconEvents from '../img/icn_events_60x60.svg';

import iconRandom from '../img/icn_random.svg';
import iconRandomActive from '../img/icn_random_active.svg';

import { withTranslation } from 'react-i18next';

class CategoryListItem extends ResponsiveView {


  constructor(props){
    super(props)

    let icn;

    switch(this.props.title){
      case 'Konzerte':
        icn = iconConcerts;
        break;
      case 'Aufnahmen':
        icn = iconRecordings;
        break;
      case 'Komponisten':
        icn = iconComposers;
        break;
      case 'Werke':
        icn = iconWorks;
        break;
      case 'Orchester':
        icn = iconOrchestras;
        break;
      case 'Künstler':
        icn = iconArtists;
        break;
      case 'Orte':
        icn = iconLocations;
        break;
      case 'Ereignisse':
        icn = iconEvents;
        break;
      default:
        break;
    }

    this.state = {
      icon:icn,
      overRandom:false,
    }
  }

  onViewResize(){
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for CategoryListItem");
      return;
    }

    this.props.onItemSelected(this.props.title);
  }

  onRandomClick(e){
    e.stopPropagation();
    this.props.onRandomClick(this.props.title)
  }

  onOverRandom(){
    this.setState({
      overRandom:true,
    })
  }

  onOutRandom(){
    this.setState({
      overRandom:false,
    })
  }

  render() {

    const {t} = this.props;

    let background = this.props.isActive ? {background:'#f9f9f9'} : null;

    return (
      <div style={Object.assign({}, this.styles().container, background)} onClick={this.onItemSelected.bind(this)}>


        <img src={this.state.icon} alt="" style={this.styles().categoryIcon}/>

        <div style={this.styles().titleContainer}>
          <div style={this.styles().title}>
            {this.props.title}
          </div>
          <div style={this.styles().discovered}>
            {t('category.discovered')} {this.props.discovered}/{this.props.total}
          </div>

        </div>

        <div style={this.styles().randomButton}
             onClick={this.onRandomClick.bind(this)}
             onMouseOver={this.onOverRandom.bind(this)}
             onMouseOut={this.onOutRandom.bind(this)}>
          {this.state.overRandom
            ? <img src={iconRandomActive} alt="" />
            : <img src={iconRandom} alt="" />
          }

        </div>

      </div>
    );
  }

  styles(){

    return {

      container:{
        background:'#fff',
        position:'relative',
        width:'100%',
        //lineHeight:'60px',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        padding:'10px 0px 10px 20px',
        cursor:'pointer',
        textAlign:'left',
        overflow:'hidden',
        ':hover':{
          background:'#f9f9f9',
        },
      },
      categoryIcon:{
        float:'left',
        display:'block',
      },
      titleContainer:{
        float:'left',
        color:'#4A4A4A',
        marginLeft:'20px',
        marginTop:'12px',
      },
      title: {

        fontWeight:'700',
        fontSize:'20px',

        whiteSpace:'nowrap',
        overflow:'hidden',
      },
      discovered:{
        fontSize:'12px',
        lineHeight:'20px',
      },
      randomButton:{
        float:'right',
        height:'48px',
        borderLeft:'1px solid #D8D8D8',
        padding:'12px 15px',
        marginTop:'6px',
      }

    }

  }
}

export default withTranslation()(Radium(CategoryListItem));