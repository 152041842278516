import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from '../views/ResponsiveView';

import Radium from 'radium';


class SmallButtonRound extends ResponsiveView {

  onViewResize(){
    return true;
  }

  onButtonClick(){
    this.props.onButtonClick(this.props.id);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>
        {this.props.label}
      </div>
    );
  }

  styles(){

    return {

      container:{
        textAlign:'center',
        background:this.props.background,
        color:this.props.labelColor,
        fontSize:'14px',
        fontWeight:'700',
        height:'32px',
        borderRadius:'16px',
        padding:'0 15px',
        lineHeight:'32px',
        display:'inline-block',
        cursor:'pointer',
        minWidth:this.props.minWidth ? this.props.minWidth : null,
        ':hover':{
          opacity:.8,
        }
      },
    }
  }
}

SmallButtonRound.propTypes = {
  label:PropTypes.string.isRequired,
  background:PropTypes.string.isRequired,
  labelColor:PropTypes.string.isRequired,
  onButtonClick:PropTypes.func.isRequired,
  id:PropTypes.number,
  minWidth:PropTypes.string,
};

export default Radium(SmallButtonRound);