export const mediaAssets = [
  {
    id:1,
    thumbUrl:"/img/img_bonus_material_0.jpg",
    imageUrl:"/img/img_bonus_material_big_0.jpg"
  },
  {
    id:2,
    thumbUrl:"/img/img_bonus_material_1.jpg",
    imageUrl:"/img/img_bonus_material_big_1.jpg"
  },
  {
    id:3,
    thumbUrl:"/img/img_bonus_material_2.jpg",
    imageUrl:"/img/img_bonus_material_big_2.jpg"
  },
  {
    id:4,
    thumbUrl:"/img/img_bonus_material_3.jpg",
    imageUrl:"/img/img_bonus_material_big_3.jpg"
  }
];