import React from 'react';

import ResponsiveView from './ResponsiveView';

//import { getCategoryTotals } from '../api/queries/getCategoryTotals';
import { getCategoryTotals } from '../api/kapiClient';


import { getDiscoveredValue, addDiscoveredItem } from '../utils/localStorageService';

//import {encodeId} from '../utils/helpers';

import CategoryListItem from '../components/CategoryListItem';
import LoadingIndicator from '../components/LoadingIndicator';
import SearchHeader from '../components/SearchHeader';
import SearchResultsList from '../components/SearchResultsList';

import { fullTextQuery } from '../api/kapiClient';
import { randomIntFromInterval } from '../utils/helpers';

import { track } from '../utils/trackingService';

import { withTranslation } from 'react-i18next';

class DiscoverCategorySelectionListView extends ResponsiveView {

    constructor(props) {
        super(props);
        console.log("props in DiscoverCategorySelectionListView: ", this.props.match);

        this.state = {
            listData: null,
            title: this.props.title,
            listType: null,
            searchQuery: '',
            searchResults: null,
        };

        this.onDataReady = this.onDataReady.bind(this);
        this.searchStartDelay = null;
    }


    componentDidMount() {

        const { t } = this.props;

        track('load-page', { pageId: 'DiscoverCategorySelection' });

        if (!this.state.listData) {

            getCategoryTotals(false).then(function (result) {
                console.log("getCategoryTotals returned: ", result);

                let data = result.data;

                //TODO: translate
                let categories = [
                    {
                        title: t(data.concerts.i18nLabel),
                        total: data.concerts ? data.concerts.totalCount : 0,
                        discovered: getDiscoveredValue('concert'),
                        idList: data.concerts.edges,
                    },
                    {
                        title: t(data.recordings.i18nLabel),
                        total: data.recordings ? data.recordings.totalCount : 0,
                        discovered: getDiscoveredValue('recording'),
                        idList: data.recordings.edges,
                    },
                    {
                        title: t(data.composers.i18nLabel),
                        total: data.composers ? data.composers.totalCount : 0,
                        discovered: getDiscoveredValue('composer'),
                        idList: data.composers.edges,
                    },
                    {
                        title: t(data.musicworks.i18nLabel),
                        total: data.musicworks ? data.musicworks.totalCount : 0,
                        discovered: getDiscoveredValue('musicwork'),
                        idList: data.musicworks.edges,
                    },
                    {
                        title: t(data.orchestras.i18nLabel),
                        total: data.orchestras ? data.orchestras.totalCount : 0,
                        discovered: getDiscoveredValue('orchestra'),
                        idList: data.orchestras.edges,
                    },
                    {
                        title: t(data.artists.i18nLabel),
                        total: data.artists ? data.artists.totalCount : 0,
                        discovered: getDiscoveredValue('artist'),
                        idList: data.artists.edges,
                    },
                    {
                        title: t(data.locations.i18nLabel),
                        total: data.locations ? data.locations.totalCount : 0,
                        discovered: getDiscoveredValue('location'),
                        idList: data.locations.edges,
                    },
                    {
                        title: t(data.events.i18nLabel),
                        total: data.events ? data.events.totalCount : 0,
                        discovered: getDiscoveredValue('event'),
                        idList: data.events.edges,
                    }
                ];

                this.onDataReady(categories);

            }.bind(this), function (error) {
                console.error("Call to getCategoryTotals failed.");
                this.setState({
                    errorMessage: t('discover.errorMessage')
                })
            }.bind(this));



        }

    }

    onDataReady(data) {
        console.log("-> onDataReady ", data);

        this.setState({
            listData: data,
        })
    }

    onViewResize() {

    }

    onCategorySelected(title) {
        console.log("onCategorySelected: ", title);

        let path = "";

        const {t} = this.props;


        // DO NOT TRANSLATE!!!! This is also hardcoded in API.dashboardData
        switch (title) {
            case t("discover.concerts"):
                path = '/discover/concerts/list';
                break;
            case t("discover.recordings"):
                path = '/discover/recordings/list';
                break;
            case t("discover.composers"):
                path = '/discover/composers/list';
                break;
            case t("discover.works"):
                path = '/discover/works/list';
                break;
            case t("discover.orchestras"):
                path = '/discover/orchestras/list';
                break;
            case t("discover.artists"):
                path = '/discover/artists/list';
                break;
            case t("discover.places"):
                path = '/discover/locations/list';
                break;
            case t("discover.events"):
                path = '/discover/events/list';
                break;
            default:
                break;
        }
        this.props.history.push(path);

    }

    onRandomCategory(title) {

        console.log("onRandomCategory: ", title);

        let path = "";

        const {t} = this.props;

        switch (title) {
            case t("discover.concerts"):
                path = '/discover/concerts/' + this.state.listData[0].idList[randomIntFromInterval(1, this.state.listData[0].total)].node.id;
                break;
            case t("discover.recordings"):
                path = '/discover/recordings/' + this.state.listData[1].idList[randomIntFromInterval(1, this.state.listData[1].total)].node.id;//randomIntFromInterval(1, 2240);
                break;
            case t("discover.composers"):
                path = '/discover/composers/' + this.state.listData[2].idList[randomIntFromInterval(1, this.state.listData[2].total)].node.id;//randomIntFromInterval(1, 217);
                break;
            case t("discover.works"):
                path = '/discover/works/' + this.state.listData[3].idList[randomIntFromInterval(1, this.state.listData[3].total)].node.id;;//randomIntFromInterval(1, 754);
                break;
            case t("discover.orchestras"):
                path = '/discover/orchestras/' + this.state.listData[4].idList[randomIntFromInterval(1, this.state.listData[4].total)].node.id;;//randomIntFromInterval(1, 131);
                break;
            case t("discover.artists"):
                path = '/discover/artists/' + this.state.listData[5].idList[randomIntFromInterval(1, this.state.listData[5].total)].node.id;;//randomIntFromInterval(1, 1724);
                break;
            case t("discover.places"):
                path = '/discover/locations/' + this.state.listData[6].idList[randomIntFromInterval(1, this.state.listData[6].total)].node.id;;//randomIntFromInterval(1, 334);
                break;
            case t("discover.events"):
                path = '/discover/events/' + this.state.listData[7].idList[randomIntFromInterval(1, this.state.listData[7].total)].node.id;;//randomIntFromInterval(1, 118);
                break;
            default:
                break;
        }

        this.props.history.push(path);
    }

    onUpdateSearchQuery(query) {
        console.log("onUpdateSearchQuery ", query);


        if (this.searchStartDelay) clearTimeout(this.searchStartDelay);

        if (query.length > 0) {
            this.searchStartDelay = setTimeout(function () {

                this.setState({
                    searchQuery: query,
                    searchActive: true,
                }, function () {
                    this.onStartSearch();
                })

            }.bind(this), 500);
        }

    }

    onStartSearch() {

        console.log("-> onStartSearch() for: ", this.state.searchQuery);


        fullTextQuery(this.state.searchQuery).then(function (data) {
            console.log("received data in DiscoverCategorySelectionListView: ", data);
            this.setState({
                searchActive: false,
                searchResults: data,
            });
        }.bind(this));


    }

    onCloseSearch() {
        this.setState({
            searchQuery: '',
            searchActive: false,
            searchResults: null
        })
    }

    onSearchResultSelected(type, id) {
        this.props.history.push('/discover/' + type + '/' + id);
    }

    renderCategoryListItems() {

        if (!this.state.listData) return;

        let items = [];

        for (let i = 0; i < this.state.listData.length; i++) {

            let data = this.state.listData[i];

            console.log(data);

            let item = (<CategoryListItem
                id={i}
                key={i}
                title={data.title}
                total={data.total}
                discovered={data.discovered}
                onItemSelected={this.onCategorySelected.bind(this)}
                onRandomClick={this.onRandomCategory.bind(this, data.title)}
                lastItem={i === this.state.listData.length - 1}
            />);

            items.push(item);

        }

        return items;

    }

    render() {

        const { t } = this.props;

        return (
            <div style={this.styles().view}>

                <div style={this.styles().container}>


                    <div style={this.styles().listHeader}>
                        {this.state.title}

                        <SearchHeader
                            onInputChange={this.onUpdateSearchQuery.bind(this)}
                            onCloseSearch={this.onCloseSearch.bind(this)}
                            placeholder={t('discover.searchPlaceholder')}
                        />
                    </div>


                    {!this.state.errorMessage && (!this.state.listData || this.state.searchActive)
                        ?
                        <div style={this.styles().container}>

                            <LoadingIndicator label={this.state.searchActive ? t('discover.searchInProgress') : t('discover.resultsLoading')} />

                        </div>

                        : null
                    }

                    {this.state.errorMessage
                        ? <div style={this.styles().errorMsgContainer}>{this.state.errorMessage}</div>
                        : null
                    }

                    {this.state.listData && !this.state.searchActive && !this.state.searchResults
                        ? <div style={this.styles().contentContainer}>

                            {this.renderCategoryListItems()}

                        </div>
                        : null
                    }

                    {!this.state.searchActive && this.state.searchResults
                        ? <div style={this.styles().contentContainer}>

                            <SearchResultsList
                                results={this.state.searchResults}
                                onItemSelected={this.onSearchResultSelected.bind(this)}
                                visibleElements={5}
                            />

                        </div>
                        : null
                    }


                </div>

            </div>
        );
    }

    styles() {

        return {
            container: {
                //background:'#fff',
                color: '#131313',
                overflow: 'hidden',
                width: '100%',
            },
            spinnerContainer: {
                padding: '50px 20px',
                textAlign: 'center',
            },
            contentContainer: {
                width: '100%',
                marginTop: '-1px',
            },
            listHeader: {
                height: '60px',
                //background:'#fff',
                borderBottom: '1px solid #E6E6E6',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '900',
                color: '#4A4A4A',
                lineHeight: '60px',
            },
            backButton: {
                position: 'absolute',
                left: '15px',
                top: '18px',
                cursor: 'pointer',
            },
            errorMsgContainer: {
                fontSize: '14px',
                padding: '16px 10px',
            }
        }

    }
}

export default withTranslation()(DiscoverCategorySelectionListView);