import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import ResponsiveView from '../views/ResponsiveView';
import RecordingTypeLabel from './RecordingTypeLabel';

import { capitalizeTitle } from '../utils/helpers';
import KWVNumber from './KWVNumber';

class ListItemInfoCard extends ResponsiveView {


  onViewResize(){
    return true;
  }

  onItemSelected(){

    if(!this.props.onItemSelected) {
      console.warn("No callback defined for InfoCardListItem");
      return;
    }

    this.props.onItemSelected(this.props.id);
  }

  render() {

    let background = this.props.isActive ? {background:'#f9f9f9'} : null;

    return (
      <div style={Object.assign({}, this.styles().container, background)} onClick={this.onItemSelected.bind(this)}>


        <div style={this.styles().title}>
          {capitalizeTitle(this.props.title)}
        </div>


        {this.props.kwvTitle
          ? <div style={this.styles().composers}>
              <KWVNumber kwv={this.props.kwvTitle} />
          </div>
          : null
        }

        <div style={this.styles().composers}>
          {this.props.subtitle}
        </div>

        <div style={this.styles().orchestra}>
          {this.props.caption}
        </div>

        <div style={this.styles().date}>
          {this.props.dateString}
        </div>

        {this.props.label
          ? <div style={this.styles().label}>
          <RecordingTypeLabel
            type={this.props.label}
            lightTheme={true}
          />
        </div>
          : null
        }
      </div>
    );
  }

  styles(){

    return {

      container:{
        background:'#fff',
        position:'relative',
        width:'100%',
        borderTop:'1px solid #E6E6E6',
        borderBottom:this.props.lastItem ? '1px solid #E6E6E6' : null,
        padding:'16px 20px',
        //marginLeft:'16px',
        //paddingLeft:'6px',
        cursor:this.props.onItemSelected ? 'pointer' : null,
        textAlign:'left',
        overflow:'hidden',
        fontSize:'18px',
        ':hover':{
          background:this.props.onItemSelected ? '#f9f9f9' : null,
        },
      },
      label:{
        //marginBottom:'8px',
        marginTop:'8px',
      },
      title: {
        fontWeight:'700',
        fontSize:'20px',
        color:'#191919',
        whiteSpace:this.props.wrapTitle ? null : 'nowrap',
        overflow:'hidden',
      },
      composers:{
        color:'#3F3F3F',
        fontSize:'16px',
      },
      orchestra:{
        color:'#4A4A4A',
        fontSize:'13px',
        fontWeight:'700',
        marginTop:'10px',
      },
      date:{
        color:'#4A4A4A',
        fontSize:'13px',
        fontWeight:'700',
      }

    }

  }
}

ListItemInfoCard.propTypes = {
  id:PropTypes.number.isRequired,
  title:PropTypes.string.isRequired,
  subtitle:PropTypes.string,
  kwvTitle:PropTypes.string,
  caption:PropTypes.string,
  dateString:PropTypes.string,
  onItemSelected:PropTypes.func,
  wrapTitle:PropTypes.bool,
  label:PropTypes.string,
};

export default Radium(ListItemInfoCard);