import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveView from '../../views/ResponsiveView';

import Radium from 'radium';


class ButtonToggleIconAndLabel extends ResponsiveView {

  componentDidMount(){
    this.state = {
      isActive:false,
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps){

    console.log("ButtonToggleIconAndLabel -> componentWillReceiveProps: ", nextProps);

    if(nextProps.isActive === this.props.isActive)

      this.setState({
        isActive:nextProps.isActive,
      });

  }

  onViewResize(){
    return true;
  }

  onButtonClick(){

    let newButtonState = !this.props.isActive;

    console.log("ButtonToggleIconAndLabel -> newButtonState: ", newButtonState);

    if(this.props.onButtonClick) this.props.onButtonClick(newButtonState);
  }

  render() {
    return (
      <div style={this.styles().container} onClick={this.onButtonClick.bind(this)}>

        <div style={this.styles().bg}>
          {this.props.isActive
            ? <img src={this.props.iconActive} alt="" style={this.styles().iconAsset}/>
            : <img src={this.props.icon} alt="" style={this.styles().iconAsset}/>
          }

          {this.props.isActive
            ? <span>{this.props.labelActive}</span>
            : <span>{this.props.label}</span>
          }
        </div>

      </div>
    );
  }

  styles(){

    return {

      container:{
        textAlign:'center',
        //background:'#fff',
        color:this.props.labelColor ? this.props.labelColor : '#fff',
        fontSize:'14px',
        fontWeight:'700',
        height:'48px',
        lineHeight:'48px',
        display:'inline-block',
        cursor:'pointer',
        minWidth:this.props.minWidth ? this.props.minWidth : null,
        borderRadius:'24px',
        boxShadow:'0 1px 3px rgba(0,0,0,.3)',
        overflow:'hidden',
      },
      bg:{
        width:'100%',
        height:'100%',
        padding:'0 18px 0 14px',
        background:this.props.background ? this.props.background : '#1C6ECC',
        ':hover':{
          opacity:.9,
        }
      },
      iconAsset:{
        display:'inline-block',
        verticalAlign:'sub',
        paddingRight:'6px',
      }

    }

  }
}

ButtonToggleIconAndLabel.propTypes = {
  label:PropTypes.string.isRequired,
  labelActive:PropTypes.string,
  icon:PropTypes.string.isRequired,
  iconActive:PropTypes.string,
  isActive:PropTypes.bool.isRequired,
  background:PropTypes.string
}


export default Radium(ButtonToggleIconAndLabel);