import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveView from './ResponsiveView';

import {getStoriesIndices, getStoriesList} from '../api/queries/getStoriesList';
import {getParamValue, getOffset, decodeId} from '../utils/helpers';
import {track} from '../utils/trackingService';

import BigButtonRound from '../components/BigButtonRound';
import StoryItem from '../components/StoryItem';
import DummyStoryItem from '../components/DummyStoryItem';
import LoadingIndicator from '../components/LoadingIndicator';

import icnStories from '../img/icn_stories_big.svg';

class StoriesView extends ResponsiveView {

  constructor(props){
    super(props, false);

    this.state = {
      viewData:null,
      errorMessage:'',
      firstPage:0,
      lastPage:0,
      prevPageCursor:null,
      nextPageCursor:null,
      hasPreviousPage:false,
      hasNextPage:false,
      storyIndices:null,
    };

    this.onDataReady = this.onDataReady.bind(this);
  }

  componentDidMount(){

    getStoriesIndices(9999).then(function(result){
      this.setState({
        storyIndices:result.data.stories.edges
      });

      let pageCursor = null;
      let page = 0;
      let id = decodeURIComponent(getParamValue(this.props.location.search, 'id'));

      if(id){
        let cursorInfo = this.getPageCursorInfoForStoryCursor(id);
        pageCursor = cursorInfo.cursor;
        page = cursorInfo.page;
      }

      try{
        track('load-page', {pageId:'Stories', deeplink:id !== 'undefined' ? decodeId(id) : ''});
      } catch(error){}


      let prevCursor = this.getPrevPageCursor(page);

      this.setState({
        prevPageCursor:prevCursor,
        nextPageCursor:pageCursor,
        hasPreviousPage:(page > 0),
        firstPage:page,
        lastPage:page,
        deepLinkId:id,
      }, function(){
        this.props.history.replace({search:''});
        this.loadViewData(pageCursor);
      });

    }.bind(this), function(error){
      console.log("There was an error in getStoriesIndices", error);
      this.setState({isLoading:false, errorMessage:'Die Stories konnten nicht geladen werden.'});
    }.bind(this));

  }

  onRetryLoading(){
    this.loadViewData();
    this.setState({
      errorMessage:'',
    })
  }

  loadNewerStories(){

    let prevCursor = this.getPrevPageCursor(this.state.firstPage);

    this.loadViewData(prevCursor, 'PREV');
    this.setState({
      errorMessage:'',
    })
  }

  loadOlderStories(){

    let nextCursor = this.getNextPageCursor(this.state.lastPage);

    this.loadViewData(nextCursor, 'NEXT');
    this.setState({
      errorMessage:'',
    })
  }

  getPageCursorInfoForStoryCursor(cursor){

    let cursorPosition = -1;
    let page = null;
    let pageCursor = null;

    for(let i=0; i<this.state.storyIndices.length;i++){

      let el = this.state.storyIndices[i];

      if(el.cursor === cursor){
        cursorPosition = i;
        break;
      }
    }

    if(cursorPosition >= 0){
      page = Math.floor(cursorPosition/10);
    }

    console.log("Found cursor at position: ", cursorPosition);
    console.log("Page: ", page);

    let index = 0;

    if(page != null && page > 0){

      index = (page*10)-1;

      pageCursor = this.state.storyIndices[index].cursor;
    }

    console.log("pageCursor: ", pageCursor);

    return {cursor:pageCursor, index:index, page:page};

  }

  getPrevPageCursor(currentPage){

    let index = 0;
    let cursor = null;

    if(currentPage > 1){
      index = (currentPage-1)*10;
      cursor = this.state.storyIndices[index].cursor
    }

    return cursor;

  }

  getNextPageCursor(currentPage){

    let index = ((currentPage+1)*10)-1;
    return this.state.storyIndices[index].cursor;

  }

  loadViewData(pageCursor, direction){


    getStoriesList(10, pageCursor).then(function(result){

      if(result && result.data && result.data.stories){
        console.log("getStoriesList returned: ",result.data.stories);

        if(direction === "PREV"){
          this.setState({
            firstPage: this.state.firstPage-1,
          });
        } else if(direction === "NEXT"){
          this.setState({
            lastPage: this.state.lastPage+1,
          });
        }

        this.onDataReady(result.data.stories.edges, result.data.stories.pageInfo.hasNextPage, direction);
      } else if(result && result.errors) {
        console.error('There was an error for query getStoriesList:', result.errors[0].message);
        this.setState({isLoading:false, errorMessage:'Es ist ein Fehler aufgetreten.'});
      } else {
        console.log('getStoriesList did not return any results', this.props.match.params.id, result);
        this.setState({isLoading:false, errorMessage:'Die Stories konnten nicht geladen werden.'});
      }

    }.bind(this), function(error){
      console.log("There was a problem querying getStoriesList: ", error);
      this.setState({isLoading:false, errorMessage:'Die Stories konnten nicht geladen werden.'});
    }.bind(this));

  }

  onDataReady(data, hasNextPage, direction){
    console.log("-> onDataReady ", data);

    let allData = data;

    if(this.state.viewData){

      if(direction === "PREV"){
        allData = data.concat(this.state.viewData);
      } else {
        allData = this.state.viewData.concat(data);
      }
    }

    console.log("Current cursor: ", atob(data[data.length-1].cursor));

    this.setState({
      viewData:allData,
      nextPageCursor:data[data.length-1].cursor,
      hasNextPage:hasNextPage,
    }, function(){

      if(this.state.deepLinkId){
        let id = this.state.deepLinkId;//decodeURIComponent(getParamValue(this.props.location.search, 'id'));

        console.log("id: ", id);
        if(id){
          let el = document.getElementById('story'+id);

          console.log("el: ", el);
          if(el){
            let scrollTop = getOffset(el).top;
            console.log("We should scroll to: ", scrollTop);
            window.scrollTo(0,scrollTop);
          }

        }

        this.setState({
          deepLinkId:null,
        })
      }

    })
  }

  onViewResize(){

  }

  onLinkClick(path){
    this.props.history.push(path);
  }

  onShareRequest(data){
    this.props.onShareRequest(data);
  }

  decodeId(encodedId){

    let decodedId = atob(encodedId);

    decodedId = decodedId.split(':')[1];

    return decodedId;

  }

  renderStoryItems(){

    let items = [];
    let isLeft = true;

    for(let i=0; i<this.state.viewData.length;i++){
      let story = this.state.viewData[i].node;
      let cursor = this.state.viewData[i].cursor;

      if((story.title && story.title.length > 3) || (story.fullText && story.fullText.length > 3) || (story.teaser && story.teaser.length > 3)) {
        items.push(
          <StoryItem
            id={cursor}
            title={story.title}
            text={story.fullText ? story.fullText : story.teaser}
            releaseDate={story.releaseDate}
            imageUrl={story.imageUrl}
            tags={story.tags}
            alignment={isLeft ? 'left' : 'right'}
            key={i}
            onLinkClick={this.onLinkClick.bind(this)}
            onShareItem={this.onShareRequest.bind(this)}
          />
        );

        isLeft = !isLeft;
      }
    }

    return items;

  }

  render() {

    return (

      <div style={this.styles().view}>

        <div style={this.styles().backgroundGradient}></div>

        <div style={this.styles().container}>

          <div style={this.styles().contentContainer}>


            <div style={this.styles().pageIconContainer}>
              <img src={icnStories} alt="Icon: Stories" style={this.styles().pageIcon}/>
            </div>
            <div style={this.styles().pageTitle}>
              Stories
            </div>


            {!this.state.viewData && this.state.errorMessage.length === 0
              ?
                <div>
                  <LoadingIndicator />
                  <DummyStoryItem
                    alignment={'left'}
                  />
                  <DummyStoryItem
                    alignment={'right'}
                  />
                  <DummyStoryItem
                    alignment={'left'}
                  />
                  <DummyStoryItem
                    alignment={'right'}
                  />
                </div>
              : null
            }

            {!this.state.viewData && this.state.errorMessage.length > 0
              ? <div style={this.styles().emptyViewDescription}>
                  {this.state.errorMessage}

                  <div style={this.styles().retryButtonContainer}>
                    <BigButtonRound
                      label="WIEDERHOLEN"
                      onButtonClick={this.onRetryLoading.bind(this)}
                      background="#1C6ECC"
                    />
                  </div>
                </div>
              : null
            }

            {this.state.viewData && this.state.errorMessage.length === 0
              ? <div style={this.styles().storyItemsContainer}>

                  {this.state.firstPage > 0
                    ?
                    <div style={this.styles().newerButtonContainer}>
                      <BigButtonRound
                        label="NEUERE ANZEIGEN"
                        onButtonClick={this.loadNewerStories.bind(this)}
                        background="#1C6ECC"
                      />
                    </div>
                    :null
                  }

                  {this.renderStoryItems()}
                  <div style={this.styles().timelineEnd}>

                  </div>

                  {this.state.hasNextPage
                    ?
                    <div style={this.styles().retryButtonContainer}>
                      <BigButtonRound
                        label="ÄLTERE ANZEIGEN"
                        onButtonClick={this.loadOlderStories.bind(this)}
                        background="#1C6ECC"
                      />
                    </div>
                    :null
                  }
                </div>
              : null
            }

          </div>

        </div>

      </div>
    );
  }

  styles(){

    return {
      view:{
        background:'#3A3A3A',
        minHeight:'100vh',
        paddingLeft:window.innerWidth > 768 ? '60px' : '0px',
      },
      backgroundGradient:{
        position:'fixed',
        top:'0',
        left:'0',
        width:'100%',
        height:'100vh',
        background:'url('+process.env.PUBLIC_URL + '/img/bg_dark_gradient.png)',
        backgroundSize:'cover',
        zIndex:0,
      },
      container:{
        //overflow:'hidden',
        paddingBottom:'100px',
        color:'#fff',
        position:'relative',
      },
      spinnerContainer:{
        padding:'10px 20px',
        textAlign:'center',
      },
      contentContainer: {
        maxWidth:'940px',//'860px',
        margin:'0 auto',
      },
      pageTitle:{
        fontSize:'28px',
        fontWeight: '900',
        marginBottom:'10px',
        color:'#fff',
      },
      pageIconContainer: {
        paddingTop:'40px',
        minHeight:'48px',
      },
      pageIcon:{
        margin:'0 auto',
        display:'block',
      },
      emptyViewDescription:{
        paddingTop:'30px',
        fontSize:'18px',
        fontWeight:'700',
        color:'#E6E6E6',
      },
      retryButtonContainer:{
        marginTop:'20px',
      },
      newerButtonContainer:{
        marginTop:'20px',
        marginBottom:'20px',
      },
      storyItemsContainer:{
        textAlign:'center',
      },
      timelineEnd:{
        display:window.innerWidth >= 1024 ? 'block' : 'none',
        borderTop:'2px solid #D9C56F',
        width:'10px',
        margin:'0 auto',
      }
    }

  }
}

StoriesView.propTypes = {
  onShareRequest:PropTypes.func.isRequired,
};

export default StoriesView;