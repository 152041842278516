export const collections = [
  {
    id:1,
    title:'Wie Schostakowitsch es sich erträumt hat',
    subtitle: "Dimitri Schostakowitsch: Symphonie Nr.10",
    caption: "Herbert von Karajan / Staatskapelle Dresden",
    imageUrl: '/img/collection_shostakovich_hero_round.jpg',
    program:[
      {
        parts:[
          {
            "title":"1. Akt, 1. Szene: Golaud, Mélisande: 'Je ne pourrai'",
            "duration":1357,
            "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3",
            "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHKM.mp3"
          },
          {
            "title": "1. Akt, 1. Szene: Golaud, Mélisande: 'Qu'est-ce",
            "duration": 256,
            "audioUrl": "https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3",
            "audioPreviewUrl": "https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLY.mp3"
          }
        ]
      },
      {
        parts:[
          {
            "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Voice ce qu'il écrit'",
            "duration":703,
            "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3",
            "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHLK.mp3"
          },
          {
            "title":"1. Akt, 2. Szene: Arkel, Geneviève: 'Je n'en dis rien'",
            "duration":787,
            "audioUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3",
            "audioPreviewUrl":"https://mp31.phononet.de/amazon//d2/171/005/LEAZGHCOPKXYBLAMRZGEXWCOPHYR.mp3"
          },
        ]
      }
    ],
    locationId:1,
    tagIds:[1,2],
    storyId:1,
    mediaIds:[1,2,3,4],
    creditsId:1,
  },
  {
    id:2,
    title: "Und ein weiteres großartiges Zitat",
    subtitle: "Ludwig van Beethoven: Symphonie Nr.9",
    caption: "Herbert von Karajan / Berliner Philharmoniker",
    imageUrl: "/img/collection_pavarotti_hero_round.jpg",
    playlistId: 1,
    locationId:1,
    tagIds:[1,2],
    storyId:1,
    mediaIds:[1,2,3,4],
    creditsId:1,
  },
  {
    id:3,
    title: "Ein etwas längeres Zitat sollte das Layout auch aushalten können",
    subtitle: "Ludwig van Beethoven: Symphonie Nr.9",
    caption: "Herbert von Karajan / Wiener Philharmoniker",
    imageUrl: "/img/collection_shostakovich_hero_round.jpg",
    playlistId: 1,
    locationId:1,
    tagIds:[1,2],
    storyId:1,
    mediaIds:[1,2,3,4],
    creditsId:1,
  }
];
